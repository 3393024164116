import React, { useMemo } from "react";
import connect from "shared/context/connect";
import { useHistory } from "react-router-dom";
import { PATH, FIELDS } from "shared/constants/constants";
import storeAction from "shared/context/actions";
import { CheckMarkItemContainer } from "components";
import WithBottomButton from "components/Layout/WithBottomButton";
import utils from "shared/helpers/utils";
import { defaultLayout } from "layouts";
import track from 'react-tracking';
import { trackButtonClick, buildAnswerCodeFromArr } from 'shared/lib/tracking.utils';

interface Props {
  onSave?: any;
  investMediocreRanking?: string;
  data: Array<object>;
  userId: string;
  tracking: Function;
}
const Momentum: React.FC<Props> = ({
  onSave = () => {},
  investMediocreRanking,
  data,
  tracking = () => {},
  userId
}) => {
  // const [checkedItem, setCheckedItem] = useState(governance);
  const history = useHistory();
  const { getStringAfterSpecialChar } = utils;

  // const handleItemChecked = (e: any) => {
  //   if (e.target.checked) {
  //     onSave(e.target.name, e.target.id);
  //   } else {
  //     onSave(e.target.name, null);
  //   }
  // };

  const handleItemChecked = (value: any, name: string) => {
    onSave(name, value);
  };

  const handleSkipped = () => {
    trackButtonClick(tracking, userId, [
      { key: 'questionCode', value: data[0]['code'] },
      { key: 'skipped', value: 'true' },
    ]);
    onSave(FIELDS.MOMENTUM, []);
    history.push(`${PATH.TRANSITION}?id=2`);
  };

  const renderOptions = useMemo(() => {
    const mappedData = data.map((item: any) => ({
      id: item.code,
      title: item.name,
      label: item.desc,
      imgSrc: `momentum-${getStringAfterSpecialChar(item.code, ".")}.png`,
    }));
    return mappedData;
  }, [data]);

  return (
    <WithBottomButton
      // title="Governance"
      description="Would you invest in companies with mediocre ESG score, but signs of positive score improvements?"
      onSkipClick={() => handleSkipped()}
      // hideSkip
      disable={!investMediocreRanking.length}
      onButtonClick={() => {
        const answerCode = buildAnswerCodeFromArr(investMediocreRanking);
        trackButtonClick(tracking, userId, [
          { key: 'questionCode', value: data[0]['code'] },
          { key: 'answerCode', value: answerCode },
        ]);
        history.push(`${PATH.TRANSITION}?id=2`);
      }}
    >
      <CheckMarkItemContainer
        name={FIELDS.MOMENTUM}
        selected={investMediocreRanking}
        items={renderOptions.slice(1)}
        // items={[
        //   { id: 'investMediocreRanking-yes', label: "I would like to encourage companies showing signs of positive change towards ESG.", title: 'Yes', imgSrc: 'momentum-yes.png' },
        //   { id: 'investMediocreRanking-no', label: "Too soon for me. I would rather invest when they have already made the transition and proven themselves.", title: 'No', imgSrc: 'momentum-no.png' },
        // ]}
        onSelect={handleItemChecked}
      />
    </WithBottomButton>
  );
};

const mapStateToProps = ({ investmentState, rootState }) => ({
  investMediocreRanking: investmentState.investMediocreRanking,
  data: rootState.masterData.EN.trend,
  userId: rootState.userId
});

const mapDispatchToProps = {
  onSave: storeAction.updateInvestmentData,
};

export default track()(connect(
  mapStateToProps,
  mapDispatchToProps
)(defaultLayout(Momentum)));
