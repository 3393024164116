import React, { useMemo } from "react";
import connect from "shared/context/connect";
import { useHistory } from "react-router-dom";
import { CheckMarkItemContainer } from "components";
import WithBottomButton from "components/Layout/WithBottomButton";
import { PATH, FIELDS } from "shared/constants/constants";
import storeAction from "shared/context/actions";
import { defaultLayout } from "layouts";
import utils from "shared/helpers/utils";
import track from 'react-tracking';
import { trackButtonClick, buildAnswerCodeFromArr } from 'shared/lib/tracking.utils';
interface Props {
  onSave?: any;
  environment?: string;
  data: Array<object>;
  userId: string;
  tracking: Function;
}
const Environment: React.FC<Props> = ({
  onSave = () => {},
  environment,
  data,
  tracking = () => {},
  userId
}) => {
  const history = useHistory();
  const { getStringAfterSpecialChar } = utils;
  // const handleItemChecked = (e: any) => {
  //   if (e.target.checked) {
  //     onSave(e.target.name, e.target.id);
  //   } else {
  //     onSave(e.target.name, null);
  //   }
  // };

  console.log(getStringAfterSpecialChar("nw.esg.env.climate_change", "."));

  const handleItemChecked = (value: any, name: string) => {
    onSave(name, value);
  };

  const handleSkipped = () => {
    // onSave(FIELDS.ENVIRONMENT, null);
    trackButtonClick(tracking, userId, [
      { key: 'questionCode', value: data[0]['code'] },
      { key: 'skipped', value: 'true' },
    ]);
    onSave(FIELDS.ENVIRONMENT, []);
    history.push(PATH.SOCIAL);
  };

  // const data = [
  //   { id: 'climateChange', label: "Mitigate risks, and leverage opportunities arising from global warming.", title: 'Climate change and carbon emissions', imgSrc: 'env-climate-change.png' },
  //   { id: 'pollutionAndHaze', label: "Avoid investments that contribute to the serious health hazards posed by recurring transboundary haze in ASEAN.", title: 'Air and water pollution', imgSrc: 'env-pollution.png' },
  //   { id: 'energyEfficiency', label: "Xxxxxxx xxxxxxx xxxx xx xxxxx xxxxxxx xxxx xx xxxxx..", title: 'Energy efficiency', imgSrc: 'env-energy-efficiency.png' },
  //   { id: 'wasteManagement', label: "Xxxxxxx xxxxxxx xxxx xx xxxxx xxxxxxx xxxx xx xxxxx..", title: 'Waste management', imgSrc: 'env-waste-management.png' },
  //   { id: 'waterScarcity', label: "Xxxxxxx xxxxxxx xxxx xx xxxxx xxxxxxx xxxx xx xxxxx..", title: 'Water scarcity', imgSrc: 'env-water-scarcity.png' },
  //   { id: 'bioDiversity', label: "Xxxxxxx xxxxxxx xxxx xx xxxxx xxxxxxx xxxx xx xxxxx..", title: 'Biodiversity and deforestation', imgSrc: 'env-biodiversity.png' },
  // ];

  const renderOptions = useMemo(() => {
    const mappedData = data.map((item: any) => ({
      id: item.code,
      title: item.name,
      label: item.desc,
      imgSrc: `env-${getStringAfterSpecialChar(item.code, ".")}.png`,
    }));
    return mappedData;
  }, [data]);

  return (
    <WithBottomButton
      // title="Environment."
      description="What ENVIRONMENTAL causes do you care about the most?"
      onSkipClick={() => handleSkipped()}
      disable={!environment.length}
      onButtonClick={() => {
        const answerCode = buildAnswerCodeFromArr(environment);
        trackButtonClick(tracking, userId, [
          { key: 'questionCode', value: data[0]['code'] },
          { key: 'answerCode', value: answerCode },
        ]);
        history.push(PATH.SOCIAL);
      }}
    >
      <CheckMarkItemContainer
        name={FIELDS.ENVIRONMENT}
        selected={environment}
        items={renderOptions.slice(1)}
        onSelect={handleItemChecked}
        type="checkbox"
      />
    </WithBottomButton>
  );
};

const mapStateToProps = ({ investmentState, rootState }) => ({
  environment: investmentState.environment,
  data: rootState.masterData.EN.env,
  userId: rootState.userId
});

const mapDispatchToProps = {
  onSave: storeAction.updateInvestmentData,
};

export default track()(connect(
  mapStateToProps,
  mapDispatchToProps
)(defaultLayout(Environment)));
