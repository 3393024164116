import React, { useState, useEffect } from 'react';
import connect from 'shared/context/connect';
import { useHistory } from "react-router-dom";
import { authLayout } from 'layouts';
import storeAction from 'shared/context/actions';
import utils from 'shared/helpers/utils';
import ReactLoading from 'react-loading';
import './Login.scss';

import { InputWithButton } from 'components';
import { PATH } from 'shared/constants/constants';

interface Props {
    onHandleLogin?: any,
    onValidateToken?: any,
    phoneNumber?: string,
    error?: string,
    token?: string,
}
const Login: React.FC<Props> = ({
    onHandleLogin = () => {}, phoneNumber, error, token,
    onValidateToken = () => {}
}) => {
    const [mobile, setMobile] = useState(phoneNumber);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (token) {
            onValidateToken();
        }
    }, []);

    const onMobileChange = (e: any) => {
        setMobile(e.target.value);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        setIsLoading(true);
        if (token) {
            history.push(PATH.INTRO);
            setIsLoading(false);
        } else {
            Promise.all([
                onHandleLogin(mobile, history),
            ]).then(() => {
                setIsLoading(false);
            });
        }
    }

    const buttonVal = isLoading ? (
        <ReactLoading height="24px" width="24px" color="#ffffff" type="spin" />
    ) : (
        <>
            <span>{token ? 'Continue' : 'Send OTP'}</span>
            <img src={`${utils.baseUrl}/images/arrow-right.svg`} alt="enter" />
        </>
    );

    return (
        <section className="login-page">
            <div className="login-page__row">
                <h1>Make your portfolio reflect your vision for our future.</h1>
                <p>Research is increasingly showing that ESG investing  can reduce portfolio risk, generate competitive investment returns, and help investors feel good about the stocks they own.</p>

                <InputWithButton
                    type="tel"
                    placeholder="+65"
                    value={mobile}
                    onInputChange={onMobileChange}
                    buttonValue={buttonVal}
                    isValid={error === null || error === ''}
                    errorMessage={error}
                    onButtonClick={(e) => onSubmit(e)}
                />

                <p><em>For now this is a private access only for the registered mobile numbers.</em></p>

                <p>
                    <a href={`mailto:${process.env.REACT_APP_ESG_EMAIL}?subject=Access to ESG Profiler&body=Please kindly indicate the ff: your name, your mobile number (with the country code) and your employer's name and/or the context of your request for a (time-limited) access to the ESG Profiler & Digital Advisor tool`} title="request access">Request access.</a>
                </p>
            </div>
            <div className="login-page__row login-page__row--withBg" style={{ backgroundImage: `url(${utils.baseUrl}/images/bg-art.png)` }}>
                <img src={`${utils.baseUrl}/images/app-screenshot.png`} alt="ESG sample screen" />
            </div>
        </section>
    )
}

const mapStateToProps = ({ rootState }) => ({
    phoneNumber: rootState.phoneNumber,
    error: rootState.error,
    token: rootState.token,
});

const mapDispatchToProps = ({
    onHandleLogin: storeAction.requestOtp,
    onValidateToken: storeAction.validateToken,
})
export default connect(mapStateToProps, mapDispatchToProps)(authLayout(Login));