import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './FilterSlider.scss';
import Switcher from 'components/Switcher/Switcher';
import IconButton from 'components/Button/IconButton/IconButton';

interface FilterSliderProps {
    id?: string,
    title: string,
    percentage: string,
    // number: number,
    preposition: string,
    minValue:number,
    maxValue:number,
    minLabel: string,
    maxLabel: string,
    step:number,
    marks?: any,
    defaultValue?: number,
    onChange?: any,
    onAfterChange?: any,
    isDisabled?: boolean,
    isHidden?: boolean,

    switcherName?: string,
    switcherId?: string,
    switcherIsOn?: boolean,
    onSwitchChange?: any,
    isSwitcherDisabled?: boolean,
    isSwitcherHidden?: boolean,

    iconBtnId?: string,
    popupContent?: string,
    // name: string
}

const FilterSlider: React.FC<FilterSliderProps> = ({
    id, title, percentage, preposition, minValue, maxValue, minLabel, maxLabel, step, defaultValue, onChange = () => {}, isDisabled = false,
    onAfterChange = () => {}, isHidden = false, switcherName, switcherId, switcherIsOn, onSwitchChange = () => {}, isSwitcherDisabled = false, isSwitcherHidden = false, iconBtnId, popupContent,
}) => {

    const classes = classnames({
        'filter-slider-wrapper': true,
        'filter-slider-wrapper--disabled': isDisabled,
        // 'filter-slider-wrapper--hidden': isHidden,
      });
    
    const marks = {
        [minValue]: minLabel, 
        [maxValue]: maxLabel,
    }

    return (
        <>
        {!isHidden && 
            <section className={classes} id={id}>            
            <div className="filter-slider-wrapper__header">
                <div className="filter-slider-wrapper__header__words">
                    <FormattedMessage id="filter.title" values={{ title: title }}/>
                    <span>
                    <FormattedMessage id="filter.result" values={{ percentage: percentage, number: defaultValue, preposition: preposition }}/>
                    </span>
                </div>
                <div className="filter-slider-wrapper__header__icons">
                        <Switcher name={switcherName} id={switcherId} isOn={switcherIsOn} onChange={onSwitchChange} isDisabled={isSwitcherDisabled} isHidden={isSwitcherHidden}/>
                        {popupContent && <IconButton id={iconBtnId} popupcontent={popupContent} />}
                </div>
            </div>
            <div className="filter-slider-wrapper__body">
                {/* <input type="text" hidden name={name} defaultValue={defaultValue} /> */}
                <Slider
                    min={minValue}
                    max={maxValue}
                    step={step}
                    defaultValue={defaultValue}
                    marks={marks}
                    onChange={onChange}
                    onAfterChange={onAfterChange}
                    disabled={isDisabled}
                />
            </div> 
        </section>}
        </>
    );
}

export default memo(FilterSlider);