import React, { memo } from "react";
import { Rating } from 'components';

import './EsgProduct.scss'

interface IEsgHeaderProps {
    // imgSrc?: string,
    title?: string,
    ratingNumber?: number,
    id?: string,
}

const EsgHeader: React.FC<IEsgHeaderProps> = ({
    title = '',
    ratingNumber = 5,
    id = '',
}) => {
    return (
        <header className="esg-header">
            {/* <figure className="esg-header__thumbnail">
                <img src={imgSrc} alt={title} />
            </figure> */}
            <div className="esg-header__details">
                 <h4>{title}</h4>

                 <small className="smaller">Morningstar Sustainability rating</small>
                 <Rating id={id} ratingNumber={ratingNumber} />
            </div>
        </header>
    );
}

export default memo(EsgHeader);