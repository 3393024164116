import React, { memo } from "react";
import utils from 'shared/helpers/utils';

import './SwipeSummary.scss';

import IconCheck from "assets/images/RightCheckClear.png";
import IconClose from "assets/images/LeftCheckClear.png";
import IconSkipped from "assets/images/icon-question.svg"

export interface ISwipeSummaryProps {
    data?: any,
}

const SwipeSummary: React.FC<ISwipeSummaryProps> = ({
    data = []
}) => {
    const getImageSources = (title: string) => {
        switch (title) {
            case 'ESG investments':
                return 'exp-esg.png';
            case 'SRI investments':
                return 'exp-sri.png';
            case 'Impact investments':
                return 'exp-impact.png';
            case 'Venture Philanthropy':
                return 'exp-philanthropy.png';
            default:
                return null;
        }
    }
    return (
        <ul className="swipe-summary">
            { data.map((item: any, idx) => (
                <li key={`summary-${idx}`} style={{ backgroundImage: `url('${utils.baseUrl}/images/${getImageSources(item && item.title)}')` }}>
                    <img src={item.direction === 'right' ? IconCheck : (item.direction === 'skipped' ? IconSkipped : IconClose)} alt={item.title} />
                    <h3>{item.title}</h3>
                </li>
            ))}
        </ul>
    );
}

export default memo(SwipeSummary);