import React, { memo, useState } from "react";
import "./CheckMarkItemContainer.scss";
import CheckMarkImage from "components/CheckMarkImage/CheckMarkImage";

interface Props {
  items?: any,
  onSelect?: any,
  // onSelect?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined,
  name?: string,
  selected?: any,
  type?: 'radio' | 'checkbox',
}

const CheckMarkItemContainer: React.FC<Props> = ({ 
  items = [],
  onSelect = () => {},
  name,
  selected,
  type = 'radio'
}) => {
  const [checkedItem, setCheckedItem] = useState(selected);
  const [radioChecked,setRadioChecked] = useState(false);

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.checked) {
  //     setCheckedItem(e.target.id);
  //   } else {
  //     setCheckedItem(null);
  //   }
  //   onSelect(e);
  // };
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    let arr = [...checkedItem];
    if(e.target.type === 'radio'){
      setRadioChecked(true);
      arr = [];
      if (e.target.checked) {
        arr.push(e.target.id);
      } 
    } else {
     
      if(radioChecked){
        arr = [];
        setRadioChecked(false);
      }
      

      if (e.target.checked) {
        arr.push(e.target.id);
      } else {
        arr = arr.filter((p) => p !== e.target.id);
      }
    }
    onSelect(arr, name);
    setCheckedItem(arr);
  };

  return (
    <div className={'slide-item-container'}>
      {items.map((_item:any) => {
        const id = _item && _item.id && _item.id.toString && _item.id.toString()
        return (
          <div
            key={_item.id}
            // className={`slide-item ${
            //   checkedItem !== null && checkedItem !== id && checkedItem !== ''
            //     ? "slide-item-hide"
            //     : ""
            // }`}
            className={`slide-item ${checkedItem.indexOf(id) > -1 ? 'slide-item-hide' : ''}`}
          >
            <CheckMarkImage
              key={_item.id}
              id={_item.id}
              name={name}
              type={type}
              label={_item.label}
              title={_item.title}
              imgSrc={_item.imgSrc}
              // isChecked={(checkedItem !== null || checkedItem !== '') && checkedItem === id}
              isChecked={checkedItem.indexOf(id) > -1}
              onCheckMarkChange={handleChange}
            />
           </div>
        );
      })}
    </div>
  );
};

export default memo(CheckMarkItemContainer);
