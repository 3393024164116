import React, { memo } from 'react';
import { Button } from 'components';
import Tag from 'components/Tag/Tag';
import './Filter.scss'

type Props = {
  id?: string; // Filter name (fieldname)
  tags:any; // TODO create type for this
  title:string,
  onRemove?: any,
  onAdd?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined,
  buttonValue?: any,
  isEsgPreferences?: boolean,
};

const Filter: React.FC<Props> = memo(({
  id, tags, title, onRemove = () => {}, buttonValue = '+', onAdd = () => {},
  isEsgPreferences = false
}) => {
  const onHandleRemove = (e) => {
    onRemove(id, e);
  }
  return (
    <div className={`filter ${isEsgPreferences ? 'filter--esgPref' : ''}`} id={id}>
      <h6 className="filter__title">{title}</h6>
      <div className="filter__items">
        {tags.map((_item: string, idx: number)=> {return <Tag key={`tag-${idx}`} name={_item} onTagClick={e => onHandleRemove(e)} />})}
        <Button value={buttonValue} name={id} onButtonClick={onAdd} />
      </div>
    </div>
  );
});

export default Filter;
