import React from 'react';

import './simpleLayout.scss';
import { FsNavbar } from 'components';

const simpleLayout = (Wrapped: React.ComponentType) => (props: JSX.IntrinsicAttributes | (JSX.IntrinsicAttributes & { children?: React.ReactNode; })) => (
    <main className="app-wrapper">
        <FsNavbar />
        <div className="app-wrapper__container layout layout--simple">
            <section className="layout__body">
                <Wrapped {...props} />
            </section>
            
        </div>
    </main>
)

export default simpleLayout;