import React, { useState } from 'react';
import connect from 'shared/context/connect';
// import ReactPlayer from 'react-player';
import track from 'react-tracking';
import { trackButtonClick, buildAnswerCodeFromArr } from 'shared/lib/tracking.utils';
import storeAction from 'shared/context/actions';
import { useHistory } from 'react-router-dom';
import { PATH, FIELDS } from 'shared/constants/constants';
import WithBottomButton from 'components/Layout/WithBottomButton';
import InfoIcon from 'assets/images/information.svg';
import { EsgModal } from 'components';
import utils from 'shared/helpers/utils';
import { defaultLayout } from 'layouts';
import './Transition.scss';

export interface ITransitionProps {
    icon?: string,
    desc?: string,
    onSave?: any,
    onGetScore?: any,
    userId: string,
    tracking: Function,
}

const Transition: React.FC<ITransitionProps> = ({
    icon = `${utils.baseUrl}/icons/icon-transition.svg`,
    desc = 'Some investors have a strong aversion to certain categories such as tobacco and gambling. Would you like to specifically exclude these categories?',
    onSave = () => {},
    onGetScore = () => {},
    tracking = () => {},
    userId
}) => {
    const history = useHistory();
    const [showPopup, setShowPopup] = useState(false);
    const [showTipsPopup, setShowTipsPopup] = useState(false);
    const urlParams = new URLSearchParams(history.location.search);
    const transitionNumber = urlParams.getAll('id').toString();
    const isFirstTransition = !!(transitionNumber === '1');
    const renderIconSrc = isFirstTransition ? `${utils.baseUrl}/icons/icon-transition1.svg` : icon;
    
    const desc1 = 'Next, let’s understand your sustainable investing practices around SRI, ESG, Impact investing, and venture philanthropy.';
    const renderDesc = isFirstTransition ? desc1 : desc;

    const renderSkipValue = isFirstTransition ? <small className="knowMore">Learn more about sustainable investing</small> : 'Customise exclusions';
    const renderButtonValue = isFirstTransition ? "Let's do it" : 'Ignore exclusions';

    const handleCustomizeExclusion = () => {
        trackButtonClick(tracking, userId, [{ key: 'excluded', value: 'true' }]);
        history.push(PATH.EXCLUSIONS)
    }
    
    return (
        <WithBottomButton
            isGradientBg
            hideSkip={isFirstTransition}
            skipValue={renderSkipValue}
            buttonValue={renderButtonValue}
            onSkipClick={() => {
                !isFirstTransition ? handleCustomizeExclusion() : setShowPopup(true);
                // window.open(process.env.REACT_APP_EDUCATION_CENTER)
            }}
            onButtonClick={() => {
                if (!isFirstTransition) {
                    onSave(FIELDS.EXCLUSIONS, []);
                    trackButtonClick(tracking, userId, [{ key: 'excluded', value: 'false' }]);
                    Promise.all([onGetScore()]).then(() => {
                      history.push(PATH.SCORE);
                    });
                } else {
                    history.push(PATH.EXPERIENCE);
                }
                // history.push(isFirstTransition ? PATH.EXPERIENCE : PATH.SCORE);
            }}
        >
            <div className="transitionPage">
                <img className="back-button" src={renderIconSrc} alt="Icon" width={96} height={96} />
                <h4>{renderDesc}</h4>

                {!isFirstTransition && (
                    <button className="transitionPage__tipsBtn" type="button" onClick={() => setShowTipsPopup(true)}>
                        <span>Is exclusion the only way forward?</span> <img src={InfoIcon} />
                    </button>
                )}                
            </div>

            <EsgModal 
                showModal={showTipsPopup} 
                title="Is exclusion the only way forward?" 
                onClose={() => setShowTipsPopup(false)} 
                onConfirm={() => setShowTipsPopup(false)} 
            >
                <p>Sometimes, companies with a low sustainable rating might have more room for growth and improvement in the long 
                   term so it’s important for investors to remain diversified and consider each company and approach individually. &nbsp;
                   <a href="https://www.fundsingapore.com/education-center/article/bad-stocks-is-excluding-companies-the-only-way-forward" className="color-primary" target="_blank" rel="noreferrer" title="Is exclusion the only way forward?">Read more.</a>
                </p>
            </EsgModal>

            <EsgModal 
                showModal={showPopup} 
                title="Know more about sustainable investing" 
                onClose={() => setShowPopup(false)} 
                onConfirm={() => setShowPopup(false)} 
            >
                <p>Sustainable investing seeks to deliver competitive financial results while driving positive environmental, social and governance (ESG) outcomes. In other words, it considers how investments can impact people, planet, and profits.</p>
                <p>Sustainable investing approaches range from those aimed primarily at avoiding negative outcomes, be those investment our real-world outcomes, to those focused more on advancing positive outcomes. Investors may use one approach or combine several or all of them.</p>
                <p>For investments, a sustainability lens can help control risk, and it can help identify new investment opportunities by recognizing the material relevance of ESG factors and including them in their investment process, resulting in a more-complete picture of an investment. For the world, a sustainability lens can help investors avoid negative outcomes or achieve positive outcomes for people in the planet.</p>
            </EsgModal>
        </WithBottomButton>
    );
}

const mapStateToProps = ({ investmentState, rootState }) => ({
    // investmentStyle: investmentState.style,
    userId: rootState.userId,
});
  
const mapDispatchToProps = ({
    onSave: storeAction.updateInvestmentData,
    onGetScore: storeAction.getUserScore,
});

export default track()(connect(mapStateToProps, mapDispatchToProps)(defaultLayout(Transition)));