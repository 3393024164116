const ACTION_TYPE = {
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',

  UPDATE_INVESTMENT_DATA: 'UPDATE_INVESTMENT_DATA',
  UPDATE_GROUPED_SUPPORTS: 'UPDATE_GROUPED_SUPPORTS',
  CLEAR_SUPPORTS: 'CLEAR_SUPPORTS',
  GET_USER_PORTFOLIO: 'GET_USER_PORTFOLIO',
  CLEAR_USER_INPUT_DATA: 'CLEAR_USER_INPUT_DATA',

  REQUEST_FAILED: 'REQUEST_FAILED', 

  AUTH: {
    PHONE_NUMBER: 'PHONE_NUMBER',
    REQUEST_OTP_SUCCESS: 'REQUEST_OTP_SUCCESS',
    VALIDATE_TOKEN_SUCCESS: 'VALIDATE_TOKEN_SUCCESS',
    ERROR: 'AUTH_ERROR',
    UPDATE_USER_ID: 'UPDATE_USER_ID',
    LOGOUT: 'LOGOUT'
  },
  PRODUCTS: {
    INITIALIZE_PRODUCTS: 'INITIALIZE_PRODUCTS',
    PRODUCTS_LOADED: 'PRODUCTS_LOADED',
    TOTAL_OF_RECORDS_CHANGED: 'TOTAL_OF_RECORDS_CHANGED',
    NUMBER_OF_RECORDS_CHANGED: 'NUMBER_OF_RECORDS_CHANGED',
    PRODUCT_FILTER_CHANGED: 'PRODUCT_FILTER_CHANGED',
    FILTER_PRODUCT_NAME_CHANGED: 'FILTER_PRODUCT_NAME_CHANGED',
    ERROR: 'FAILED_FETCHING_PRODUCTS',
    IS_PRODUCT_LOADING: 'IS_PRODUCT_LOADING',
  },

  MASTER_DATA: 'SAVE_MASTER_DATA',

  STREAMING_NEWS: 'STREAMING_NEWS',
  POST_SEARCH_CRITERIA: 'POST_SEARCH_CRITERIA',
  GET_SEARCH_CRITERIA: 'GET_SEARCH_CRITERIA',
};

export default ACTION_TYPE;
