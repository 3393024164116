/* eslint-disable react/button-has-type */
import React, { useState, useRef, useCallback, useEffect, memo } from 'react';
import classnames from 'classnames';
import './IconButton.scss';
import IconBtn from 'assets/images/information.svg';

interface Props {
  id: string,
  popupcontent: React.ReactNode | string,
}
const IconButton: React.FC<Props> = ({
  id, popupcontent,
}) => {

  const classes = classnames({
    'icon-btn': true,
  });

  const [showPopup, setShowPopup] = useState(false)
  const onClick = () => {
    setShowPopup(!showPopup)
  }

  const iconBtnRef = useRef(null);
  const handleClickOutside = useCallback(
		(event) => {
			if (iconBtnRef.current && !iconBtnRef.current.contains(event.target)) {
				setShowPopup(false);
			}
		},
		[setShowPopup]
	);

  useEffect(() => {
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, []);

  return <div className={classes} ref={iconBtnRef} onClick={onClick} id={id}>
    <img className='icon-btn__button'
        src={IconBtn}
    />
    {showPopup ?
    <span className='icon-btn__content'>{popupcontent}</span> : null }
  </div>

};
export default memo(IconButton);