import axios from 'axios';
import qs from 'qs';

const AUTH_URL = process.env.REACT_APP_AUTH;
const API_PORTFOLIO_URL = process.env.REACT_APP_API_PORTFOLIO;
const API_FUNDS_URL = process.env.REACT_APP_API_FUNDS;
const API_URL = process.env.REACT_APP_API;
const MASTER_DATA_URL = process.env.REACT_APP_MASTER_DATA_API;
const TRACKING_API_URL = process.env.REACT_APP_TRACKING_API_URL_IMAS;

// Request interceptor
axios.interceptors.request.use((config) => config, (error) => Promise.reject(error));

// Response interceptor
axios.interceptors.response.use((response) => response, (error) => {
  const { status } = error.response;
  if (status === 401) {
    localStorage.clear();
    window.location.replace('/');
  }
  return Promise.reject(error);
});

/**
 * Request OTP
 * @param phoneNumber
 */
const requestOTP = (phoneNumber) => {
    try {
        const res = axios({
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: `${AUTH_URL}/otp`,
            // url: `${AUTH_URL}/otp?channel=smart_advice&username=${phoneNumber}`,
            data: qs.stringify({ channel: 'smart_advice', username: phoneNumber }),
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error.response);
    }
};

/**
 * Validate OTP received on the phone number
 * @param phoneNumber 
 * @param password 
 * @returns token
 */
const validateOTP = async (phoneNumber, password) => {
    try {
        const res = await axios({
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: `${AUTH_URL}/otp/login`,
            // url: `${AUTH_URL}/otp?channel=smart_advice&username=${phoneNumber}`,
            data: qs.stringify({ password, username: phoneNumber }),
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error.response);
    }
};

/**
 * Check if token is valid
 * @param token 
 * @returns true if valid
 */
const validateToken = async (token) => {
    try {
        const res = await axios({
            method: 'POST',
            headers: { Authorization: `Bearer ${token}` },
            url: `${AUTH_URL}/auth/token`,
        });
        return Promise.resolve(res);
    } catch (error) {
        return Promise.reject(error.response);
    }
}

/**
 * Get User Portfolio based on user inputted data
 * @param token string
 * @param principal string (phoneNumber)
 * @param supports Array<string>
 * @param excludes Array<string>
 */
const postUserScore = async (token, principal, supports, excludes, threshold, carbonRiskScore, submittedAnswers) => {
    try {
        const res = await axios({
            method: 'POST',
            headers: { Authorization: `Bearer ${token}`, principal },
            url: API_PORTFOLIO_URL,
            data: { supports, excludes, threshold, carbonRiskScore, submittedAnswers }
        });
        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error.response);
    }
}

/** Master data for options */
const getMasterData = async () => {
    try {
        const res = await axios({
            method: 'GET',
            headers: { 'Content-type': 'application/json', 'charset':'utf-8' },
            url: MASTER_DATA_URL,
        });
        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error.response);
    }
}

const getProducts = async (token, supports, excludes, threshold, carbonRiskScore) => {
    try {
        const res = await axios({
            method: 'POST',
            headers: { Authorization: `Bearer ${token}`, distributor: 'esg' },
            url: API_FUNDS_URL,
            data: { excludes, supports, threshold, carbonRiskScore },
        });
        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error.response);
    }
}

const getNews = async (language) => {
    try {
        const res = await axios({
            method: 'GET',
            headers: { 'Content-type': 'application/json', 'charset':'utf-8' },
            url: `${API_URL}rss?locale=${language}`,
        });
        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error.response);
    }
}

const postSearchCriteria = async (token, keywords) => {
    try {
        const res = await axios({
            method: 'POST',
            headers: { Authorization: `Bearer ${token}` },
            url: `${API_URL}user/rss/search-criteria`,
            data: { keywords },
        });
        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error.response);
    }
}

const getSearchCriteria = async (token) => {
    try {
        const res = await axios({
            method: 'GET',
            headers: { 'Content-type': 'application/json', 'charset':'utf-8', Authorization: `Bearer ${token}` },
            url: `${API_URL}user/rss/search-criteria`,
        });
        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error.response);
    }
}

const postTrackingData = async (data) => {
    try {
        const res = await axios({
            method: 'POST',
            url: TRACKING_API_URL,
            data: data,
        });
        return Promise.resolve(res.data);
    } catch (error) {
        return Promise.reject(error.response);
    }
}


const apiService = {
    requestOTP,
    validateOTP,
    validateToken,

    getMasterData,
    postUserScore,
    getProducts,

    getNews,
    postSearchCriteria,
    getSearchCriteria,

    postTrackingData,
}

export default apiService;