import React from 'react';
import utils from 'shared/helpers/utils';
import './authLayout.scss';

const authLayout = (Wrapped: React.ComponentType) => (props: (JSX.IntrinsicAttributes) | (JSX.IntrinsicAttributes & { children?: React.ReactNode; })) => (
    <main className="auth-wrapper">
        <div className="auth-wrapper__container">
            <header className="auth-wrapper__header">
                <section className="nav-header">
                    <img src={`${utils.baseUrl}/images/logo-black.svg`} alt="logo" />

                    <div className="nav-header__content">
                        <h3>ESG PROFILER</h3>
                        <h6>ESG investments simplified</h6>
                    </div>
                </section>
            </header>

            <section className="auth-wrapper__body">
                <section className="auth-wrapper__body__container">
                    <Wrapped {...props} />
                </section>
            </section>
        </div>
    </main>
)

export default authLayout;