// import { FIELDS } from 'shared/constants/constants';
import { orderBy } from 'lodash'; // lodash, {uniq, map, compact, property}
import { STORAGE_KEY } from './../../constants/constants';
// import { useContext } from 'react';
// import { GlobalContext } from 'shared/context/store';

// const {
//     state: { investmentState, productState },
// } = useContext(GlobalContext);


/** 
 * Order/ Sort Array by field 
 * @param data : array of objects
 * @param filterName(s) : field name to be filtered
 * @param filterDirection : asc / desc 
 * */
const orderByField = (data = [], filterName, filterDirection = '') => orderBy(data, filterName, filterDirection);

const products = productState => productState.data;

// const getProductsFilter = productState => productState.filters;
// const getFilterProductRisk = productState => {
//     return getProductsFilter(productState)[FIELDS.PRODUCT_RISK];
// }
// const getFilteredProducts = productState => {

// }

const modifiedProducts = productState => {
    const products = productState;
    // const productRiskFilter = getFilterProductRisk(productState);
    // const productRiskFilter = productState.filters.productRisk; // FOR SOME REASON, I'M NOT GETTING THE LATEST STATE FROM THE STORE SO I USE LOCAL STATE
    const localStore = JSON.parse(localStorage.getItem(STORAGE_KEY.ESG_STATE));
    const productRiskFilter = localStore && localStore.productState.filters.productRisk;

    // console.log('FILTERS ', productRiskFilter[0], productRiskFilter[1]);
    if (!products) {
        return null;
    }

    let modifiedData = [];

    products && products.forEach((item) => {
        const data = {
            isin: item.ISIN,
            title: item.FundName,
            ratingNumber: item.SustainabilityRating,
            riskRating: item.RiskRating,
            sustainabilityScore: item.SustainabilityScore, // to sort by desc order
            imgSrc: item.logo || null,
            isLowCarbon: item.LowCarbonDesignation,
        }
        const lastestNavData = {
            latestNav: (item.ClosePrice).toFixed(2),
            dateNav: item.ClosePriceDate,
        }
        const performance = [
            { /* INCEPTION */
                annualizedReturn: '6.91%',
                benchmarkReturn: '3.55%',
                ...lastestNavData
            }, { /* 5Y */
                annualizedReturn: (item.ReturnM60 === 0 ? '-' : `${item.ReturnM60}%`) || '-',
                benchmarkReturn: '3.67%',
                ...lastestNavData
            }, { /* 3Y */
                annualizedReturn: (item.ReturnM36 === 0 ? '-' : `${item.ReturnM36}%`) || '-',
                benchmarkReturn: '8.72%',
                ...lastestNavData
            }, { /* 1Y */
                annualizedReturn: (item.ReturnM12 === 0 ? '-' : `${item.ReturnM12}%`) || '-',
                benchmarkReturn: '11.60%',
                ...lastestNavData
            }, { /* 1M */
                annualizedReturn: (item.ReturnM1 === 0 ? '-' : `${item.ReturnM1}%`) || '-',
                benchmarkReturn: '2.34%',
                ...lastestNavData
            }
        ];
        const parsedData = Object.assign(data, {performance});
        modifiedData.push(parsedData);
    })
    const minRiskLevel = productRiskFilter && productRiskFilter[0];
    const maxRiskLevel = productRiskFilter && productRiskFilter[1];
    const filteredDataByRisk = modifiedData.filter((item) => {
        return !!(item.riskRating >= minRiskLevel && item.riskRating <= maxRiskLevel);
    });
    const orderedList = orderByField(filteredDataByRisk, ['ratingNumber', 'sustainabilityScore'], 'desc');
    // console.log('SELECTOR >> FILTERED DATA', orderedList.length, minRiskLevel, maxRiskLevel);
    return orderedList;
}

const selectors = {
    products,
    modifiedProducts
}

export default selectors;