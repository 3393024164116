import { Button } from 'components';
import React, { memo } from 'react';
import classnames from 'classnames';
import { isMobile } from 'react-device-detect';
import './WithBottomButton.scss';
import { isHeritageClause } from 'typescript';


type Props = {
  children: React.ReactNode;
  onButtonClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
  title?: String;
  description?: String;
  hideSkip?: Boolean;
  skipValue?: string | React.ReactNode;
  disable?: boolean;
  onSkipClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
  buttonValue?: String | React.ReactNode;
  isGradientBg?: boolean;
  isButtonSwapOrder?: boolean;
};

const WithBottomButton: React.FC<Props> = memo(({
  children, onButtonClick = () => {}, title, description, hideSkip = false, disable=false,
  skipValue = 'Skip', onSkipClick = () => {},
  buttonValue = 'Next',
  isGradientBg = false,
  isButtonSwapOrder = false
}) => {
  const mainClasses = classnames({
    'with-bottom-button': true,
    'with-bottom-button--gradientBg': isGradientBg
  });
  const bottomClasses = classnames({
    'with-bottom-button__bottom': true,
    'with-bottom-button__bottom--swapOrder': isButtonSwapOrder,
    'with-bottom-button__bottom--skip': !hideSkip,
  });
  return (
    <div className={mainClasses}>
         {title && <h1 className='with-bottom-button__title'>{title}</h1>}
         {description && <div className='with-bottom-button__desc'>{description}</div>}
         
        <div className='with-bottom-button__main'>{children}</div>
        {/* <span className="spacer" /> */}
        <div className={bottomClasses}>
          {!hideSkip && <Button isTextButton isBig isFullWidth={!!isMobile} value={skipValue} onButtonClick={onSkipClick} />}
          {!isMobile ? <span /> : null}
          <Button isDisabled={disable} isBig isFullWidth={!!isMobile} onButtonClick={onButtonClick} value={buttonValue} />
        </div>
    </div>
  );
});

export default WithBottomButton;
