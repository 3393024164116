import React, { useMemo } from "react";
import connect from "shared/context/connect";
import { useHistory } from "react-router-dom";
import { PATH, FIELDS } from "shared/constants/constants";
import storeAction from "shared/context/actions";
import { CheckMarkItemContainer } from "components";
import WithBottomButton from "components/Layout/WithBottomButton";
import utils from "shared/helpers/utils";
import { defaultLayout } from "layouts";
import track from 'react-tracking';
import { trackButtonClick, buildAnswerCodeFromArr } from 'shared/lib/tracking.utils';

interface Props {
  onSave?: any;
  investHighRanking?: string;
  data: Array<object>;
  userId: string;
  tracking: Function;
}
const HighRanking: React.FC<Props> = ({
  onSave = () => {},
  investHighRanking,
  data,
  tracking = () => {},
  userId
}) => {
  // const [checkedItem, setCheckedItem] = useState(governance);
  const history = useHistory();
  const { getStringAfterSpecialChar } = utils;

  // const handleItemChecked = (e: any) => {
  //   if (e.target.checked) {
  //     onSave(e.target.name, e.target.id);
  //   } else {
  //     onSave(e.target.name, null);
  //   }
  // };

  const handleItemChecked = (value: any, name: string) => {
    onSave(name, value);
  };

  const handleSkipped = () => {
    trackButtonClick(tracking, userId, [
      { key: 'questionCode', value: data[0]['code'] },
      { key: 'skipped', value: 'true' },
    ]);
    onSave(FIELDS.HIGH_RANKING, []);
    history.push(PATH.MOMENTUM);
  };

  const renderOptions = useMemo(() => {
    const mappedData = data.map((item: any) => ({
      id: item.code,
      title: item.name,
      label: item.desc,
      imgSrc: `high-rank-${getStringAfterSpecialChar(item.code, ".")}.png`,
    }));
    return mappedData;
  }, [data]);

  return (
    <WithBottomButton
      // title="Governance"
      description={
        <>
          <p>
            Would you invest in high ESG ranking companies within low ESG
            scoring industries (also known as hard to abate industries or sectors)? 
            For example - an airline or an oil major that is
            the best in their respective industries.
          </p>
          {/* <p>For example - an airline or an oil major that is the best in their respective industries.</p> */}
        </>
      }
      onSkipClick={() => handleSkipped()}
      // hideSkip
      disable={!investHighRanking.length}
      onButtonClick={() => {
        const answerCode = buildAnswerCodeFromArr(investHighRanking);
        trackButtonClick(tracking, userId, [
          { key: 'questionCode', value: data[0]['code'] },
          { key: 'answerCode', value: answerCode },
        ]);
        history.push(PATH.MOMENTUM);
      }}
    >
      <CheckMarkItemContainer
        name={FIELDS.HIGH_RANKING}
        selected={investHighRanking}
        items={renderOptions.slice(1)}
        // items={[
        //   { id: 'investHighRanking-yes', label: "I am comfortable investing in companies that are the best in their industry, even if the industry has poor ESG score.", title: 'Yes', imgSrc: 'high-rank-yes.png' },
        //   { id: 'investHighRanking-no', label: "ESG factors cannot be measured in relative terms. ESG scores must be high irrespective of the industry.", title: 'No', imgSrc: 'high-rank-no.png' },
        // ]}
        type="radio"
        onSelect={handleItemChecked}
      />
    </WithBottomButton>
  );
};

const mapStateToProps = ({ investmentState, rootState }) => ({
  investHighRanking: investmentState.investHighRanking,
  data: rootState.masterData.EN.absolute,
  userId: rootState.userId
});

const mapDispatchToProps = {
  onSave: storeAction.updateInvestmentData,
};

export default track()(connect(
  mapStateToProps,
  mapDispatchToProps
)(defaultLayout(HighRanking)));
