import React, { memo } from "react";
import './EsgPerformance.scss';

import utils from 'shared/helpers/utils';

export interface IEsgDetailsProps {
    annualizedReturn?: number,
    benchmarkReturn?: number,
    latestNav?: number,
    dateNav?: any,
}

const EsgDetails: React.FC<IEsgDetailsProps> = ({
    annualizedReturn = 6.9,
    benchmarkReturn = 3.7,
    latestNav = 1.42,
    dateNav = Date.now(),
}) => {
    return (
        <section className="esg-details">
            <div className="esg-details__item">
                <h3 className="esg-details__item__value">{annualizedReturn}</h3>
                <small className="esg-details__item__label">Annualized return</small>
            </div>
            <div className="esg-details__item">
                <h3 className="esg-details__item__value">{benchmarkReturn}</h3>
                <small className="esg-details__item__label">Benchmark return</small>
            </div>
            <div className="esg-details__item">
                <h3 className="esg-details__item__value">{latestNav}</h3>
                <small className="esg-details__item__label">
                    Latest NAV
                    <span>{utils.formatDate(dateNav)}</span>
                </small>
            </div>
        </section>
    );
}

export default memo(EsgDetails);