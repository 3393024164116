import React,{useState} from "react";
import { CheckMarkOption } from "components";
import "./CheckMarkOtions.scss";

interface Props {
  checkedValues: any; //TODO: define type this each elemnt of this
  onCheckMarkChange: any;
  optionList: any; //TODO: define type this each elemnt of this
  name?: string;
  type?: 'radio' | 'checkbox',

}

const Options: React.FC<Props> = ({
  optionList,
  onCheckMarkChange = () =>{},
  type,
  checkedValues,
  name,
}) => {

  const [checkedItem, setCheckedItem] = useState(checkedValues);
  const [radioChecked,setRadioChecked] = useState(false);
  const handleItemChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    let arr = [...checkedItem];
    if(event.target.type === 'radio'){
      setRadioChecked(true);
      arr = [];
      if (event.target.checked) {
        arr.push(event.target.id);
      } 
    } else {
     
      if(radioChecked){
        arr = [];
        setRadioChecked(false);
      }
      

      if (event.target.checked) {
        arr.push(event.target.id);
      } else {
        arr = arr.filter((p) => p !== event.target.id);
      }
    }
    // console.log('arr', arr);
    onCheckMarkChange(arr,name);
    setCheckedItem(arr);
  };

  return (
    <div className={`options`}>
      {optionList &&
        optionList.map((item: any, i: number) => (
          <CheckMarkOption
            key={`${item.name}-${i}`}
            id={item.id}
            values={checkedItem}
            label={item.label}
            title={item.title}
            type={type}
            name={name}
            onCheckMarkChange={handleItemChecked}
          />
        ))}
    </div>
  );
};

export default Options;
