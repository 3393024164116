/* eslint-disable react/button-has-type */
import React, { memo } from 'react';
import './Tag.scss';
import utils from 'shared/helpers/utils';
import vectorIcon from '../../assets/images/Vector.png';
interface Props {
  value?: string | React.ReactNode;
  name?: string | undefined;
  onNormalTagClick? :any;
  onTagClick?: any;
  icon? : string;
  isNormalTag?: boolean;
}

const Tag: React.FC<Props> = ({
  // value = null,
  name = '',
  onNormalTagClick = () => {},
  onTagClick = () => {},
  icon = vectorIcon,
  isNormalTag = false,
}): JSX.Element => {
  const onHandleTagClick = () => {
    onTagClick(name)
  }
  return (
    <>
      <div className={'nw-tag'}>
        {isNormalTag? 
          <span className={'nw-tag__normalTagName'} onClick={onNormalTagClick}>{name}</span>
          :
          <span className={'nw-tag__name'}>{utils.temporaryParseId[name]}</span>
        }
        {/* <span className={'nw-tag__x'}>x</span> */}
        <img src={icon} alt="remove" onClick={onHandleTagClick} />
      </div>
    </>
  );
};

export default memo(Tag);
