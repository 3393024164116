import React, { useState, useCallback, useEffect, PureComponent, useContext } from 'react';
import { GlobalContext } from 'shared/context/store';
import { FormattedMessage } from 'react-intl';
import { defaultLayout } from 'layouts';
import connect from 'shared/context/connect';
import { NewsItem, PageLoader, EsgModal } from 'components';
import SearchFilter from './FilterNewsComponent/SearchFilter';
import { useStreamingNews } from 'shared/customHooks';
import utils from '../../shared/helpers/utils';

import './StreamingNews.scss';
import NewsDetails from 'components/NewsDetails';
import storeAction from 'shared/context/actions';

export interface StreamingNewsProps {
    id: string,
    displaySearchFilter: boolean,
    onCloseSearchFilter: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined,
    onStoreSearchHistory?: any,
    onRemoveSearchCriteria?: any,
    onGetSearchHistory?: any,
    saveNews?: any,
    newsData?: any,
    searchHistory?: any,
}

const StreamingNews: React.FC<StreamingNewsProps> = ({
    id,
    displaySearchFilter,
    onCloseSearchFilter,
    onStoreSearchHistory = () => {},
    onRemoveSearchCriteria = () => {},
    onGetSearchHistory = () => {},
    saveNews = () => {},
    newsData,
    searchHistory,
}) => {
    const { status, data, isFetching, error } = useStreamingNews(saveNews);

    const [showNewsDetails, setShowNewsDetails] = useState(false)

    const [newsTitle, setNewsTitle] = useState('')
    const [newsDescription, setDescription] = useState('')
    const [newsPublishDate, setPublishDate] = useState('')
    // const [newsAuthor, setAuthor] = useState('')
    
    const [searchTerm, setSearchTerm] = useState('');

    /** Retrieve State for Language */
    const { state: { rootState } } = useContext(GlobalContext);

    const search = (e) => {
        if(e.target.value !== null && e.target.value.match(/^ *$/) === null && e.key === 'Enter') {
            onCloseSearchFilter(e);
            const searchedVal = e.target.value.toLowerCase();
            setSearchTerm(searchedVal);
            onStoreSearchHistory(e.target.value);
        }
    }

    const onHandleSearchValChange = (e) => {
        setSearchTerm(e.target.value)
    }
    
    const onHandleSeachValReset = (e) => {
        onCloseSearchFilter(e);
        setSearchTerm('')
    }

    const filteredSearch = useCallback(() => {
        const newsDataCopy = [...newsData];
        let searchResult = [];
        searchResult = !searchTerm ? newsDataCopy
            :  newsDataCopy.filter(x => x.title.toLowerCase().includes(searchTerm) || x.description.toLowerCase().includes(searchTerm) || x.codes.toLowerCase().includes(searchTerm));
        return searchResult;
    }, [searchTerm, newsData]);

    useEffect(() => {
        onGetSearchHistory()
      }, []);

    const handleTagClick = (e) => {
        onCloseSearchFilter(e);
        const searchedVal = e.currentTarget.textContent.toLowerCase();
        setSearchTerm(searchedVal);
    }
    
    const handleClearBtn = (e) => {
        e = searchHistory.splice(0, searchHistory.length)
        onRemoveSearchCriteria(e)
    };

    const handleRemoveItem = (e) => {
        onRemoveSearchCriteria(e)
    };

    return (
        <section className="streaming-news" id={id}>
            {status === 'loading' ? (
                <PageLoader />
            ) : status === "error" ? (
                <span>Error: {error.message}</span>
            ) : (
                <>
                    <SearchFilter
                        showSearchFilter={displaySearchFilter}
                        onClose={onCloseSearchFilter}
                        placeholder={rootState.language.locale === 'EN' ? "Search by Symbol, Company Name & Keyword" : "请输入要搜索的词"}
                        onKeyDownSearchInput={search}
                        searchVal={searchTerm}
                        onHandleSearchVal={onHandleSearchValChange}
                        onSeachValReset={onHandleSeachValReset}
                        tagLength={searchHistory.length}
                        clearBtnVal={<FormattedMessage id="searchFilter.clearAll"/>}
                        onHandleClearBtn={handleClearBtn}
                        onNormalTagClick={handleTagClick}
                        onHandleRemove={handleRemoveItem}
                        tags={searchHistory}
                    />
                    <EsgModal showModal={showNewsDetails} withButton={false} title='News Story' onClose={() => {setShowNewsDetails(false)}}>
                        <NewsDetails
                            title={newsTitle}
                            description={newsDescription}
                            dateString={newsPublishDate}
                            // author={newsAuthor}
                        />
                    </EsgModal>
                    { data && filteredSearch().map((product: any, index: any) => (
                        <NewsItem
                            newsItemId={index}
                            key={index}
                            newsTitle={product.title}
                            dateTime={rootState.language.locale === 'EN' ? utils.epochToShortDateString(product.publishDate*1000) : utils.cnDateTime(product.publishDate)}
                            // logo={product.logo}
                            primaryTags={['budget', 'surplus']}
                            secondaryTags={['J&JS']}
                            onClick={()=>{
                                setNewsTitle(product.title)
                                setDescription(product.description)
                                setPublishDate(rootState.language.locale === 'EN' ? utils.epochToShortDateString(product.publishDate*1000) : utils.cnDateTime(product.publishDate))
                                // setAuthor(product.author)
                                setShowNewsDetails(!showNewsDetails)
                            }}
                            {...product}
                        />
                    ))}
                    {filteredSearch().length === 0 ?
                        <div className="streaming-news__resultError"><FormattedMessage id="searchFilter.noResult" /></div> :
                            filteredSearch().length === 1 ?
                                <div className="streaming-news__resultNumber"><FormattedMessage id="searchFilter.singularResult" values={{ number: filteredSearch().length }} /></div> :
                                    <div className="streaming-news__resultNumber"><FormattedMessage id="searchFilter.pluralResult" values={{ number: filteredSearch().length }} /></div>

                    }
                    <div>{isFetching ? "Background Updating..." : " "}</div>
                </>
            )}
        </section>
        
    );
};

const mapStateToProps = ({ newsState }) => ({
    newsData: newsState.data,
    searchHistory: newsState.searchTerms,
  });
  
  const mapDispatchToProps = ({
    saveNews: storeAction.storeNews,
    onStoreSearchHistory: storeAction.storeSearchCriteria,
    onRemoveSearchCriteria: storeAction.removeSearchCriteria,
    onGetSearchHistory: storeAction.getSearchCriteria,
  });


export default connect(mapStateToProps, mapDispatchToProps)(defaultLayout(StreamingNews));