export const trackButtonClick = (tracking, userId, arrKeyValue) => {
    try {
        const { app } = tracking.getTrackingData();
        let data = arrKeyValue.map(item => ({ ...item, eventType: 'esg_tracking' })) || [];
        if (userId) {
            data.push({
                eventType: 'esg_tracking',
                key: 'userId',
                value: userId
            });
        }
        const body = {
            app,
            data
        }
        if (data.length) {
            tracking.trackEvent(body);
        }
    } catch {
        // ignore err
    }
}

export const buildAnswerCodeFromArr = (arr) => {
    let result = '';
    Array.prototype.map.call(arr, (item, index) => {
        result += ((index !== 0 ? ',' : '') + item.toString());
    });
    return result;
}
