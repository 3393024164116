// import BackButton from "components/Button/BackButton/BackButton";
import React, { memo } from "react";
import './ProgressBar.scss';

interface IProgressBarProps {
    progress?: number,
}

const ProgressBar: React.FC<IProgressBarProps> = ({
    progress = 0,
}) => {
    return (
        <section className="progress-bar">
           
            <div className="progress-bar__container">
                <span className="progress-bar__container__gauge" style={{ width: `${progress}%` }} />
            </div>
            {/* <BackButton/> */}
        </section>
    );
}

export default memo(ProgressBar);