import React, { useState, memo } from "react";
import classnames from 'classnames';
import './Switcher.scss';

interface SwitcherProps {
    name: string,
    id: string,
    isOn: boolean,
    onChange?: any,
    isDisabled: boolean,
    isHidden: boolean,
}

const Switcher: React.FC<SwitcherProps> = ({
    name, id, isOn = false, onChange = () => {}, isDisabled = false, isHidden = false,
}) => {

    const classes = classnames({
        'switcher': true,
        'switcher--disabled': isDisabled,
      });
      
      const [switchValue, setSwitchValue] = useState(isOn);
      const handleChange = (e) => {
		setSwitchValue(!switchValue);
		onChange(e);
	};

    return (
        <>
            {!isHidden && <label className={classes}>
                <input className='switcher__input' id={id}
                    name={name}
                    type='checkbox'
                    checked={(isOn = !switchValue)}
                    onChange={handleChange}
                    disabled={isDisabled} 
                />
                <span className='switcher__slider' />
            </label>}
		</>
    );
}

export default memo(Switcher);