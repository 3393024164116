import React, { useRef, useEffect, useCallback, memo } from 'react';
import ReactDOM from 'react-dom';
import './EsgModal.scss';
import { Button } from 'components';
import CloseIcon from 'assets/images/icon-close.svg';


interface IEsgModalProps {
    title?: string,
    customClassName?: string,
    children?: React.ReactNode,
    // onClose?: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined,
    onClose?: any,
    showModal?: boolean,
    onConfirm?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined,
    withButton?: boolean,
    // closeModalFunc?: func
  }
const EsgModal: React.FC<IEsgModalProps> = ({
  title,
  customClassName = '',
  children = <></>,
  onClose = () => {},
  showModal = false,
  onConfirm = () => {},
  withButton = true,
}) => {

  const modalRef = useRef(null);

//   const handleConfirm = useCallback(() => {
//     onConfirm();
//     onClose();
//   },[onConfirm,onClose]);


  const handleClickOutside = useCallback((event: any) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  },[onClose]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  if (showModal) {
    return ReactDOM.createPortal(
      <div className="modal-overlay">
        <div className="modal-overlay__container">
          <section className={`modal ${customClassName}`} ref={modalRef}>
            {title && (
              <header className="modal__header">
                <span />
                <h3 className="modal__header__title">{title}</h3>
                <img src={CloseIcon} alt="close" onClick={onClose} />
              </header>
            )}
            <div className="modal__content">
              {children}
            </div> 
            <footer className="modal__footer">
              {withButton && <Button value="Done" isBig onButtonClick={onConfirm} />}
            </footer>
          </section>
        </div>
      </div>,
      document.querySelector('#app'),
    );
  }
  return null;
};

export default memo(EsgModal);
