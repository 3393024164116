import React, { memo, useEffect, useRef, useContext } from 'react';
import './App.scss';
import track from 'react-tracking';
import classnames from 'classnames';
import { useHistory } from "react-router-dom";
import { isMobileOnly, isBrowser, deviceType } from 'react-device-detect';
import utils from 'shared/helpers/utils';
import { idleTimer, clearIdleTimer } from 'shared/helpers/idleTimer';
import { STORAGE_KEY, EXPIRATION_TIME } from 'shared/constants/constants';
import storeAction from 'shared/context/actions';
import { GlobalContext } from 'shared/context/store';
import apiService from 'shared/services/apiService';
import 'rc-slider/assets/index.css';
// import ConnectedIntlProvider from './components/utils/ConnectedIntlProvider';
import Routes from 'Routes';
const appName = process.env.REACT_APP_TRACKING_APP_NAME;

import { QueryClient, QueryClientProvider } from 'react-query';

const App: React.FC = (): JSX.Element => {
  const history = useHistory();
  const timer = useRef(null);
  const { state, dispatch } = useContext(GlobalContext);
  
  const classes = classnames({
    'app': true,
    'app--desktop': (!isMobileOnly && isBrowser) || (deviceType === 'tablet'),
    'app--mobileBrowser': isMobileOnly && !utils.isInStandaloneMode(),
    'app--pwa': isMobileOnly && utils.isInStandaloneMode(),
  });

  /**
   * Check app for any event
   * Update timer when there is event
   * then clear data if no event ater 30mins
   * @param timerRef : timer
   */
  const refreshApp = (timerRef) => {
    /** refresh user function */
    const expirationDate = JSON.parse(localStorage.getItem(STORAGE_KEY.EXPIRATION_DATE));
    const rightNow = new Date().getTime();
    const isExpired = expirationDate && (rightNow - expirationDate) >= EXPIRATION_TIME;
    // console.log('is expired ', isExpired, expirationDate, rightNow, timerRef);
    if (isExpired) {
      console.log('SESSION EXPIRED!');
      storeAction.onClearUserInputData()(dispatch);
      localStorage.removeItem(STORAGE_KEY.EXPIRATION_DATE);
    }

    // refresh user after 15 mins inactive
    idleTimer(timerRef, '#app', () => {
      /** CLEAR USER INPUT DATA */
      if (localStorage.getItem(STORAGE_KEY.ESG_STATE)) {
        storeAction.onClearUserInputData()(dispatch);
        localStorage.removeItem(STORAGE_KEY.EXPIRATION_DATE);
      }
    }, EXPIRATION_TIME);

  }

  useEffect(() => {
    refreshApp(timer.current);
    storeAction.fetchMasterData()(dispatch);
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get('userId');
    storeAction.updateUserId(userId)(dispatch, state);
    if (userId) {
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete('userId');
      window.history.replaceState(null, '', newUrl.href);
    }
    /** on component will unmount, clear the timer */
    return () => {
      clearIdleTimer(timer.current);
    }
  }, []);

  /** Create client */
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <main className={classes} id="app">
        <Routes />
      </main>
    </QueryClientProvider>
  )
};

const trackData = apiService.postTrackingData;
const TrackedApp = track(
  { app: appName },
  { dispatch: (data) => {
      trackData(data)
  }
}
)(App)

export default memo(TrackedApp);
