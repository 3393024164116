import WithBottomButton from "components/Layout/WithBottomButton";
import React, { useState, useMemo } from "react";
import connect from "shared/context/connect";
// import ReactPlayer from 'react-player';
import { useHistory } from "react-router-dom";
import { PATH, FIELDS } from "shared/constants/constants";
import utils from "shared/helpers/utils";
import storeAction from "shared/context/actions";
import { defaultLayout } from "layouts";
import { SwipeCard, SwipeSummary, EsgModal } from "components";
import { isMobileOnly } from "react-device-detect";
import { useWindowSize } from "shared/customHooks";
import track from 'react-tracking';
import { trackButtonClick } from 'shared/lib/tracking.utils';

import EsgImg from "assets/images/swipe-esg.png";
import ImpactImg from "assets/images/swipe-impact.png";
import SriImg from "assets/images/swipe-sri.png";
import VentureImg from "assets/images/swipe-philanthropy.png";
import EsgImgDesktop from "assets/images/swipe-esg-desktop.png";
import ImpactImgDesktop from "assets/images/swipe-impact-desktop.png";
import SriImgDesktop from "assets/images/swipe-sri-desktop.png";
import VentureImgDesktop from "assets/images/swipe-philanthropy-desktop.png";

interface Props {
  onSave?: any;
  experience?: Array<Object>;
  // onGetScore?: any,
  environment?: string;
  social?: string;
  governance?: string;
  userId: string;
  tracking: Function;
}
const Experience: React.FC<Props> = ({
  onSave = () => {},
  experience,
  // onGetScore = () => {},
  environment,
  social,
  governance,
  tracking = () => {},
  userId,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  // const [checkedItem, setCheckedItem] = useState(result);
  const history = useHistory();
  const windowSize = useWindowSize();
  const isMobileSize = !!(windowSize.width <= 768);

  const dummy = [
    {
      id: "philanthropy",
      swiped: false,
      direction: "",
      url: isMobileOnly ? VentureImg : VentureImgDesktop,
      title: "Venture Philanthropy",
      content: "What is Venture Philanthropy?",
      order: 4,
    },
    {
      id: "impact",
      swiped: false,
      direction: "",
      url: isMobileOnly ? ImpactImg : ImpactImgDesktop,
      title: "Impact investments",
      content: "What is Impact Investing?",
      order: 3,
    },
    {
      id: "sri",
      swiped: false,
      direction: "",
      url: isMobileOnly ? SriImg : SriImgDesktop,
      title: "SRI investments",
      content: "What is SRI?",
      order: 2,
    },
    {
      id: "esg",
      swiped: false,
      direction: "",
      url: isMobileOnly ? EsgImg : EsgImgDesktop,
      title: "ESG investments",
      content: "What is ESG?",
      order: 1,
    },
  ];

  //TODO handle this in by saving to store
  const [data, setData] = useState(dummy);
  // console.log('Experience', data, experience);

  const handleDataChange = (val: any) => {
    setData(val);
    onSave(FIELDS.EXPRIENCE, val);
    const changedVal = Array.prototype.find.call(val || [], item => item.swiped === true);
    trackButtonClick(tracking, userId, [
      { key: 'questionCode', value: `nw.esg.experience.${changedVal.id}` },
      { key: 'answerCode', value: changedVal.direction === 'right' ? 'yes' : 'no' },
    ]);
    // console.log('data', val, experience)
  };

  const isContinueDisabled = () => {
    return data[0].swiped; //enable when in the last  slide
  };
  const isHideSkip = () => {
    return data[0].swiped; //all are swiped
  };

  const onSkipClick = () => {
    const clonedData = [...data];
    let nextToSwipe = clonedData[clonedData.length - 1];
    for (let i = 0; i < clonedData.length; i++) {
      if (i > 0 && clonedData[i].swiped) {
        nextToSwipe = clonedData[i - 1];
        break;
      }
    }

    if (nextToSwipe) {
      nextToSwipe.swiped = true;
      nextToSwipe.direction = "skipped";
      setData(clonedData);
      onSave(FIELDS.EXPRIENCE, clonedData);
      trackButtonClick(tracking, userId, [
        { key: 'questionCode', value: `nw.esg.experience.${nextToSwipe.id}` },
        { key: 'skipped', value: 'true' },
      ]);
    }
  };

  const onContinue = () => {
    // onGetScore();
    onSave(
      FIELDS.GROUPED_SUPPORTS,
      utils.getSupportsByID(environment, social, governance)
    );
    history.push(PATH.HIGH_RANKING);
  };

  const renderToolTips = useMemo(() => {
    switch (currentId) {
      case "esg":
        return {
          title: "What is ESG?",
          answer:
            "ESG refers to environmental, social, and governance issues that investors with sustainability concerns consider when making investment decisions.",
          video: <iframe width={isMobileSize ? '100%' : '450'} height="244" frameBorder="0" src="https://www.youtube.com/embed/Uvi2S2Fi9Ig" title="What is ESG?" allowFullScreen></iframe>
          // video: <ReactPlayer width={isMobileSize ? '100%' : '450px'} style={{ height: '244px' }} url='https://www.youtube.com/watch?v=Uvi2S2Fi9Ig' />
        };
      case "sri":
        return {
          title: "What is SRI?",
          answer:
            "Socially Responsible Investment is used in the names of numerous offers and labels. It is an investment which aims to <strong>reconcile economic performance with ESG impact</strong> by <strong>funding companies and public institutions</strong> that contribute to sustainable development regardless of their business sector. <strong>SRI contributes to a responsible economy.</strong>",
        };
      case "impact":
        return {
          title: "What is Impact Investing?",
          answer:
            "Impact investing means investing in companies to pursue a measurable, positive environmental or social impact, coupled with a financial return.",
          video: <iframe width={isMobileSize ? '100%' : '450'} height="244" frameBorder="0" src="https://www.youtube.com/embed/FowZtFRv5gU" title="What is Impact Investing?" allowFullScreen></iframe>
        };
      case "philanthropy":
        return {
          title: "What is Venture Philanthropy?",
          answer:
            "Venture philanthropy is a type of impact investment that takes concepts and techniques from venture capital finance and business management and applies them to achieving philanthropic goals.",
        };
      default:
        return null;
    }
  }, [currentId]);

  const onHandleTooltipClick = (id: string) => {
    setCurrentId(id);
    setShowPopup(true);
  };

  return (
    <WithBottomButton
      hideSkip={isHideSkip()}
      description={
        !data[0].swiped
          ? "Have you ever invested in the following?"
          : "This is what your sustainable investment experience looks like."
      }
      onButtonClick={() => onContinue()}
      // skipValue="Don't know"
      onSkipClick={onSkipClick}
      disable={!isContinueDisabled()}
    >
      {!data[0].swiped && (
        <SwipeCard
          cards={data}
          isMobile={isMobileSize}
          onSwipe={handleDataChange}
          onClick={(id: string) => onHandleTooltipClick(id)}
        />
      )}

      {data[0].swiped && (
        <SwipeSummary
          data={experience.sort((a: any, b: any) => a.order - b.order)}
        />
      )}

      <EsgModal
        showModal={showPopup}
        title={renderToolTips?.title}
        onClose={() => setShowPopup(false)}
        onConfirm={() => setShowPopup(false)}
      >
        {/* <p>{renderToolTips?.answer}</p> */}
        <p dangerouslySetInnerHTML={{ __html: `${renderToolTips?.answer}` }} />
        <div style={{ textAlign: 'center', paddingTop: '16px' }}>
          {renderToolTips?.video && renderToolTips?.video}
        </div>
      </EsgModal>
    </WithBottomButton>
  );
};

const mapStateToProps = ({ investmentState, rootState }) => ({
  experience: investmentState.experience,
  environment: investmentState.environment,
  social: investmentState.social,
  governance: investmentState.governance,
  userId: rootState.userId,
});

const mapDispatchToProps = {
  onSave: storeAction.updateInvestmentData,
  // onGetScore: storeAction.getUserScore,
};

export default track()(connect(
  mapStateToProps,
  mapDispatchToProps
)(defaultLayout(Experience)));
