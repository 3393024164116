import React, { useEffect, useCallback } from 'react';
import utils from 'shared/helpers/utils';
import connect from 'shared/context/connect';
import { useHistory } from "react-router-dom";
import { isMobile } from 'react-device-detect';
// import { isMobileOnly, isBrowser} from 'react-device-detect';
import { Charts } from 'components';
import { defaultLayout } from 'layouts';
import ESGInfo from './ESGInfo/ESGInfo';
import storeAction from 'shared/context/actions';
import { PATH } from "shared/constants/constants";
import WithBottomButton from "components/Layout/WithBottomButton";
import { useWindowSize } from "shared/customHooks";
import track from 'react-tracking';
import { trackButtonClick } from 'shared/lib/tracking.utils';
import './Score.scss';

const FUND_LIBRARY_URL = process.env.REACT_APP_FUND_LIBRARY;
export interface ScoreProps {
  // onGetScore?: any,
  score?: number,
  scoreDesc?: string,
  token?: string,
  groupedSupports?: any,
  esgElement?: string,
  // name?: string,
  onRestart?: any,
  exclusions?: any,
  userId: string,
  tracking: Function,
}
const Score: React.FC<ScoreProps> = ({
  // onGetScore = () => {}, 
  score,
  token,
  groupedSupports = [],
  scoreDesc,
  esgElement,
  onRestart = () => {},
  exclusions = [],
  tracking = () => {},
  userId,
  // name = 'AIR'
}) => {
  // const isDesktop = !isMobileOnly && isBrowser;
  const windowSize = useWindowSize();
  const showSponsor = !!(windowSize.width >= 1190);
  const showSponsorOnMobile = !!(windowSize.width <= 980);
  const history = useHistory();
  // const exclusionList = exclusions && exclusions.split(',');
  
  // const fetchScore = useCallback(() => {
  //   if (groupedSupports) { onGetScore() }
  // }, [groupedSupports]);

  // useEffect(() => {
  //   // if (!token) {
  //   //   history.push(PATH.INDEX);
  //   // }
  //   if (score === 0) {
  //     fetchScore(); 
  //   }
  // }, [fetchScore]);

  return (
    <WithBottomButton
      skipValue="Start over"
      onSkipClick={() => {
        trackButtonClick(tracking, userId, [
          { key: 'startOver', value: 'true' }
        ]);
        onRestart();
        history.push(PATH.STYLE_PICKER);
      }}
      buttonValue="Explore sustainable investing"
      onButtonClick={() => {
        trackButtonClick(tracking, userId, [
          { key: 'firstExploreSustainableInvesting', value: 'true' }
        ]);
        history.push(PATH.PREFERENCES);
        /** @Todo add real params on the url (esg risk score, excludes, esg element?) */
        // window.open(`${FUND_LIBRARY_URL}?score=${score}&exclusions=${exclusions}`)
        // console.log('REDIRECT TO IMAS SCREENER')
      }}
      isButtonSwapOrder
    >
      {showSponsor && (
        <h6 className="sponsorv3">
          <span>Special thanks to</span>
          <span className="sponsorv3__logo"><img src={`${utils.baseUrl}/icons/amundiAM_logo.png`} alt="What is ESG?" width={120} height="auto" style={{marginTop: '5px' }} /></span>
        </h6>
      )}

      {showSponsorOnMobile && (
        <h6 className="sponsorv3">
          <span>Special thanks to</span>
          <span className="sponsorv3__logo"><img src={`${utils.baseUrl}/icons/Amundi_logo.svg`} alt="What is ESG?" width={100} height="auto" /></span>
        </h6>
      )}

      <div className="score">
        <div className="score__chart">
          <Charts label={"ESG score"} value={score} strokewidth={isMobile ? 16 : 24} size={isMobile ? 116 : 170} />
        </div>
        <div className="score__detail">
          <section>
            <h5>Your ESG element: {esgElement}</h5>
            <p>{scoreDesc}</p>
          </section>
        </div>
      </div>
      <ESGInfo />

    </WithBottomButton>
  );
};

const mapStateToProps = ({ investmentState, rootState }) => ({
  exclusions: investmentState.exclusions,
  score: investmentState.portfolio.score,
  esgElement: investmentState.portfolio.name,
  token: rootState.token,
  groupedSupports: investmentState.groupedSupports,
  scoreDesc: investmentState.portfolio.description,
  userId: rootState.userId
});

const mapDispatchToProps = ({
  onGetScore: storeAction.getUserScore,
  onRestart: storeAction.onClearSupports,
});


export default track()(connect(mapStateToProps, mapDispatchToProps)(defaultLayout(Score)))
// export default connect(mapStateToProps, mapDispatchToProps)(layoutWithBottomInfo(Score, ESGInfo));
