/* eslint-disable react/button-has-type */
import React, { memo } from 'react';
import './Button.scss';
import classnames from 'classnames';

interface Props {
  value: string | React.ReactNode;
  name?: string | undefined;
  type?: 'button' | 'submit' | 'reset' | undefined;
  isSecondary?: boolean;
  isTertiary?: boolean;
  isFullWidth?: boolean;
  isBig?: boolean;
  isOutline?: boolean;
  isDisabled?: boolean;
  onButtonClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
  onMouseDown?: any;
  onMouseUp?: any;
  isTextButton?: boolean;
  isWhiteButton?: boolean;
  extraStyle?: any;
}

const Button: React.FC<Props> = ({
  value,
  name = '',
  type = 'button',
  onButtonClick = () => {},
  onMouseUp = () => {},
  onMouseDown = () => {},
  isSecondary = false,
  isFullWidth = false,
  isOutline = false,
  isDisabled = false,
  isBig = false,
  isTertiary = false,
  isTextButton = false,
  isWhiteButton = false,
  extraStyle = {}
}): JSX.Element => {
  const classes = classnames({
    'nw-button': true,
    'nw-button--outline': isOutline,
    'nw-button--primary': !isSecondary,
    'nw-button--secondary': isSecondary,
    'nw-button--tertiary': isTertiary,
    'nw-button--isText': isTextButton,
    'nw-button--isWhite': isWhiteButton,
    'nw-button--fullwidth': isFullWidth,
    'nw-button--big': isBig,
    'nw-button--disabled': isDisabled,
  });
  return (
    <>
      <button
        className={classes}
        name={name}
        type={type}
        onClick={onButtonClick}
        disabled={isDisabled}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        style={extraStyle}
      >
        {value}
      </button>
    </>
  );
};

export default memo(Button);
