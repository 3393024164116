import React, { useMemo } from "react";
import { CheckMarkItemContainer } from "components";
import WithBottomButton from "components/Layout/WithBottomButton";
import connect from "shared/context/connect";
import { useHistory } from "react-router-dom";
import { PATH, FIELDS } from "shared/constants/constants";
import storeAction from "shared/context/actions";
import { defaultLayout } from "layouts";
import utils from "shared/helpers/utils";
import track from 'react-tracking';
import { trackButtonClick, buildAnswerCodeFromArr } from 'shared/lib/tracking.utils';

interface Props {
  onSave?: any;
  social?: string;
  data: Array<object>;
  userId: string;
  tracking: Function;
}
const Social: React.FC<Props> = ({ onSave = () => {}, social, data, tracking = () => {}, userId }) => {
  // const [checkedItem, setCheckedItem] = useState(social);
  const history = useHistory();
  const { getStringAfterSpecialChar } = utils;

  // const handleItemChecked = (e: any) => {
  //   if (e.target.checked) {
  //     onSave(e.target.name, e.target.id);
  //   } else {
  //     onSave(e.target.name, null);
  //   }
  // };

  const handleItemChecked = (value: any, name: string) => {
    onSave(name, value);
  };

  const handleSkipped = () => {
    // onSave(FIELDS.SOCIAL, null);
    onSave(FIELDS.SOCIAL, []);
    trackButtonClick(tracking, userId, [
      { key: 'questionCode', value: data[0]['code'] },
      { key: 'skipped', value: 'true' },
    ]);
    history.push(PATH.GOVERNANCE);
  };

  const renderOptions = useMemo(() => {
    const mappedData = data.map((item: any) => ({
      id: item.code,
      title: item.name,
      label: item.desc,
      imgSrc: `social-${getStringAfterSpecialChar(item.code, ".")}.png`,
    }));
    return mappedData;
  }, [data]);

  // const data = [
  //   { id: 'humanRights', label: "Invest in equal pay for equal work, right to education, freedom of expression, etc.", title: "Human rights", imgSrc: "social-human-rights.png" },
  //   { id: 'gender', label: "Xxxxxxx xxxxxxx xxxx xx xxxxx xxxx xxxxxxx xxxx xx xxxxx.", title: 'Gender and diversity policies', imgSrc: 'social-gender.png' },
  //   { id: 'laborStandards', label: "Xxxxxxx xxxxxxx xxxx xx xxxxx xxxx xxxxxxx xxxx xx xxxxx.", title: 'Labor standards', imgSrc: 'social-labor.png' },
  //   { id: 'empEngagement', label: "Xxxxxxx xxxxxxx xxxx xx xxxxx xxxx xxxxxxx xxxx xx xxxxx.", title: 'Employee engagement', imgSrc: 'social-employee.png' },
  //   { id: 'customerSatisfaction', label: "Xxxxxxx xxxxxxx xxxx xx xxxxx xxxx xxxxxxx xxxx xx xxxxx.", title: 'Customer satisfaction', imgSrc: 'social-customer.png' },
  //   { id: 'communityRelations', label: "Encourage companies to donate to the local community or encourage employees to perform volunteer work.", title: "Community relations", imgSrc: "social-community.png" }
  // ];

  return (
    <WithBottomButton
      // title="Social"
      description="What SOCIAL causes do you care about the most?"
      onSkipClick={() => handleSkipped()}
      disable={!social.length}
      onButtonClick={() => {
        const answerCode = buildAnswerCodeFromArr(social);
        trackButtonClick(tracking, userId, [
          { key: 'questionCode', value: data[0]['code'] },
          { key: 'answerCode', value: answerCode },
        ]);
        history.push(PATH.GOVERNANCE);
      }}
    >
      <CheckMarkItemContainer
        name={FIELDS.SOCIAL}
        selected={social}
        items={renderOptions.slice(1)}
        type="checkbox"
        onSelect={handleItemChecked}
      />
    </WithBottomButton>
  );
};

const mapStateToProps = ({ investmentState, rootState }) => ({
  social: investmentState.social,
  data: rootState.masterData.EN.social,
  userId: rootState.userId
});

const mapDispatchToProps = {
  onSave: storeAction.updateInvestmentData,
};

export default track()(connect(
  mapStateToProps,
  mapDispatchToProps
)(defaultLayout(Social)));
