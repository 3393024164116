import { STORAGE_KEY } from 'shared/constants/constants';

export const idleTimer = (t, element, callback, expirationTime) => {
  const App = document.querySelector(element);

  t = setTimeout(callback, expirationTime);

  const resetTimer = () => {
    clearTimeout(t);
    localStorage.setItem(STORAGE_KEY.EXPIRATION_DATE, new Date().getTime());
    t = setTimeout(callback, expirationTime);
  };

  App.onmousemove = resetTimer; // catches mouse movements
  App.onmousedown = resetTimer; // catches mouse movements
  App.onclick = resetTimer; // catches mouse clicks
  App.onscroll = resetTimer; // catches scrolling
  App.onkeypress = resetTimer; // catches keyboard actions
  App.touches = resetTimer; // catches keyboard actions
};

export const clearIdleTimer = (t) => {
  clearTimeout(t);
};

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
