import React, { useContext, useState } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { GlobalContext } from "shared/context/store";
import { FormattedMessage } from "react-intl";
import storeAction from "shared/context/actions";
import {
  Test,
  Login,
  Otp,
  Intro,
  HomePage,
  StylePicker,
  Exclusions,
  Environment,
  Social,
  Governance,
  Score,
  Experience,
  HighRanking,
  Momentum,
  StreamingNews,
  Transition,
  Preferences,
} from "pages";
import { PATH } from "shared/constants/constants";
import utils from "shared/helpers/utils";
import PrivateRoute from "./PrivateRoute";

import ArrowLeft from "assets/images/arrow-left.svg";
import newsIcon from "assets/images/news-icon.svg";
import searchIcon from "assets/images/search-icon.svg";

interface Props {
  history: any;
}

const BackImg = () => <img src={ArrowLeft} alt="Back" />;

const NewsImg = () => <img src={newsIcon} alt="news" />;

const SearchImg = () => <img src={searchIcon} alt="search" />;

const Routes: React.FC<Props> = ({ history }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const {
    investmentState: { portfolio },
  } = state;

  const [showSearchFilter, setShowSearchFilter] = useState(false);
  const handleSearchFilter = () => {
    setShowSearchFilter(!showSearchFilter);
  };

  // console.log('history ', portfolio.name);
  return (
    <Switch>
      <Route path={PATH.INDEX} exact render={() => <Intro />} />

      <Route path={PATH.OTP} render={(props) => <Otp {...props} />} />

      <Route path={PATH.INTRO} render={() => <Intro />} />

      <Route
        path={PATH.TEST}
        render={(props) => <Test navTitle="Sample Page Title" {...props} />}
      />

      <Route
        path={PATH.STYLE_PICKER}
        render={(props) => (
          <StylePicker
            leftButtonValue={<BackImg />}
            onLeftButtonClick={() => {
              history.push(PATH.INTRO);
            }}
            isShowProgress
            navTitle="Return, values, impact"
            progress={10}
            {...props}
          />
        )}
      />

      <Route
        path={PATH.ENVIRONMENT}
        render={(props) => (
          <Environment
            leftButtonValue={<BackImg />}
            onLeftButtonClick={() => {
              history.goBack();
            }}
            isShowProgress
            navTitle="Environmental causes"
            progress={20}
            {...props}
          />
        )}
      />

      <Route
        path={PATH.SOCIAL}
        render={(props) => (
          <Social
            leftButtonValue={<BackImg />}
            onLeftButtonClick={() => {
              history.goBack();
            }}
            isShowProgress
            navTitle="Social causes"
            progress={30}
            {...props}
          />
        )}
      />

      <Route
        path={PATH.GOVERNANCE}
        render={(props) => (
          <Governance
            leftButtonValue={<BackImg />}
            onLeftButtonClick={() => {
              history.goBack();
            }}
            isShowProgress
            navTitle="Governance causes"
            progress={40}
            {...props}
          />
        )}
      />

      <Route
        path={PATH.EXPERIENCE}
        render={(props) => (
          <Experience
            leftButtonValue={<BackImg />}
            onLeftButtonClick={() => {
              history.goBack();
            }}
            isShowProgress
            navTitle="Your investment experience"
            progress={50}
            {...props}
          />
        )}
      />

      <Route
        path={PATH.HIGH_RANKING}
        render={(props) => (
          <HighRanking
            leftButtonValue={<BackImg />}
            onLeftButtonClick={() => {
              history.goBack();
            }}
            isShowProgress
            navTitle="Relative or absolute ESG score"
            progress={60}
            {...props}
          />
        )}
      />

      <Route
        path={PATH.MOMENTUM}
        render={(props) => (
          <Momentum
            leftButtonValue={<BackImg />}
            onLeftButtonClick={() => {
              history.goBack();
            }}
            isShowProgress
            navTitle="ESG ‘Momentum’"
            progress={70}
            {...props}
          />
        )}
      />

      <Route
        path={PATH.TRANSITION}
        render={() => (
          <Transition
            leftButtonValue={<BackImg />}
            onLeftButtonClick={() => {
              history.goBack();
            }}
          />
        )}
      />

      <Route
        path={PATH.EXCLUSIONS}
        render={(props) => (
          <Exclusions
            leftButtonValue={<BackImg />}
            onLeftButtonClick={() => {
              history.goBack();
            }}
            isShowProgress
            navTitle="Negative screening"
            progress={80}
            {...props}
          />
        )}
      />

      <Route
        path={PATH.SCORE}
        render={(props: any) => (
          <Score
            navTitle="ESG screener"
            leftButtonValue={<BackImg />}
            onLeftButtonClick={() => {
              // storeAction.onClearSupports()(dispatch);
              history.goBack();
            }}
            isShowProgress
            progress={90}
            {...props}
          />
        )}
      />

      <Route
        path={PATH.PREFERENCES}
        render={(props: any) => (
          <Preferences
            leftButtonValue={<BackImg />}
            onLeftButtonClick={() => {
              history.goBack();
            }}
            isShowProgress
            navTitle="ESG preferences"
            progress={100}
            {...props}
          />
        )}
      />

      <Route
        path={PATH.HOME}
        render={(props) => (
          <HomePage
            history={history}
            leftButtonValue={<BackImg />}
            onLeftButtonClick={() => {
              history.push(PATH.SCORE);
            }}
            navTitle="Investment Ideas"
            {...props}
            // rightButtonValue={<NewsImg />}
            // onRightButtonClick={()=>{history.push(PATH.STREAMING_NEWS)}}
          />
        )}
      />

      <Route
        path={PATH.STREAMING_NEWS}
        render={(props) => (
          /** streamingNews.title 流媒体新闻 */
          <StreamingNews
            history={history}
            leftButtonValue={<BackImg />}
            onLeftButtonClick={() => {
              history.goBack();
            }}
            navTitle={<FormattedMessage id="streamingNews.title" />}
            {...props}
            rightButtonValue={<SearchImg />}
            onRightButtonClick={handleSearchFilter}
            displaySearchFilter={showSearchFilter}
            onCloseSearchFilter={handleSearchFilter}
          />
        )}
      />
    </Switch>
  );
};

export default withRouter(Routes);
