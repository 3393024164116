import React, { useMemo, useEffect } from "react";
import connect from 'shared/context/connect';
import { useHistory } from "react-router-dom";
import { Button } from "components";
import Chips from "components/Chips";
import { PATH } from "shared/constants/constants";
import utils from 'shared/helpers/utils';
// import storeAction from 'shared/context/actions';
import track from 'react-tracking';
import { trackButtonClick } from 'shared/lib/tracking.utils';

import "./ESGInfo.scss";

interface Props {
  exclusions?: Array<string>,
  // groupedSupports?: Array<string>,
  // environment?: string,
  // social?: string,
  // governance?: string,
  // scoreDesc?: string,
  onGetProducts?: any,
  esgPreferences?: Array<any>,
  // onSave?: any,
  userId: string,
  tracking: Function,
  score: any,
}
const ESGInfo: React.FC<Props> = ({
  exclusions,
  esgPreferences = [],
  tracking = () => {},
  userId,
  score
  // esgPreferences = [{
  //   name: 'Environment',
  //   status: 'Passionate',
  //   desc: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint officia consequat.'
  // }, {
  //   name: 'Social',
  //   status: 'Supportive',
  //   desc: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint officia consequat.'
  // }, {
  //   name: 'Governance',
  //   status: 'Indifferent',
  //   desc: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint officia consequat.'
  // }]
}) => {
  const history = useHistory();

  const esgDesc = {
    Evangelist: 'You are ready to be the face and voice of such causes, and why there are so critical; investments should definitely make an impact!',
    Passionate: 'You care a lot and feel strongly about some causes, and when doable - you are ready to align your investments with them.',
    Advocate: 'You publicly supports one or more causes, and occasionally you might allocate part of your investments into this theme.',
    Supportive: 'You encourages people and initiatives, and you might even invest some of your money into the best performers.',
    Indifferent: 'You have no particular interest on those topics, and you probably prefer to focus your attention on other priorities.'
  }
  const esgType = {
    E: 'Environment',
    S: 'Social',
    G: 'Governance'
  }
  const renderEsgPreferences = useMemo(() => {
    return esgPreferences.map(item => ({
      name: esgType[item.type],
      status: item.esgProfile,
      desc: `${esgDesc[item.esgProfile]} (${item.totalScore})`
    }))
  }, [esgPreferences])
  
  const getScoreByType = (preferences, type) => {
    const item = preferences.find(item => item.type === type);
    return item?.totalScore;
  }

  useEffect(() => {
    trackButtonClick(tracking, userId, [
      { key: 'esgScore', value: score },
      { key: 'sScore', value: getScoreByType(esgPreferences, 'S') },
      { key: 'eScore', value: getScoreByType(esgPreferences, 'E') },
      { key: 'gScore', value: getScoreByType(esgPreferences, 'G') },
    ]);
  }, [esgPreferences])
  
  const onContinue = () => {
    // onGetProducts();
    // onSave(FIELDS.GROUPED_SUPPORTS, utils.getSupportsByID(environment, social, governance));
    history.push(PATH.HOME);
  }

  // We replace the numbers in brackets with an empty string
  function cleanItemDescription(itemDescription) {
    // pattern to remove numbers in brackets
    // at the end of item description string
    const bracketsPattern = /[(]-?[0-9]+[)]$/g;

    return itemDescription.replace(bracketsPattern, "")
  }

  return (
    <div className="esg-info">
      {/* <h3 className="esg-info__element">Your ESG element is AIR.</h3> */}
      <article className="esg-info__desc">
        <h6>Your ESG preferences</h6>
        <ul className="esg-preferences">
          {esgPreferences && renderEsgPreferences && renderEsgPreferences.map((item, idx) => (
            <li className="esg-preferences__item" key={`esgPref-${idx}`}>
              <div className="esg-preferences__item__stat">
                <strong>{item.name}</strong>
                <small>{item.status}</small>
              </div>
              <article>
                <small>{cleanItemDescription(item.desc)}</small>
              </article>
            </li>
          ))}
        </ul>
        {/* {scoreDesc} */}
      </article>
      
      {/* utils.getSupports(environment, social, governance) */}
      {/* <Chips isWithTitle isPrimary title='You want to support' data={groupedSupports.map(item => utils.temporaryParseId[item])}/> */}
      {utils.getExclusions(exclusions).length ? <Chips isWithTitle title='You want to exclude' data={utils.getExclusions(exclusions)}/> : null}
      {/* <span className="esg-info__spacer" />
      <Button isBig value='See investment ideas' onButtonClick={onContinue} />
      <Button isBig isTertiary isOutline value='Start over' /> */}
    </div>
  );
};

const mapStateToProps = ({ investmentState, rootState }) => ({
  exclusions: investmentState.exclusions,
  // environment: investmentState.environment,
  // social: investmentState.social,
  // governance: investmentState.governance,
  groupedSupports: investmentState.groupedSupports,
  scoreDesc: investmentState.portfolio.description,
  score: investmentState.portfolio.score,
  esgPreferences: investmentState.portfolio.esgRankings,
  userId: rootState.userId
});

const mapDispatchToProps = ({
  // onGetProducts: storeAction.getProducts,
  // onSave: storeAction.updateInvestmentData,
});

export default track()(connect(mapStateToProps, mapDispatchToProps)(ESGInfo));
