import React, { memo, useState, useCallback, useRef, useEffect } from "react";
// import TinderCard from 'react-tinder-card'
import { Swipeable } from "react-deck-swiper";
import "./SwipeCard.scss";
import SwipeButtons from './SwipeButtons';
import RightCheckClean from "assets/images/RightCheckClear.png";
import LeftCheckClean from "assets/images/LeftCheckClear.png";

// import LeftCheck from "assets/images/LeftCheck.png";
// import RightCheck from "assets/images/RightCheck.png";

// import RightRect from "assets/images/RightRect.png";
// import LeftRect from "assets/images/LeftRect.png";
// import RightRectCheck from "assets/images/RightRectCheck.png";
// import LeftRectCheck from "assets/images/LeftRectCheck.png";
import RightRectCheck from "assets/images/icon-yes.svg";
import LeftRectCheck from "assets/images/icon-no.svg";

type Props = {
  cards: Array<any>;
  onSwipe: (data: any) => void;
  onClick?: any;
  isMobile: boolean;
};

const RIGHT = "right";
const LEFT = "left";

const SwipeCard: React.FC<Props> = ({ cards = [], onSwipe = () => {}, onClick = () => {}, isMobile }) => {
  const [currentDirection, setCurrentDirection] = useState("");
  const [hideGuide, setHideGuide] = useState(false);
  const [currentOpacity, setCurrentOpacity] = useState(1);
  const [showGuide, setShowGuide] = useState(false);
  const [data, setData] = useState(cards);

  useEffect(() => {
    if (isMobile) {
      setShowGuide(true);
      setTimeout(() => {
        // console.log('show in 3 sec');
        setShowGuide(false);
      }, 5500);
    }
    // return () => {
    //   setShowGuide(false);
    // }
  }, [showGuide, isMobile])

  const handleOnSwipe = useCallback(
    (swipeDirection: any, id: string) => {
      const tmpData = [...data];
      const updateItem = tmpData.find((p) => p.id === id);
      setCurrentOpacity(0);
      setHideGuide(false);
      if (updateItem) {
        updateItem.swiped = true;
        updateItem.direction = swipeDirection;
        setHideGuide(false);
      }

      setData(tmpData);
      onSwipe(tmpData);
    },
    [setData, data, setCurrentOpacity]
  );

  const handleHideGuide = () => {
    if (!hideGuide) {
      setHideGuide(true);
    }
  };

  const onSwipeStart = useCallback(
    (direction: string) => {
      setCurrentOpacity(0.4);

      if (
        (direction === RIGHT && currentDirection !== RIGHT) ||
        (direction === LEFT && currentDirection !== LEFT)
      ) {
        setCurrentDirection(direction);
      } else {
        setCurrentDirection("");
      }
    },
    [setCurrentDirection]
  );

  const onOpacityChange = (opacity: number) => {
    // console.log('opacity opacity opacity ', opacity,1 - opacity + 0.3);
    setCurrentOpacity(()=>{
      let op = 1 - opacity + 0.2;
      if(op >=1){
        return 1;
      }
      return op;
    });
  }

  // const getCheckImage = () => {
  //   if (currentDirection !== "") {
  //     if (currentDirection === RIGHT) {
  //       return <img className="check-img" src={RightCheck} />;
  //     }
  //     return <img className="check-img" src={LeftCheck} />;
  //   }
  //   return null;
  // };

  const getCheckImage = useCallback(() => {
    if (currentDirection !== "") {
      if (currentDirection === RIGHT) {
        return <img className="check-img" src={RightCheckClean} style={{ opacity: currentOpacity }} />
      }
      return <img className="check-img" src={LeftCheckClean} style={{ opacity: currentOpacity }} />

    }
    return null;
  },[currentOpacity]);

  return (
    <div className="card-container" 
    // style={{backgroundImage, borderRadius: 20 }}
    >
      {getCheckImage()}
      {data.map((p, i) => (
        <CardOption
          direction={p.direction}
          onSwipeStart={onSwipeStart}
          onFirstSwipeStart={handleHideGuide}
          onOpacityChange={onOpacityChange}
          onMouseUp={()=>setCurrentOpacity(0)}
          url={p.url}
          key={p.id}
          title={p.title}
          content={p.content}
          swiped={p.swiped}
          id={p.id}
          handleOnSwipe={handleOnSwipe}
          onClick={onClick}
          pos={i + 1} // use this for zindex
        />
      ))}
      {/* {!hideGuide && data.map(p => (
        <div className="card-guide" key={`tip-${p.id}`}>
          <img className="card-right-check" src={RightRectCheck} />
          <img className="card-left-check" src={LeftRectCheck} />
        </div>
      ))} */}
      {isMobile && showGuide && (
        <div className="card-guide">
          <img className="card-right-check" src={RightRectCheck} />
          <img className="card-left-check" src={LeftRectCheck} />
        </div>
      )}
    </div>
  );
};

type ICardProps = {
  swiped?: boolean; //TODO set type for this
  pos?: number;
  id?: string;
  handleOnSwipe?: any;
  onSwipeStart: any;
  onOpacityChange?: any;
  onFirstSwipeStart: any;
  onMouseUp?: any;
  url: any;
  direction?: string;
  title?: string;
  content?: string;
  onClick?: any;
};
const CardOption: React.FC<ICardProps> = memo(
  ({
    swiped = false,
    pos = 1,
    id,
    handleOnSwipe = () => {},
    onSwipeStart = () => {},
    onOpacityChange = () =>{},
    onFirstSwipeStart = () => {},
    onMouseUp= () => {},
    direction = "",
    title = "",
    content = "",
    url = null,
    onClick = () => {}
  }) => {
    const [middle, setMiddle] = useState(0);
    let mouseDown = useRef(false);
    const [showOutline, setShowOutline] = useState('outline');

    // const [direction, setDirection] = useState("");
    const changeMiddle = (e: any) => {
      setMiddle(e.target.getBoundingClientRect().left);
    };

    const handleMouseDown = (e:any) => {
      // if(middle){
        setMiddle(e.target.getBoundingClientRect().left);
      // }
      mouseDown.current = true;
    };

    const handleMouseUp = () => {
      mouseDown.current = false;
      onSwipeStart("");
      onMouseUp();
      setShowOutline('outline');
    };

    const handleMouseMove = (e: any) => {
      if (mouseDown.current) {
        onFirstSwipeStart();
        const pos = e.target.getBoundingClientRect().left;

        if (pos > middle) {
          onSwipeStart(RIGHT);
          setShowOutline('');
        } else if (pos < middle) {
          onSwipeStart(LEFT);
          setShowOutline('');
        } else {
          onSwipeStart("");
          setShowOutline('outline');
        }
      }
    };

    /** Yes/ No button on desktop */
    const renderButtons = ({
      right,
      left,
    }) => (
      <SwipeButtons
        right={right}
        left={left}
      />
    );

    const checkMarkuUrl = direction === RIGHT ? RightCheckClean : direction === LEFT ? LeftCheckClean : null;
    return (
      <div
        className={`card-wrapper ${swiped ? "card-wrapper--disapear" : ""}`}
        style={{ zIndex: pos }}
      >
        {swiped && checkMarkuUrl && <img className="card-wrapper-img" src={checkMarkuUrl} />}
        {!swiped && (
          <Swipeable
            onSwipe={(d) => handleOnSwipe(d, id)}
            renderButtons={renderButtons}
            fadeThreshold={30}
            swipeThreshold={120}
            // onBeforeSwipe={(cancelSwipe,direction)=>start(onSwipeStart,cancelSwipe,direction)}
            onOpacityChange={(opacity)=>{onOpacityChange(opacity)}}
          >
            <div
              className="card-img"
              onMouseEnter={changeMiddle}
              onMouseMove={handleMouseMove}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseOut={() => onSwipeStart("")}

              // for mobile
              onTouchMove={handleMouseMove}
              onTouchStart={handleMouseDown}
              onTouchEnd={handleMouseUp}

              style={{ backgroundImage: "url(" + url + ")" }}
            ></div>
          </Swipeable>
        )}
        {!swiped && (
          <div className="card-text">
            {/* <div className="card-text__title">{title}</div> */}
            <div className={`card-text__content ${showOutline}`} onClick={() => onClick(id)}>{content}</div>
          </div>
        )}
      </div>
    );
  }
);

export default memo(SwipeCard);
