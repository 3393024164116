import React, { useContext } from 'react';
import { GlobalContext } from 'shared/context/store';

const connect = (mapStateToProps: any, mapDispatchTopProps: any) => (
  WrappedComponent: any
) => (props: any) => {
  const { state, dispatch } = useContext(GlobalContext);
  const mappedState = mapStateToProps(state);
  const mappedDispatch: any = {};
  for (let i = 0; i < Object.keys(mapDispatchTopProps).length; i += 1) {
    const idxItem = Object.keys(mapDispatchTopProps)[i];
    mappedDispatch[idxItem] = (...args: any) =>
      mapDispatchTopProps[idxItem](...args)(dispatch, state);
  }

  const rest = {
    ...props,
    ...mappedState,
    ...mappedDispatch,
  };
  return <WrappedComponent {...rest} />;
};

export default connect;
