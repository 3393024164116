import React, { memo, useRef } from 'react';
import classNames from 'classnames';
import CheckMark from 'components/CheckMark/CheckMark';
import './CheckMarkImage.scss';

import utils from 'shared/helpers/utils';

interface Props {
  label?: string;
  title?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  name?: string,
  value?: string;
  id?:any;
  imgSrc?: string,
  type?: 'radio' | 'checkbox',
  onCheckMarkChange?:
    | ((event: React.ChangeEvent<HTMLInputElement>) => void)
    | undefined;
}

const CheckMarkImage: React.FC<Props> = ({
  label = '',
  title = '',
  isChecked = false,
  isDisabled = false,
  onCheckMarkChange = () => {},
  id,
  imgSrc,
  name = '',
  type
}): JSX.Element => {
  const classes = classNames({
    'nw-checkmark-image': true,
    'nw-checkmark-image--uncheck': !isChecked,

    // // 'nw-checkmark--has-label': label,
    'nw-checkmark-image--disabled': isDisabled,
    // 'nw-checkmark--checked': isChecked,

    // 'nw-checkmark--has-container': hasContainer,
  });
  interface RefObject {
    click: () => void
  }
  const ref = useRef<RefObject>(null);

  const handleClick =() =>{
    if(ref.current){
      ref.current.click()
    }
  }

  const contentClasses = classNames({
    'nw-checkmark-image__content': true,
    'nw-checkmark-image__content--selected': isChecked,
  })

  const footerClasses = classNames({
    'nw-checkmark-image__content__footer': true,
    'nw-checkmark-image__content__footer--hidden': !isChecked,
  });
  return (
    <div
      className={classes}
      style={{
        backgroundImage: `url(${utils.baseUrl}/images/${imgSrc})`,
      }}
    onClick={handleClick}>
      <div className={'nw-checkmark-image-wrapper'}>
        <CheckMark type={type} ref={ref} id={id} name={name} isChecked={isChecked} onCheckMarkChange={onCheckMarkChange}/>
      </div>
      <div className={contentClasses}>
        <h3 className='nw-checkmark-image__content__header'>
          {title}
        </h3>
        <small className={footerClasses}>{label}</small>
      </div>
    </div>
  );
};

export default memo(CheckMarkImage);
