import { STORAGE_KEY } from 'shared/constants/constants';

/* eslint-disable no-undef */
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(STORAGE_KEY.ESG_STATE);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(STORAGE_KEY.ESG_STATE, serializedState);
  } catch {
    // ignore write errors
    console.log('Oops!');
  }
};

export const clearStorage = async (listItem: any[]) => {
  try {
    await listItem.map((item: string) => localStorage.removeItem(item));
  } catch (error) {
    console.log(error);
  }
};
