import React, { memo, useState, useEffect, Children } from "react";
import "./Tabs.scss";

import classNames from 'classnames';

type Props = {
  tabMenu?: any; //TODO set type for this
  children: JSX.Element[];
  tabActive?: number;
  onTabClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  isDefaultStyle?: boolean, 
};

const Tabs: React.FC<Props> = memo(
  ({ tabMenu = [], children, tabActive = 0, onTabClick = () => {}, isDefaultStyle = true }) => {
    const [listMenu, setListMenu] = useState(tabMenu);
    const [currentTab, setCurrentTab] = useState(tabActive);

    useEffect(() => {
      setListMenu(tabMenu);
    }, [tabMenu]);

    const handleClick = (item: any, idx: number) => {
      setCurrentTab(idx);
      onTabClick(item);
    };

    const classes = classNames({
      'tabs': true,
      'tabs--2ndStyle': !isDefaultStyle,
    })

    return (
      <div className={classes}>
        <ul
          className={`tabs__menu ${
            listMenu && listMenu.length === 2 ? "twoTab" : ""
          }`}
        >
          {listMenu && listMenu.length > 0
            ? listMenu.map((item: any, idx: number) => {
                return (
                  <li
                    key={`tabs${idx}`}
                    className={`${currentTab === idx ? "active" : ""}`}
                  >
                    <span onClick={() => handleClick(item.title, idx)}>
                      {item.title}
                    </span>
                  </li>
                );
              })
            : null}
        </ul>
        <div className="tabs__content">
          {/* {Children.map(children, (child, i) => (
            <div className={`${currentTab === i ? 'active' : ''}`}>{child}</div>
          ))} */}
          {/* {tabMenu[currentTab] && tabMenu[currentTab].child} */}
          {Children.map(children, (child, i) => (
            <div
              className={`tabs__content--normal ${
                currentTab === i ? "tabs__content--active" : "tabs__content--inactive"
              }`}
            >
              {child}
            </div>
          ))}
        </div>
      </div>
    );
  }
);

export default Tabs;
