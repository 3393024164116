import React, { memo, useState } from "react";
// import { FormattedMessage } from 'react-intl';
import Slider from 'rc-slider';
import _ from 'lodash';
import IconButton from 'components/Button/IconButton/IconButton';
import './RiskSlider.scss';

// import 'rc-slider/assets/index.css';
// import InfoIcon from 'assets/images/icon-info.svg';
// import 'rc-tooltip/assets/bootstrap.css';

export interface IRiskSliderProps {
    defaultValue?: any,
    // results?: number,
    onChange?: any,
    // tooltips?: string
}

// function log(value) {
//     console.log(value); //eslint-disable-line
// }

const colorVariables = {
    handleBg: '#27ca47',
    handleBorder: '#f6f6f6',
    trackBg: '#27ca47',
    trackBorder: '#f6f6f6',
    dotBg: '#e5e5e5',
    dotBorder: '#27ca47',
    railBg: '#e5e5e5',
    railBorder: '#e5e5e5',
}

const RiskSlider: React.FC<IRiskSliderProps> = ({
    defaultValue = [1, 4], onChange = () => {}, 
    // tooltips,
    // results,
}) => {
    const { Range } = Slider;
    const [trackColor, setTrackColor] = useState('linear-gradient(to right, #D3D141, #F1BD33, #E5752B)');

    const marks = {
        1: 'Negligible',
        // 2: '2',
        // 3: 'Medium',
        // 4: '4',
        5: 'Severe',
    }
    const onHandleChange = (e) => {
        onChange(e);
        handleTrackGradientColor(e);
    }

    const handleTrackGradientColor = (val) => {
        if (_.isEqual(val.sort(), [1,2].sort())) {
            setTrackColor('linear-gradient(to right, #B0E551, #D3D141');
        } else if (_.isEqual(val.sort(), [1,3].sort())) {
            setTrackColor('linear-gradient(to right, #B0E551, #D3D141, #F1BD33)');
        } else if (_.isEqual(val.sort(), [2,3].sort())) {
            setTrackColor('linear-gradient(to right, #D3D141, #F1BD33)');
        } else if (_.isEqual(val.sort(), [1,4].sort())) {
            setTrackColor('linear-gradient(to right, #B0E551, #D3D141, #F1BD33, #E5752B)');
        } else if (_.isEqual(val.sort(), [2,5].sort())) {
            setTrackColor('linear-gradient(to right, #D3D141, #F1BD33, #E5752B, #D92C23)');
        } else if (_.isEqual(val.sort(), [3,4].sort())) {
            setTrackColor('linear-gradient(to right, #F1BD33, #E5752B)');
        } else if (_.isEqual(val.sort(), [3,5].sort())) {
            setTrackColor('linear-gradient(to right, #F1BD33, #E5752B, #D92C23)');
        } else if (_.isEqual(val.sort(), [2,4].sort())) {
            setTrackColor('linear-gradient(to right, #D3D141, #F1BD33, #E5752B)');
        } else if (_.isEqual(val.sort(), [1,5].sort())) {
            setTrackColor('linear-gradient(to right, #B0E551, #D3D141, #F1BD33, #E5752B, #D92C23)');
        } else {
            setTrackColor('linear-gradient(to right, #E5752B, #D92C23)');
        }
    }

    const renderTooltips = () => (
        <div className="carbon-tooltips">
            {/* <h6>What is carbon risk</h6>
            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint officia consequat.</p> */}
            <h6>How Morningstar defines carbon score</h6>
            <p>Carbon Risk Metrics provide investors a high level view on how well their portfolio holdings are positioned given the transition to a low carbon economy using Sustainalytics’ evaluation of the company’s activities and products including its carbon intensity, fossil fuel involvement, stranded assets exposure, mitigation strategies, and green product solutions. A portfolio’s Carbon Risk Score is the asset-weighted sum of the carbon risk scores of its holdings, averaged over the trailing 12 months. The portfolio Carbon Risk Score is displayed as a number between 0 and 100 (a lower score is better).</p>
            <ol>
                <li><span><b>1</b> Negligible</span><small>0</small></li>
                <li><span><b>2</b> Low</span><small>0.1-9.99</small></li>
                <li><span><b>3</b> Medium</span><small>10-34.99</small></li>
                <li><span><b>4</b> High</span><small>35-59.99</small></li>
                <li><span><b>5</b> Severe</span><small>60+</small></li>
            </ol>
        </div>
    )

    return (
        <section className="risk-slider">
            <h6 className="risk-slider__header">
                {/* <FormattedMessage id="risk.title" /> */}
                <span>Adjust carbon risk</span>

                {/* <img src={InfoIcon} width={20} height={20} alt="What is carbon risk?" /> */}
                <IconButton id={'carbonRisk'} popupcontent={renderTooltips()} />
            </h6>

            <div className="risk-slider__body">
                <Range
                    dots 
                    min={1} 
                    max={5}
                    step={1}
                    count={1}
                    defaultValue={defaultValue} 
                    onChange={e => onHandleChange(e)} 
                    allowCross={false}
                    pushable
                    marks={marks}
                    dotStyle={{
                        // borderColor: colorVariables.dotBorder,
                        // backgroundColor: colorVariables.dotBg,
                        width: '18px',
                        height: '18px',
                        top: -10,
                        marginLeft: '-10px'
                    }}
                    activeDotStyle={{
                        borderColor: colorVariables.trackBorder,
                        backgroundColor: colorVariables.trackBg,
                    }}
                    railStyle={{ backgroundColor: colorVariables.railBg, height: 5, top: 2 }}
                    trackStyle={[{
                        // backgroundColor: colorVariables.trackBg,
                        backgroundImage: `${trackColor}`,
                        top: 2
                    }]}
                    handleStyle={[{
                        borderColor: colorVariables.trackBorder,
                        // borderWidth: 3,
                        height: 28,
                        width: 28,
                        top: -6,
                        backgroundColor: colorVariables.handleBg,
                    }, {
                        borderColor: colorVariables.trackBorder,
                        // borderWidth: 3,
                        height: 28,
                        width: 28,
                        top: -6,
                        backgroundColor: colorVariables.handleBg,
                    }]}

                    // onChange={onChange}
                />
            </div>
            
            {/* <footer className="risk-slider__results">
                <FormattedMessage id="risk.result" values={{ number: results }} />
            </footer> */}
        </section>
    );
}

export default memo(RiskSlider);