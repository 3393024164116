import React, { memo } from 'react';
import './InputWithButton.scss';
import classnames from 'classnames';
import { Button } from 'components';

interface Props {
    id?: string,
    label?: string,
    value?: string | number,
    type?: string,
    name?: string,
    placeholder?: string,
    onInputChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined,
    buttonValue?: string | React.ReactNode,
    onButtonClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined,
    isValid?: boolean,
    errorMessage?: string,
}

const InputWithButton: React.FC<Props> = ({
  id = '', label = '', placeholder = 'Enter text here', value = '', name = '', type = 'text', onInputChange = () => {}, buttonValue = 'Submit', onButtonClick = () => {}, isValid = true, errorMessage
}): JSX.Element => {
  const classes = classnames({
    'esg-input-with-button': true,
  });
  return (
    <div className={classes}>
      {label && <span>{label}</span>}

      <div className="esg-input-with-button__container">
        <input
            id={id}
            name={name}
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={onInputChange}
        />

        <Button
            // isPrimary
            onButtonClick={onButtonClick}
            value={buttonValue}
        />
      </div>

      {!isValid && <span className="notification notification--error">{errorMessage}</span>}
    </div>
  );
};

export default memo(InputWithButton);
