import React, { PureComponent, useState } from 'react';
import connect from 'shared/context/connect';
import storeAction from 'shared/context/actions';
import ReactLoading from 'react-loading';
import { 
    EsgProduct, 
    Button,
    RiskSlider,
    EsgModal,
    ButtonOption
  } from 'components';
import { layoutWithBottomInfo } from 'layouts';
import InfiniteScroll from 'react-infinite-scroll-component';

import utils from 'shared/helpers/utils';
import FilterContainer from 'components/Filter/FilterContainer';
import selectors from 'shared/context/selectors/index';
import { PATH, FIELDS, PAGE_SIZE } from 'shared/constants/constants';

import './HomePage.scss';

interface Props {
    token?: string,
    onGetProducts?: any,
    products?: any,
    total?: number,
    numOfRecords?: number,
    onNumOfRecordsChanged?: any,
    history?: any,
    onSaveProductsLoaded?: any,
    productsLoaded?: any,
    onOverwriteProductsLoaded?: any,
    onChangeFilter?: any,
    filteredProducts?: any,
    onUpdateUserInputFilters?: any,
    onSaveGroupSupports?: any,
    isProductLoading?: boolean,
}
interface State {
    productsData?: any,
    showExclusionModal?: boolean,
}
  
class HomePage extends PureComponent<Props, State>  {
    state = {
        productsData: [], // this.props.products.slice(1, PAGE_SIZE)
        // numberOfLoads: PAGE_SIZE,
    }
    componentDidMount() {
        if (!this.props.token) {
            this.props.history.push(PATH.INDEX);
        }
        if (!this.props.products.length) {
            this.props.onGetProducts();
        }
        this.fetchMoreData();
    }

    componentWillUnmount() {
        this.setState({ productsData: null });
        // console.log('state unmount ', this.state.productsData);
        // this.props.onOverwriteProductsLoaded([]);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { filteredProducts } = this.props;
        if (snapshot !== null && prevProps.filteredProducts !== null) {
            // console.log('previous props >>> ', prevProps.filteredProducts, 'Current FilteredProducts', snapshot);
            const modifyProductsData = [...filteredProducts.slice(0, PAGE_SIZE)];
            // return prevProps.filteredProducts
            // return
            this.setState({
                productsData: modifyProductsData
            })
        }
        
    }

    getSnapshotBeforeUpdate(prevProps) {
        if (prevProps.filteredProducts !== null && prevProps.filteredProducts !== this.props.filteredProducts) {
            return this.props.filteredProducts;
        }
        return null;
    }

    fetchMoreData = () => {
        const numberToIncrease = PAGE_SIZE;
        const newPageSize = this.state.productsData.length + numberToIncrease;
        // this.props.onSaveProductsLoaded();
        setTimeout(() => {
            // this.props.onSaveProductsLoaded();
            // setProductsData((prev) => [...prev, ...products.slice(prev.length + 1, newPageSize)]);
            this.setState((prev) => ({
                productsData: prev.productsData.concat(this.props.filteredProducts.slice(prev.productsData.length, newPageSize))
            }));

          }, 500);
    }

    onHandleRiskChange = (e) => {
        this.props.onChangeFilter(FIELDS.PRODUCT_RISK, e);
    }

    render() {
        const { filteredProducts, isProductLoading } = this.props;
        const { productsData } = this.state;
        // console.log('isProductLoading', isProductLoading)
        // console.log('RISKS HOMEPAGE', filteredProducts.length, filteredProducts);

        return (
            <section className="homepage">
                <RiskSlider results={filteredProducts.length} onChange={e => this.onHandleRiskChange(e)} />
                {/* {console.log('LOCAL STATE ', productsData, products)} */}
                <section className="homepage__results">
                    {/* <div className="homepage__results__container"> */}

                    {!isProductLoading 
                        ? <InfiniteScroll
                            dataLength={productsData.length} //This is important field to render the next data
                            next={this.fetchMoreData}
                            // refreshFunction={this.onRefreshData}
                            // pullDownToRefresh
                            hasMore={productsData.length <= filteredProducts.length} // 
                            // height={500}
                            loader={<Button extraStyle={{marginTop:20}} isWhiteButton isBig isFullWidth value="Load more..." />}
                            scrollableTarget="homepage__results"
                            pullDownToRefreshThreshold={10}
                            endMessage={
                                <p style={{ textAlign: 'center', marginTop: '20px' }}>
                                <b>Yay! You have seen it all</b>
                                </p>
                            }
                        >
                        {productsData && productsData.map((item, idx) => (
                            <EsgProduct key={`${item.isin}-${idx}`} {...item} />
                        ))}

                        {!productsData.length && (
                            <span>
                                {`Oops! Please lower the "Carbon level" filter to get result`}
                            </span>
                        )}

                        {productsData.length < filteredProducts.length  && <Button extraStyle={{marginTop:20}} isWhiteButton isBig isFullWidth onButtonClick={this.fetchMoreData} value={`(${(productsData.length)} of ${filteredProducts.length}) Load more...`} />}
                    </InfiniteScroll> : (
                        <div className="homepage__results__loader">
                            <ReactLoading height="32px" width="32px" color="#27ca47" type="spin" />
                        </div>
                    )}
                    {/* </div> */}
                </section>
            {/* <TabContentSample /> */}
            </section>
        )
    }
}


const FilterWrapper = (props) => {
    const { exclusions, onUpdateUserInputFilters, onSave, onGetProducts, groupedSupports, threshold, carbonRiskScore, onSaveGroupSupports, isCarbonDisabled } = props;
    const [showExclusionModal, setShowExclusionModal] = useState(false);
    const [showSupportModal, setShowSupportModal] = useState(false);

    const onHandleRemove = (key, val) => {
        // console.log('remove from the filter: ', key, val);
        onUpdateUserInputFilters(key, val);
    }

    const onHandleAdd = (e) => {
        const { name } = e.target;
        
        if (name === 'exclusions') {
            setShowExclusionModal(true);
        }

        if (name === 'supports') {
            setShowSupportModal(true);
        }
        console.log(name, showExclusionModal)
    }

    const onHandleClose = () => {
        setShowExclusionModal(false);
        setShowSupportModal(false);
    }

    const onHandleExclusionConfirm = () => {
        setShowExclusionModal(false);
        onGetProducts();
    }

    const onHandleSupportConfirm = () => {
        setShowSupportModal(false);
        // onGetProducts(); NO NEED TO GET NEW PRODUCTS BASED ON SUPPORT CHANGES
    }

    const handleExclusionCheckedItem = (value: string) => {
        // const selected = [...checkedItem];
        const selected = [...exclusions];
        const index = selected.indexOf(value);
        if(index  !== -1){
          selected.splice(index, 1);
        } else {
          selected.push(value);
        }
        // setCheckedItem(selected);
        onSave(FIELDS.EXCLUSIONS, selected);
    };

    const handleSupportCheckedItem = (value: string) => {
        // const selected = [...groupedSupports];
        // const index = selected.indexOf(value);
        // if(index  !== -1){
        //   selected.splice(index, 1);
        // } else {
        //   selected.push(value);
        // }
        // console.log('causes to support', selected);
        // onSave(FIELDS.GROUPED_SUPPORTS, selected);
        onSaveGroupSupports(value);
    }

    const populateFilters = () => {
      const items = [
        { id: 'supports', title: 'You want to support', tags: groupedSupports },
        { id: 'exclusions', title: 'You want to exclude', tags: exclusions }
      ];
      return items;
    }

    const onHandleSliderChange = (name, val) => {
        // try {
        //     onSave(name, val);
        //     onGetProducts();
        // } catch (err) {
        //     console.log(err)
        // }
        onSave(name, val)
    }
    return  (
      <>
        <FilterContainer 
          question='You want to support/exclude...'
          items={populateFilters()}
          onRemove={(key, val) => onHandleRemove(key, val)}
          onAdd={onHandleAdd}
          defaultValueThreshold={threshold}
          defaultValueCarbonLevel={carbonRiskScore}
          onChange={onHandleSliderChange}
          onAfterChange={() => onGetProducts()}

          switcherValue={!isCarbonDisabled}
          onSwitcherChange={({ target }) => {
            Promise.all([onSave(target.name, target.checked)]).then(() => onGetProducts());
          }}
        />
        
        {/* <FilterSlider
            name={FIELDS.THRESHOLD}
            title='threshold'
            percentage='%'
            preposition='below'
            minValue={0}
            maxValue={5}
            minLabel='0.0%'
            maxLabel='5.0%'
            step={0.1}
            defaultValue={threshold}
            onChange={onHandleSliderChange}
            onAfterChange={() => onGetProducts()}
        /> */}

        {/* <FilterSlider
            name={FIELDS.CARBON_RISK_SCORE}
            title='carbon level'
            percentage=''
            preposition='below'
            minValue={1}
            maxValue={50}
            minLabel='1'
            maxLabel='50'
            step={1}
            defaultValue={carbonRiskScore}
            onChange={onHandleSliderChange}
            onAfterChange={() => onGetProducts()}
            isDisabled={true}
        /> */}

        {/* Exclusion Popup Filter */}
        <EsgModal showModal={showExclusionModal} title="Investments to exclude" onClose={onHandleClose} onConfirm={onHandleExclusionConfirm}>
            <div className="exclusion-wrapper">
                {utils.exclusionOptions.map(p => (
                    <ButtonOption
                        key={p.id}
                        label={p.label}
                        isChecked={exclusions.indexOf(p.id) > -1}
                        onClick={() => handleExclusionCheckedItem(p.id)}
                        isOnWhiteBg
                        isSecondary
                    />
                ))}
            </div>
        </EsgModal>

        <EsgModal showModal={showSupportModal} title="Causes to Support" onClose={onHandleClose} onConfirm={onHandleSupportConfirm}>
            <div className="exclusion-wrapper">
                {utils.causesToSupportOptions.map(p => (
                    <ButtonOption
                        key={p.id}
                        label={p.label}
                        isChecked={groupedSupports.indexOf(p.id) > -1}
                        onClick={() => handleSupportCheckedItem(p.id)}
                        isOnWhiteBg
                        defaultButtonValue="Add"
                        selectedButtonValue="Added"
                    />
                ))}
            </div>
        </EsgModal>
      </>
    );
  }
  
  const mapStateToProps = ({ rootState, investmentState, productState }) => ({
    token: rootState.token,
    exclusions: investmentState.exclusions,
    // environment: investmentState.environment,
    // social: investmentState.social,
    // governance: investmentState.governance,
    groupedSupports: investmentState.groupedSupports,
    threshold: investmentState.threshold,
    carbonRiskScore: investmentState.carbonRiskScore,
    isCarbonDisabled: investmentState.isCarbonDisabled,
    
    products: productState.data,
    filteredProducts: selectors.modifiedProducts(productState.data),
    total: productState.paging.total,
    numOfRecords: productState.paging.numOfRecords,

    productsLoaded: productState.productsLoaded,
    isProductLoading: productState.isLoading,
  });
  
  const mapDispatchToProps = ({
    onGetProducts: storeAction.getProducts,
    onNumOfRecordsChanged: storeAction.changeTotalOfRecords,
    onSaveProductsLoaded: storeAction.onSaveProductsLoaded,
    onOverwriteProductsLoaded: storeAction.onProductsLoaded,
    onChangeFilter: storeAction.onChangeFilter,
    onUpdateUserInputFilters: storeAction.onUpdateUserInputFilters,
    onSave: storeAction.updateInvestmentData,
    onSaveGroupSupports: storeAction.updateGroupedSupports,
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(layoutWithBottomInfo(FilterWrapper, HomePage));