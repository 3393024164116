import React, { memo } from "react";
import EsgHeader from './EsgHeader';
import { Card, EsgPerformance } from 'components';
import utils from 'shared/helpers/utils';
// import SampleImage from 'assets/images/logo-eastspring.png';
import './EsgProduct.scss';

export interface IEsgProductProps {
    isin?: string,
    imgSrc?: string,
    title?: string,
    ratingNumber?: number,
    isLowCarbon?: boolean,
    performanceMenu?: Array<Object>,
    performance?: Array<Object>,
}

const EsgProduct: React.FC<IEsgProductProps> = ({
    isin = '',
    imgSrc = '',
    title = 'Eastspring Investments Asia Sust Bd C',
    ratingNumber = 4,
    isLowCarbon = false,
    performanceMenu = [{
            title: 'Since Inception'
        }, {
            title: '5y'
        }, {
            title: '3y'
        }, {
            title: '1y'
        }, {
            title: '3m'
    }],
    performance = [{
        id: 1,
        annualizedReturn: 6.9,
        benchmarkReturn: 3.7,
        latestNav: 1.42,
        dateNav: Date.now(),
    }, {
        id: 2,
        annualizedReturn: 6.5,
        benchmarkReturn: 3.5,
        latestNav: 1.42,
        dateNav: Date.now(),
    }, {
        id: 3,
        annualizedReturn: 6.3,
        benchmarkReturn: 3.3,
        latestNav: 1.42,
        dateNav: Date.now(),
    }, {
        id: 4,
        annualizedReturn: 6.2,
        benchmarkReturn: 3.1,
        latestNav: 1.42,
        dateNav: Date.now(),
    }, {
        id: 5,
        annualizedReturn: 6.1,
        benchmarkReturn: 3.1,
        latestNav: 1.42,
        dateNav: Date.now(),
    }]
}) => {
    const handleLogo = imgSrc ? imgSrc : `${utils.baseUrl}/images/nw-192.png`;
    return (
        <Card>
            <EsgHeader 
                ratingNumber={ratingNumber}
                // imgSrc={imgSrc}
                title={title}
                id={isin}
            />
            <EsgPerformance 
                id={isin}
                tabsMenu={performanceMenu}
                details={performance}
            />

            <section className="esg-product-footer">
                <img src={handleLogo} alt={title} />
                {isLowCarbon && <img src={`${utils.baseUrl}/images/logo-low-carbon.svg`} alt="low carbon" />}
            </section>
        </Card>
    );
}

export default memo(EsgProduct);