import React from "react";
import connect from 'shared/context/connect';
import storeAction from 'shared/context/actions';
import ButtonOption from "components/ButtonOption/ButtonOption";
import WithBottomButton from "components/Layout/WithBottomButton";
import { useHistory } from "react-router-dom";
import { PATH, FIELDS  } from "shared/constants/constants";
import './Exclusions.scss'
import { defaultLayout } from 'layouts';
import utils from 'shared/helpers/utils';
import track from 'react-tracking';
import { trackButtonClick, buildAnswerCodeFromArr } from 'shared/lib/tracking.utils';
interface Props {
  exclusions?: Array<String>,
  onSave?: any,
  onGetScore?: any,
  data: Array<object>,
  userId: string,
  tracking: Function,
}
const Exclusions: React.FC<Props> = ({
  exclusions = [], onSave = () => {},
  onGetScore = () => {}, 
  data, userId,
  tracking = () => {},
}) => {
  // const [checkedItem, setCheckedItem]: any[] = useState(exclusions);

  const handleCheckedItem = (value: string) => {
    // const selected = [...checkedItem];
    const selected = [...exclusions];
    const index = selected.indexOf(value);
    if(index  !== -1){
      selected.splice(index, 1);
    } else {
      selected.push(value);
    }
    // console.log('selected', selected);
    // setCheckedItem(selected);
    onSave(FIELDS.EXCLUSIONS, selected);
  };

  const history = useHistory();

  // const dummyData = [
  //   {id: 'palmOil', label: 'Palm oil'},
  //   {id: 'thermalCoal', label: 'Thermal coal'},
  //   {id: 'alcohol', label: 'Alcohol'},
  //   {id: 'gambling', label: 'Gambling'},
  //   {id: 'tobacco', label: 'Tobacco'},
  //   {id: 'controversialWeapons', label: 'Controvertial weapons'},
  //   {id: 'adultEntertainment', label: 'Adult entertainment'},
  //   // {id: 'childLabour', label: 'Child labour'}
  // ];

  return (
    <WithBottomButton
      hideSkip={!exclusions.length}
      description={"Which categories you want to exclude?"}
      skipValue="Reset"
      onSkipClick={() => {
        // setCheckedItem([]);
        onGetScore();
        onSave(FIELDS.EXCLUSIONS, []);
      }}
      // disable={!checkedItem.length}
      buttonValue="Generate my ESG profile"
      onButtonClick={() => {
        const answersString = utils.exclusionOptions.filter(item => exclusions.includes(item.id)).map((item) => item.tmpCode);
        const answerCode = buildAnswerCodeFromArr(answersString);
        trackButtonClick(tracking, userId, [
          { key: 'questionCode', value: data[0]['code'] },
          { key: 'answerCode', value: answerCode },
        ]);
        Promise.all([onGetScore()]).then(() => {
          history.push(PATH.SCORE);
        });
      }}
    >
      <div className={'exclusion-wrapper'}>
         {utils.exclusionOptions.map(p=> (
           <ButtonOption
            key={p.id}
            label={p.label}
            isChecked={exclusions.indexOf(p.id) > -1}
            onClick={() => handleCheckedItem(p.id)}
            isSecondary
          />
         ))}
      </div>
     
    </WithBottomButton>
  );
};

const mapStateToProps = ({ investmentState, rootState }) => ({
  exclusions: investmentState.exclusions,
  data: rootState.masterData.EN.exclusion,
  userId: rootState.userId,
});

const mapDispatchToProps = ({
  onSave: storeAction.updateInvestmentData,
  onGetScore: storeAction.getUserScore,
});

export default track()(connect(mapStateToProps, mapDispatchToProps)(defaultLayout(Exclusions)));
