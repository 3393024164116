import React, { useRef, useEffect, useCallback, memo } from 'react';
import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { Button, Tag } from 'components';

import './SearchFilter.scss';
import searchIcon from 'assets/images/search-icon.svg';
import closeIcon from 'assets/images/icon-close-grey.svg';

interface SearchFilterProps {
    id?: string,
    onClose?: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined,
    showSearchFilter?: boolean,
    placeholder?: string,
    onKeyDownSearchInput?: any,
    searchVal?: string,
    onHandleSearchVal?: any,
    onSeachValReset?: any,
    tagLength: number,
    clearBtnVal?: any,
    onHandleClearBtn?: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined,
    tags?: any,
    onNormalTagClick?: any,
    onHandleRemove: any,
  }

const SearchFilter: React.FC<SearchFilterProps> = ({
    id,
    onClose = () => {},
    showSearchFilter = false,
    placeholder = 'Search by Symbol, Company Name & Keyword',
    onKeyDownSearchInput = () => {},
    searchVal,
    onHandleSearchVal = () => {},
    onSeachValReset = () => {},
    tagLength,
    clearBtnVal = 'clear all',
    onHandleClearBtn,
    tags,
    onNormalTagClick = () => {},
    onHandleRemove = () => {},
}) => {

    const searchFilterRef = useRef(null);

    const handleClickOutside = useCallback((event) => {
        if (searchFilterRef.current && !searchFilterRef.current.contains(event.target)) {
            onClose();
        }
    },[onClose]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleClickOutside]);

  if (showSearchFilter) {
    return ReactDOM.createPortal(
        <div className='searchFilter-overlay'>
            <div className='searchFilter-overlay__container'>
                <section className='searchFilter-overlay__container__search' ref={searchFilterRef}>
                    <div className='searchFilter-overlay__container__search__header'>
                        <img className='searchFilter-overlay__container__search__header__searchIcon' src={searchIcon} alt="search" />
                        <input type="text" placeholder={placeholder} onKeyDown={onKeyDownSearchInput} value={searchVal} onChange={onHandleSearchVal} />
                        {searchVal ?
                            <img className='searchFilter-overlay__container__search__header__closeIcon' src={closeIcon} alt="close" onClick={onSeachValReset} /> : ""}
                    </div>
                    <section className='searchFilter-overlay__container__search__section'>
                        <div className='searchFilter-overlay__container__search__section__header'>
                            <small>
                                {tags.length <= 1 ?
                                    <FormattedMessage id="searchFilter.singularTitle" values={{ number: tagLength }}/>
                                    : <FormattedMessage id="searchFilter.pluralTitle" values={{ number: tagLength }}/>}
                            </small>
                            {tags.length === 0 ? 
                                <Button type='button' isDisabled isTextButton isWhiteButton value={clearBtnVal} onButtonClick={onHandleClearBtn} /> 
                                :
                                <Button type='button' isTextButton isWhiteButton value={clearBtnVal} onButtonClick={onHandleClearBtn} />}
                        </div>
                        <div className='searchFilter-overlay__container__search__section__body'>
                            {tags && tags.map((_item: string, idx: number)=>
                                {return <Tag
                                            isNormalTag
                                            key={`tag-${idx}`}
                                            name={_item}
                                            icon={closeIcon}
                                            onNormalTagClick={onNormalTagClick}
                                            onTagClick={onHandleRemove}
                                        />
                                })}
                        </div>
                    </section>
                </section>
            </div>
        </div>,
      document.querySelector('#app'),
    );
  }
  return null;
};

export default memo(SearchFilter);
