import React, { memo } from 'react';
import './Card.scss';

export interface IProps {
    children?: string | React.ReactNode,
}

const Card: React.FC<IProps> = (props) => {
    return (
        <div className="esg-card">
            {props.children}
        </div>
    );
}

export default memo(Card);