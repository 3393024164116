import React, { memo,useState } from "react";
import "./FilterContainer.scss";
import Filter from "./FilterItem/Filter";
import { FilterSlider } from 'components';
import { FIELDS } from 'shared/constants/constants';

type Props = {
  items: any; // TODO create type for this
  question: string,
  onRemove?: any,
  onAdd?: any,
  
  /** FilerSlider */
  defaultValueThreshold?: number,
  defaultValueCarbonLevel?: number,
  onChange?: any,
  onAfterChange?: any,

  /** Switcher */
  switcherValue: boolean,
  onSwitcherChange: any,
};

const FilterContainer: React.FC<Props> = memo(({ items, question, onRemove, onAdd, defaultValueThreshold, defaultValueCarbonLevel, onChange = () => {}, onAfterChange = () => {}, switcherValue, onSwitcherChange = () => {}, }) => {
  const [hide,setHide] = useState(true);
  const onHandleRemove = (id: string, name: string) => {
    // console.log('remove from the filter: ', id, name);
    onRemove(id, name);
  }

  const onHandleThresholdChange = (val) => {
    onChange(FIELDS.THRESHOLD, val);
  }

  const onHandleCarbonLevelChange = (val) => {
    onChange(FIELDS.CARBON_RISK_SCORE, val);
  }

  const [switchValue, setSwitchValue] = useState(switcherValue);
  const handleSwitcherChange = (e) => {
    setSwitchValue(!switchValue);
    onSwitcherChange(e);
    console.log('switcher ', e.target)
};

  return (
    <div className={`filter-container`}>
      {hide && <span className="filter-container__ask">{question}</span>}
      <span className="filter-container__hide-btn" onClick={()=>setHide(prev =>!prev)}>{!hide ? 'Hide filters' : 'Show filters'}</span>
      <div className={`filter-container__items ${hide ? 'filter-container__items--hide' : ''}`}>
        {items.map((_item: { id: string, title: string; tags: any; }) => {
          return  <Filter key={_item.title} id={_item.id} title={_item.title} onAdd={onAdd} onRemove={(id: string, name: string) => onHandleRemove(id, name)} tags={_item.tags}/>;
        })}
        <FilterSlider 
          // switcherName={FIELDS.THRESHOLD}
          title='threshold'
          percentage='%'
          number={defaultValueThreshold}
          preposition='below'
          minValue={0}
          maxValue={5}
          minLabel='0.0%'
          maxLabel='Max 5.0%'
          step={0.1}
          defaultValue={defaultValueThreshold}
          onChange={e => onHandleThresholdChange(e)}
          onAfterChange={onAfterChange}
          // isSwitchDisabled
          isSwitcherHidden
          iconBtnId='threshold'
          popupContent='The percentage level at which the organisation do not meet the standards of the Environmental, social, and governance (ESG) criteria.'
        />

        <FilterSlider
          switcherName={FIELDS.IS_CARBON_DISABLED}
          title='carbon level'
          percentage=''
          number={defaultValueCarbonLevel}
          preposition='below'
          minValue={1}
          maxValue={50}
          minLabel='1'
          maxLabel='50'
          step={1}
          defaultValue={defaultValueCarbonLevel}
          onChange={e => onHandleCarbonLevelChange(e)}
          onAfterChange={onAfterChange}
          switcherIsOn={switcherValue}
          onSwitchChange={handleSwitcherChange}
          isDisabled={switchValue}
          // isHidden
          iconBtnId='carbon level'
          popupContent='The extent of carbon emissions and waste produced by the organisation.'
        />
      </div>
    </div>
  );
});

export default FilterContainer;
