import React, { memo, useState } from 'react';
import classNames from 'classnames';
import './ButtonOption.scss';
import Button from 'components/Button/Button';

interface Props {
  name?: string;
  label?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  value?: string;
  onClick?:
  ((event: any) => void) | undefined;
  isOnWhiteBg?: boolean,
  isSecondary?: boolean,
  defaultButtonValue?: string,
  selectedButtonValue?: string,
  // ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
}

const ButtonOption: React.FC<Props> = ({
  label = '',
  isChecked = false,
  isDisabled = false,
  onClick = () => {},
  isOnWhiteBg = false,
  isSecondary = false,
  defaultButtonValue = 'Exclude',
  selectedButtonValue = 'Excluded'
}): JSX.Element => {
  const [mouseDown, setMouseDown] = useState(false);

  const classes = classNames({
    'nw-button-option': true,
    'nw-button-option--uncheck': !isChecked, // !mouseDown || 
    'nw-button-option--clicked': isChecked,
    'nw-button-option--onWhiteBg': isOnWhiteBg,
    'nw-button-option--secondary': isSecondary,
    'nw-button-option--disabled': isDisabled,
    // 'nw-checkmark--checked': isChecked,

    // 'nw-checkmark--has-container': hasContainer,
  });

  return (
    <div className={classes} onClick={onClick}>
      <div className={'nw-button-option__content'}>
        <div className={'nw-button-option__content__header'}>
          {label}
        </div>
      </div>
      <Button
        onMouseUp={() => setMouseDown(false)}
        onMouseDown={() => setMouseDown(true)}
        isSecondary={isSecondary}
        isOutline={!mouseDown && !isChecked}
        value={isChecked ? selectedButtonValue : defaultButtonValue}
        // onButtonClick={() => setClicked(true)}
        onButtonClick={()=>{}}

      />
    </div>
  );
};

export default memo(ButtonOption);
