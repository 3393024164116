/* eslint-disable global-require */
import React, { useContext } from 'react';
import { GlobalContext } from 'shared/context/store';
import { IntlProvider } from 'react-intl';
import englishMessages from 'shared/translations/en.json';
import arabMessages from 'shared/translations/ar.json';

// import { geti18n } from 'shared/services/api';
// TODO investigate this
// if (!Intl.PluralRules) {
//   require('@formatjs/intl-pluralrules/polyfill');
//   require('@formatjs/intl-pluralrules/dist/locale-data/en');
//   require('@formatjs/intl-pluralrules/dist/locale-data/ar');
// }

interface Props {
  children: React.ReactNode;
}

// const ConnectedIntlProvider: React.FC<Props> = ({ children }): JSX.Element => {
//   const { state } = useContext(GlobalContext);
//   const { language: { locale }, clientData: { token } } = state.rootState;
//   const [i18n, setI18n] = useState({});
//   useEffect(() => {
//     geti18n(token).then((res) => {
//       setI18n(res.data);
//     });
//   }, [token]);
//   return (
//     <IntlProvider
//       locale={locale}
//       messages={{ ...englishMessages, ...i18n[locale.toUpperCase()] }}
//     >
//       {children}
//     </IntlProvider>
//   );
// };

const ConnectedIntlProvider: React.FC<Props> = ({ children }): JSX.Element => {
  const { state } = useContext(GlobalContext);
  const {
    language: { locale },
  } = state.rootState;

  const getMessage: any = () => {
    if (locale === 'EN') {
      return englishMessages;
    }
    return arabMessages;
  };
  return (
    <IntlProvider locale={locale} messages={{ ...getMessage() }}>
      {children}
    </IntlProvider>
  );
};

export default ConnectedIntlProvider;
