import { v4 as uuidv4 } from 'uuid';
import { STORAGE_KEY } from "./../../constants/constants";
// import { State, Actions } from 'shared/types';
import ACTION_TYPE from "shared/constants/actionTypes";
import apiService from "shared/services/apiService";
import { PATH, PAGE_SIZE } from "shared/constants/constants";
import utils from "shared/helpers/utils";
import selectors from "shared/context/selectors/index";
// import { FIELDS } from 'shared/constants/constants';

export const changeLanguage = (language: any) => async (dispatch: any) => {
  // const { clientData: { token } } = state.rootState;
  try {
    dispatch({
      type: "CHANGE_LANGUAGE",
      payload: language,
    });
    // return res;
  } catch (error) {
    return error;
  }
};

/** Save master data to the context */
const fetchMasterData = () => async (dispatch: any) => {
  try {
    const res = await apiService.getMasterData();
    dispatch({
      type: ACTION_TYPE.MASTER_DATA,
      payload: res,
    });
  } catch (err) {
    apiError(err)(dispatch);
  }
};

const updateUserId = (userId) => (dispatch: any, state: any) => {
  if (userId) {
    dispatch({
      type: ACTION_TYPE.AUTH.UPDATE_USER_ID,
      payload: userId,
    });
  } else if (!state.rootState?.userId) {
    const newUserId = uuidv4();
    dispatch({
      type: ACTION_TYPE.AUTH.UPDATE_USER_ID,
      payload: newUserId,
    });
  }
};

const updateInvestmentData = (key, value) => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPE.UPDATE_INVESTMENT_DATA,
    payload: {
      key: key,
      value: value,
    },
  });
};

const updateGroupedSupports = (payload) => (dispatch: any, state: any) => {
  const { groupedSupports } = state.investmentState;
  // const supports = utils.getSupportsByID(environment, social, governance);
  let selectedSupports = [...groupedSupports];

  /** Find index of value (payload) */
  const index = selectedSupports.indexOf(payload);
  if (index !== -1) {
    selectedSupports.splice(index, 1);
  } else {
    selectedSupports.push(payload);
  }

  dispatch({
    type: ACTION_TYPE.UPDATE_GROUPED_SUPPORTS,
    payload: selectedSupports,
  });
};

/** Authentication actions */
const requestOtpSuccess = (phoneNumber) => (dispatch) => {
  dispatch({
    type: ACTION_TYPE.AUTH.REQUEST_OTP_SUCCESS,
    payload: phoneNumber,
  });
};

const verifyOtpSuccess = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPE.AUTH.VALIDATE_TOKEN_SUCCESS,
    payload,
  });
};

const authError = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPE.AUTH.ERROR,
    payload,
  });
};

const requestOtp = (phoneNumber: string, history: any) => async (dispatch) => {
  try {
    const res = await apiService.requestOTP(phoneNumber);
    if (res && res.status === 204) {
      requestOtpSuccess(phoneNumber)(dispatch);
      history.push(PATH.OTP);
    }
  } catch (error) {
    authError("Your phone number is not registered!")(dispatch);
  }
};

const verifyOtp =
  (password: string, history: any) => async (dispatch, state) => {
    const { phoneNumber } = state.rootState;
    try {
      const res = await apiService.validateOTP(phoneNumber, password);
      if (res) {
        verifyOtpSuccess(res.data.token)(dispatch);
        // console.log('PHONE NUMBER FR STORE', phoneNumber, res, history);
        history.push(PATH.INTRO);
      }
    } catch (error) {
      console.log("error", error);
      authError("Invalid OTP number")(dispatch);
      // await dispatch({
      //   type: ACTION_TYPE.AUTH.ERROR,
      //   payload: 'Invalid OTP number',
      // });
    }
  };

const validateToken = () => async (dispatch, state) => {
  const { token } = state.rootState;
  try {
    const res = await apiService.validateToken(token);
    if (!res) {
      throw new Error("Token is invalid or has expired!");
    }
  } catch (error) {
    authError(error)(dispatch);
  }
};

/** Use this action to store the error when requesting for investment api */
const apiError = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPE.REQUEST_FAILED,
    payload,
  });
};

/** get the code from master data for Exclusion */
const parseMasterExclusions = (masterData, selected) => {
  if (!selected.length) {
    return [];
  }
  let selection = [];
  for (let item of selected) {
    selection = [
      ...selection,
      ...masterData
        .filter((master) => master.name.toLowerCase() === item.toLowerCase())
        .map((str) => str.code),
    ];
  }
  return selection;
};

const parseExperience = (data) => {
  const parsedData = data.map((item) => {
    if (item.direction === "left") {
      return "nw.esg.experience.no";
    } else if (item.direction === "right") {
      return "nw.esg.experience.selected";
    }
    return "nw.esg.experience.skipped";
  });
  return parsedData;
};

/** Get User Portfolio based on user inputted data */
const getUserScore = () => async (dispatch, state) => {
  const {
    token,
    phoneNumber,
    masterData: { EN },
  } = state.rootState;
  const {
    style,
    experience,
    investHighRanking,
    investMediocreRanking,
    exclusions,
    groupedSupports,
    threshold,
    carbonRiskScore,
    isCarbonDisabled,
  } = state.investmentState;
  const parsedExclusions = utils.getExclusions(exclusions);
  const modifiedCarbonRiskScore = !exclusions.length ? null : carbonRiskScore;
  // const modifiedCarbonRiskScore = !isCarbonDisabled ? null : carbonRiskScore;
  const submittedAnswers = [
    ...style,
    ...groupedSupports,
    ...parseExperience(experience),
    ...investHighRanking,
    ...investMediocreRanking,
    ...parseMasterExclusions(EN.exclusion, parsedExclusions),
  ];
  // console.log('SUBMIT >> ', submittedAnswers);
  // , exclusions, parsedExclusions, EN.exclusion, parseMasterExclusions(EN.exclusion, parsedExclusions)
  try {
    const res = await apiService.postUserScore(
      token,
      phoneNumber,
      groupedSupports,
      parsedExclusions,
      threshold,
      modifiedCarbonRiskScore,
      submittedAnswers
    );
    await dispatch({
      type: ACTION_TYPE.GET_USER_PORTFOLIO,
      payload: {
        name: res.name,
        score: res.score,
        description: res.description,
        esgRankings: res.esgRankings,
      },
    });
  } catch (error) {
    apiError(error)(dispatch);
  }
};

/** ACTIONS for PRODUCTS */

/**
 * Call when there is error when fetching products from the API
 */
const productError = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPE.PRODUCTS.ERROR,
    payload,
  });
};

// const initializeProducts = (payload: any) => (dispatch: any) => {
//   dispatch({
//     type: ACTION_TYPE.PRODUCTS.INITIALIZE_PRODUCTS,
//     payload,
//   })
// };

const changeNumberOfRecords = (numOfRecords) => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPE.PRODUCTS.NUMBER_OF_RECORDS_CHANGED,
    numOfRecords,
  });
};

const changeTotalOfRecords = (payload) => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPE.PRODUCTS.TOTAL_OF_RECORDS_CHANGED,
    payload,
  });
};

/**
 * Save products loaded in the homepage result
 * @param payload : array of objects
 */
const onProductsLoaded = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPE.PRODUCTS.PRODUCTS_LOADED,
    payload,
  });
};

/**
 * This is to handle saving of products loaded for PAGINATION use
 * get and Save new data everytime we paginate
 */
const onSaveProductsLoaded = () => (dispatch, state) => {
  const { productsLoaded } = state.productState;
  const productsData = [...productsLoaded];
  const parsedData = selectors.modifiedProducts(state.productState.data);
  const numberToIncrease = PAGE_SIZE;
  const newPageSize = productsData.length + numberToIncrease;
  const newProductsData = [
    ...productsData,
    ...parsedData.slice(productsData.length, newPageSize),
  ];

  onProductsLoaded(newProductsData)(dispatch);
};

const onSetProductLoading = (payload) => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPE.PRODUCTS.IS_PRODUCT_LOADING,
    payload,
  });
};

const getProducts = () => async (dispatch, state) => {
  const { token } = state.rootState;
  const { groupedSupports } = state.investmentState;
  const {
    investmentState: {
      exclusions,
      threshold,
      carbonRiskScore,
      isCarbonDisabled,
    },
  } = JSON.parse(localStorage.getItem(STORAGE_KEY.ESG_STATE));
  // const supports = utils.getSupports(environment, social, governance);
  // console.log('LOCAL ', exclusions, threshold, carbonRiskScore);
  const parsedExclusions = utils.getExclusions(exclusions);
  const modifiedCarbonRiskScore = !isCarbonDisabled ? null : carbonRiskScore;

  try {
    const res = await apiService.getProducts(
      token,
      groupedSupports,
      parsedExclusions,
      threshold,
      modifiedCarbonRiskScore
    );
    // const res = await apiService.getProducts(token, groupedSupports, parsedExclusions, threshold, carbonRiskScore);
    // initializeProducts(res.rows)(dispatch);
    onSetProductLoading(true)(dispatch);
    if (res) {
      await dispatch({
        type: ACTION_TYPE.PRODUCTS.INITIALIZE_PRODUCTS,
        payload: res.rows,
      });

      const getFilteredData = selectors.modifiedProducts(res.rows);
      // console.log('data after filter', getFilteredData);
      changeTotalOfRecords(getFilteredData.length)(dispatch);
      const modifyProductsData = [...getFilteredData.slice(0, PAGE_SIZE)];
      onProductsLoaded(modifyProductsData)(dispatch);
      onSetProductLoading(false)(dispatch);
    }
  } catch (error) {
    productError(error)(dispatch);
  }
};

/**
 *
 * @param filterType : field name of the filter
 * @param selectedFilterList
 */
const onChangeFilter =
  (filterType, selectedFilterList) => async (dispatch: any) => {
    await dispatch({
      type: ACTION_TYPE.PRODUCTS.PRODUCT_FILTER_CHANGED,
      payload: {
        filterType,
        selectedFilterList,
      },
    });

    const localStore = JSON.parse(localStorage.getItem(STORAGE_KEY.ESG_STATE));
    const getFilteredData = selectors.modifiedProducts(
      localStore.productState.data
    );
    const modifyProductsData = [...getFilteredData.slice(0, PAGE_SIZE)];
    // const localStore = JSON.parse(localStorage.getItem('stateEsg'));
    // console.log('ACTION >> GET FILTERED DATA AFTER RISK CHANGE', state.productState.filters, 'LOCAL');
    // console.log('ACTION: filtered length', getFilteredData.length);
    changeTotalOfRecords(getFilteredData.length)(dispatch);
    // onProductsLoaded(modifyProductsData)(dispatch);
    dispatch({
      type: ACTION_TYPE.PRODUCTS.PRODUCTS_LOADED,
      payload: modifyProductsData,
    });
    // const parsedData = selectors.modifiedProducts(state.productState);
  };

/**
 * Use this filter to remove those in the homepage + user inputs
 * @param key : fieldname or variable use for condition
 * @param value : field value
 */
const onUpdateUserInputFilters =
  (key, value) => async (dispatch: any, state: any) => {
    const { exclusions, groupedSupports } = state.investmentState;

    if (key === "exclusions") {
      const newArr = await exclusions.filter((item) => item !== value);
      updateInvestmentData(key, newArr)(dispatch);
      await getProducts()(dispatch, state);
      // }, 500);
    }

    if (key === "supports") {
      const newArrSupports = groupedSupports.filter((item) => item !== value);
      updateInvestmentData("groupedSupports", newArrSupports)(dispatch);
      // if (environment === value) {
      //   updateInvestmentData('environment', '')(dispatch);
      // } else if (social === value) {
      //   updateInvestmentData('social', '')(dispatch);
      // } else if (governance === value) {
      //   updateInvestmentData('governance', '')(dispatch);
      // }
    }
  };

const onClearSupports = () => (dispatch: any) => {
  dispatch({ type: ACTION_TYPE.CLEAR_SUPPORTS });
};

const onClearUserInputData = () => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPE.CLEAR_USER_INPUT_DATA,
  });
  /** Redirect to Login page */
  window.location.replace(PATH.INDEX);
};

/**
 * Save news loaded
 * @param payload : array of objects
 */
const storeNews = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION_TYPE.STREAMING_NEWS,
    payload,
  });
};

/**
 * Save search history
 * @param payload : array of objects
 */
const storeSearchCriteria = (payload) => async (dispatch, state) => {
  const { token } = state.rootState;
  const { searchTerms } = state.newsState;

  let newSearchTermsArr = [...searchTerms];
  const insert = (arr, index, newItem) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index),
  ];
  const index = newSearchTermsArr.indexOf(payload);
  if (index !== -1) {
    newSearchTermsArr.splice(index, 1, payload);
  } else {
    newSearchTermsArr = insert(newSearchTermsArr, 0, payload);
  }
  let finalSearchTermsArr = newSearchTermsArr.slice(0, 10);

  try {
    await apiService.postSearchCriteria(token, finalSearchTermsArr);
    await dispatch({
      type: ACTION_TYPE.POST_SEARCH_CRITERIA,
      payload: finalSearchTermsArr,
    });
  } catch (error) {
    apiError(error)(dispatch);
  }
};

/**
 * Delete certain searches from search history
 * @param payload : array of objects
 */
const removeSearchCriteria = (payload) => async (dispatch, state) => {
  const { token } = state.rootState;
  const { searchTerms } = state.newsState;
  let newSearchTermsArr = [...searchTerms];

  newSearchTermsArr = newSearchTermsArr.filter((x) => x !== payload);

  try {
    await apiService.postSearchCriteria(token, newSearchTermsArr);
    await dispatch({
      type: ACTION_TYPE.POST_SEARCH_CRITERIA,
      payload: newSearchTermsArr,
    });
  } catch (error) {
    apiError(error)(dispatch);
  }
};

/**
 * Retrieve search history
 * @param payload : array of objects
 */
const getSearchCriteria = () => async (dispatch, state) => {
  const { token } = state.rootState;
  try {
    const res = await apiService.getSearchCriteria(token);
    await dispatch({
      type: ACTION_TYPE.GET_SEARCH_CRITERIA,
      payload: res.keywords,
    });
  } catch (error) {
    apiError(error)(dispatch);
  }
};

const storeAction = {
  // updateInvestmentStyle,

  fetchMasterData,
  updateUserId,

  updateInvestmentData,
  updateGroupedSupports,

  requestOtp,
  verifyOtp,
  validateToken,

  getUserScore,
  getProducts,
  changeNumberOfRecords,
  changeTotalOfRecords,

  onProductsLoaded,
  onSaveProductsLoaded,

  onChangeFilter,
  onUpdateUserInputFilters,
  onClearSupports,
  onClearUserInputData,

  storeNews,
  storeSearchCriteria,
  removeSearchCriteria,
  getSearchCriteria,
};

export default storeAction;
