import React, { useMemo } from 'react';
import connect from 'shared/context/connect';
import { useHistory } from "react-router-dom";
import { PATH, FIELDS } from "shared/constants/constants";
import Options from "components/CheckMarkOtions/CheckMarkOtions";
import WithBottomButton from "components/Layout/WithBottomButton";
import storeAction from 'shared/context/actions';
import { defaultLayout } from 'layouts';
import track from 'react-tracking';
import { trackButtonClick, buildAnswerCodeFromArr } from 'shared/lib/tracking.utils';

interface Props {
  investmentStyle?: string,
  onSave?: any,
  data: Array<object>,
  userId: string,
  tracking: Function,
}
const StylePicker: React.FC<Props> = ({
  investmentStyle, onSave = () => {}, data = [], tracking = () => {}, userId
}) => {
  // const [checkedItem, setCheckedItem] = useState(investmentStyle);
  const history = useHistory();

  const handleItemChecked = (value:any,name:string) => {
     console.log('value changed',name, value);
    //  setCheckedItem(value);
     onSave(name, value);
  };

  // const dummyItems = [
  //   { id: 'investmentStyle1', label: "I don't want to mix investing and ESG.", title: "Returns, returns, returns.", type: "string"},
  //   { id: 'investmentStyle2', label: "I'll invest sustainably if I get good returns. ", title: "Returns first, values a close second.", type: "string"},
  //   { id: 'investmentStyle3', label: "Sustainable investing is critical for returns.", title: "Returns. Values. Impact. I want it all.", type: "string"},
  //   { id: 'investmentStyle4', label: "My values determine where I invest.", title: "Impact first, returns second.", type: "string" },
  //   { id: 'investmentStyle5', label: "I do not care at all about financial returns.", title: "Impact, impact, impact.", type: "string"},
  // ];

  const renderOptions = useMemo(() => {
    const mappedData = data.map((item: any) => ({ id: item.code, title: item.name, label: item.desc })).slice(1);
    return mappedData;
  }, [data]);

  // const dummyItems2 = [
  //   { id: '13', label: "I don't want to mix investing and ESG.", title: "Returns, returns, returns.", type: "string"},
  //   { id: '44', label: "I'll invest sustainably if I get good returns. ", title: "Returns first, values a close second.", type: "string"},
  //   // { id: 3, label: "Sustainable investing is critical for returns.", title: "Returns. Values. Impact. I want it all.", type: "string"},
  // ];

  return (
    <WithBottomButton
      hideSkip
      // title={'Pick your style.'}
      description={'People invest for many reasons, what do YOU want from your investments?'}
      disable={!investmentStyle}
      onButtonClick={() => {
        const answerCode = buildAnswerCodeFromArr(investmentStyle);
        trackButtonClick(tracking, userId, [
          { key: 'questionCode', value: data[0]['code'] },
          { key: 'answerCode', value: answerCode },
        ]);
        history.push(PATH.ENVIRONMENT);
      }}
    >
        <Options
          optionList={renderOptions}
          checkedValues={investmentStyle}
          type='radio'
          onCheckMarkChange={handleItemChecked}
          name={FIELDS.STYLE}
        />
    </WithBottomButton>
  );
};

const mapStateToProps = ({ investmentState, rootState }) => ({
  investmentStyle: investmentState.style,
  data: rootState.masterData.EN.investmentStyle,
  userId: rootState.userId
});

const mapDispatchToProps = ({
  onSave: storeAction.updateInvestmentData,
});

// const mapDispatchToProps = dispatch => ({
//   onSave: (val) => storeAction.updateInvestmentStyle(val)(dispatch),
// })

export default track()(connect(mapStateToProps, mapDispatchToProps)(defaultLayout(StylePicker)));
