import React from 'react';

import { defaultLayout } from 'layouts';

// import Button from 'components/Button';
// import CheckMark from 'components/CheckMark';
// import { FormattedMessage } from 'react-intl';
import { changeLanguage } from 'shared/context/actions';
import connect from 'shared/context/connect';
// import BackButton from 'components/Button/BackButton/BackButton';
// import ButtonOption from 'components/ButtonOption/ButtonOption';
// import CheckMarkImage from 'components/CheckMarkImage/CheckMarkImage';
// import Tag from 'components/Tag/Tag';

// eslint-disable-next-line react/prop-types
const Test = () => {

  // const [checked, setChecked] = useState(true);

  return <div>
    {/* <Button value='click me' />
    <Button value='click me' isSecondary />
    <Button value='click me' isTertiary />
    <span>current langue {language}</span>

    <ButtonOption isChecked={checked} onCheckMarkChange={() => setChecked(prev => !prev)} />
    <CheckMarkImage isChecked={checked} onCheckMarkChange={() => setChecked(prev => !prev)} />
    <button onClick={() => onChangeLanguage(language === 'EN' ? 'AR' : 'EN')}>Change language</button>
    <FormattedMessage id="hello" />

    <div>
      <Tag name='tag 1' />
      <Tag name='tag 2' />
      <Tag name='tag 3' />
      <Tag name='tag 4' />

    </div> */}

    {/* <div>
      <CheckMarkItemContainer />
    </div> */}

    {/* <SwipeCard/> */}
    {/* <BackButton/> */}
  </div>;
};
const mapStateToProps = ({ rootState }) => ({
  language: rootState.language.locale
  // detail: dashboard.detail,
});

const mapDispatchToProps = ({
  onChangeLanguage: changeLanguage,
});

export default connect(mapStateToProps, mapDispatchToProps)(defaultLayout(Test));