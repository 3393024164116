import React, { memo } from 'react';
import Icons from 'assets/images/esg-icon.svg';
import './Rating.scss';

type Props = {
  ratingNumber?: number;
  id?: string,
};

const Rating: React.FC<Props> = memo(({ ratingNumber = 5, id = '' }) => {
  return (
    <div className="rating">
      {Array.from({ length: ratingNumber }, (item, i) => (
        <img key={`${id}-${i}`} src={Icons} />
      ))}
    </div>
  );
});

export default Rating;
