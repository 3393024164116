import React, { memo } from "react";
import classnames from 'classnames';

import './Chips.scss';

interface IChipsProps {
    title?: string,
    isWithTitle: boolean,
    isPrimary?: boolean,
    data?: Array<string>,
}

const Chips: React.FC<IChipsProps> = ({
    title = 'Title here',
    isWithTitle = false,
    isPrimary = false,
    data = ['test 1', 'test 2', 'test 3', 'test 1', 'test 2', 'test 3', 'test 1', 'test 2', 'test 3'],
}) => {
    const classes = classnames({
        'esg-chips__list': true,
        'esg-chips__list--primary': isPrimary,
        'esg-chips__list--secondary': !isPrimary,
    });
    return (
        <section className="esg-chips">
            {isWithTitle? 
                <>
                    <h6 className="esg-chips__title">{title}</h6>
                    <ul className={classes}>
                        {data && data.map((item, idx) => (
                            <li key={isPrimary ? `primary-${idx}` : `secondary-${idx}`}>{item}</li>
                        ))}
                    </ul>
                </>
            :
                <ul className={classes}>
                    {data && data.map((item, idx) => (
                        <li key={isPrimary ? `primary-${idx}` : `secondary-${idx}`}>{item}</li>
                    ))}
                </ul>
            }
        </section>
    );
}

export default memo(Chips);