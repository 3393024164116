import React, { useMemo } from "react";
import { CheckMarkItemContainer } from "components";
import connect from "shared/context/connect";
import WithBottomButton from "components/Layout/WithBottomButton";
import { useHistory } from "react-router-dom";
import { PATH, FIELDS } from "shared/constants/constants";
import storeAction from "shared/context/actions";
import { defaultLayout } from "layouts";
import utils from "shared/helpers/utils";
import track from 'react-tracking';
import { trackButtonClick, buildAnswerCodeFromArr } from 'shared/lib/tracking.utils';

interface Props {
  onSave?: any;
  governance?: string;
  data: Array<object>;
  userId: string;
  tracking: Function;
}
const Governance: React.FC<Props> = ({
  onSave = () => {},
  governance,
  data,
  tracking = () => {},
  userId,
}) => {
  // const [checkedItem, setCheckedItem] = useState(governance);
  const history = useHistory();
  const { getStringAfterSpecialChar } = utils;

  // const handleItemChecked = (e: any) => {
  //   if (e.target.checked) {
  //     onSave(e.target.name, e.target.id);
  //   } else {
  //     onSave(e.target.name, null);
  //   }
  // };

  const handleItemChecked = (value: any, name: string) => {
    onSave(name, value);
  };

  const handleSkipped = () => {
    // onSave(FIELDS.GOVERNANCE, null);
    // history.push(PATH.EXPERIENCE);
    onSave(FIELDS.GOVERNANCE, []);
    trackButtonClick(tracking, userId, [
      { key: 'questionCode', value: data[0]['code'] },
      { key: 'skipped', value: 'true' },
    ]);
    history.push(`${PATH.TRANSITION}?id=1`);
  };

  const renderOptions = useMemo(() => {
    const mappedData = data.map((item: any) => ({
      id: item.code,
      title: item.name,
      label: item.desc,
      imgSrc: `gov-${getStringAfterSpecialChar(item.code, ".")}.png`,
    }));
    return mappedData;
  }, [data]);

  // const data = [
  //   { id: 'auditCommittee', label: "Xxxxxxx xxxxxxx xxxx xx xxxxx xxxx xxxxxxx xxxx xx xxxxx.", title: "Audit committee structure", imgSrc: "gov-audit-committee.png" },
  //   { id: 'execCompensation', label: "Xxxxxxx xxxxxxx xxxx xx xxxxx xxxx xxxxxxx xxxx xx xxxxx.", title: 'Executive compensation', imgSrc: 'gov-executive-compensation.png' },
  //   { id: 'boardComposition', label: "Xxxxxxx xxxxxxx xxxx xx xxxxx xxxx xxxxxxx xxxx xx xxxxx.", title: 'Board composition', imgSrc: 'gov-board-composition.png' },
  //   { id: 'brideryCorruption', label: "Xxxxxxx xxxxxxx xxxx xx xxxxx xxxx xxxxxxx xxxx xx xxxxx.", title: 'Bribery and corruption policies', imgSrc: 'gov-bribery.png' },
  //   { id: 'lobbyingActivities', label: "Xxxxxxx xxxxxxx xxxx xx xxxxx xxxx xxxxxxx xxxx xx xxxxx.", title: 'Lobbying activities', imgSrc: 'gov-lobbying-activities.png' },
  //   { id: 'PoliticalContributions', label: "Xxxxxxx xxxxxxx xxxx xx xxxxx xxxx xxxxxxx xxxx xx xxxxx.", title: "Political contributions", imgSrc: "gov-political-contributions.png" }
  // ];

  return (
    <WithBottomButton
      // title="Governance"
      description="What GOVERNANCE causes do you care about the most?"
      onSkipClick={() => handleSkipped()}
      disable={!governance.length}
      onButtonClick={() => {
        // history.push(PATH.EXPERIENCE);
        const answerCode = buildAnswerCodeFromArr(governance);
        trackButtonClick(tracking, userId, [
          { key: 'questionCode', value: data[0]['code'] },
          { key: 'answerCode', value: answerCode },
        ]);
        history.push(`${PATH.TRANSITION}?id=1`);
      }}
    >
      <CheckMarkItemContainer
        name={FIELDS.GOVERNANCE}
        selected={governance}
        items={renderOptions.slice(1)}
        type="checkbox"
        onSelect={handleItemChecked}
      />
    </WithBottomButton>
  );
};

const mapStateToProps = ({ investmentState, rootState }) => ({
  governance: investmentState.governance,
  data: rootState.masterData.EN.governance,
  userId: rootState.userId,
});

const mapDispatchToProps = {
  onSave: storeAction.updateInvestmentData,
};

export default track()(connect(
  mapStateToProps,
  mapDispatchToProps
)(defaultLayout(Governance)));
