import moment from 'moment';
import 'moment/min/locales';

/** Format date e.g. 07 Feb 2020 */
const formatDate = (date: string | Date) => {
    return moment(date).format('DD MMM YYYY')
}

/**
 * Converts from numeric epoch to date object
 * @params epoch : epoch (UNIX) time
 * @returns date object
 */
const getDateFromEPOCH = (epoch) => {
  let date = new Date(0); // The 0 there is the key, which sets the date to the epoch
  if (epoch.length < 12) {
    date.setUTCSeconds(epoch);
  } else {
    date.setUTCMilliseconds(epoch);
  }
  return date;
};

/**
 * Converts EPOCH//UNIT TIMESTAMP into human readble date
 * in this format DD/MM/YYYY e.g. 24/10/2020
 * @params epoch : unixTimestamp
 * @returns DD/MM/YYYY
 */
const convertEpochToDefaultDateFormat = (epoch) => {
  const newDate = getDateFromEPOCH(epoch);
  return formatDate(newDate);
};

/**
 * Converts EPOCH//UNIT TIMESTAMP into date string to be used
 * for < LastUpdated /> component
 * in this format DD MMMM YYYY, hh:MM:SS AM/PM e.g. 29 December 2020, 3:10:29 PM
 * @params epoch : unixTimestamp
 * @returns DD MMMM YYYY, hh:MM:SS AM/PM
 */
const epochToLongDateString = (epoch) => {
  // get date object using getDateFromEPOCH util from above
  const newDate = getDateFromEPOCH(epoch);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Extract date components from date object
  var date : string | number = newDate.getDate();
  const month = monthNames[newDate.getMonth()];
  const year = newDate.getFullYear();
  var hours = newDate.getHours();
  var minutes : string | number = newDate.getMinutes();
  var seconds : string | number = newDate.getSeconds();
  const amPm = hours >= 12 ? "PM" : "AM";

  // Formatting
  hours = hours % 12; // 12-hr format
  hours = hours ? hours : 12; // the hour '0' should be '12'
  date = date < 10 ? "0" + date : date; // Add '0' in front of single digit
  minutes = minutes < 10 ? "0" + minutes : minutes; // Add '0' in front of single digit
  seconds = seconds < 10 ? "0" + seconds : seconds; // Add '0' in front of single digit

  var strTime = ` ${date} ${month} ${year}, ${hours}:${minutes}:${seconds} ${amPm}`;
  return strTime;
};


/**
 * Converts EPOCH//UNIT TIMESTAMP into date string to be used
 * for < LastUpdated /> component
 * in this format DD MMMM YYYY, hh:MM:SS AM/PM e.g. 29 December 2020, 3:10:29 PM
 * @params epoch : unixTimestamp
 * @returns DD MMMM YYYY, hh:MM:SS AM/PM
 */
 const epochToShortDateString = (epoch) => {
  // get date object using getDateFromEPOCH util from above
  const newDate = getDateFromEPOCH(epoch);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract date components from date object
  var date : string | number = newDate.getDate();
  const month = monthNames[newDate.getMonth()];
  const year = newDate.getFullYear();
  var hours = newDate.getHours();
  var minutes : string | number = newDate.getMinutes();
  const amPm = hours >= 12 ? "PM" : "AM";

  // Formatting
  hours = hours % 12; // 12-hr format
  hours = hours ? hours : 12; // the hour '0' should be '12'
  date = date < 10 ? "0" + date : date; // Add '0' in front of single digit
  minutes = minutes < 10 ? "0" + minutes : minutes; // Add '0' in front of single digit

  var strTime = ` ${date} ${month} ${year}, ${hours}:${minutes} ${amPm}`;
  return strTime;
};

/**
 * Converts string (dateTime) to CN standards
 * First convert EPOCH to RFC2822 standard format
 * in this format YYYY MM DD, hh:MM e.g. 2021年3月11日 21:02
 * @returns CN: YYYY MM DD, hh:MM
 */
const cnDateTime = (dateTime) => {
  var rfcFromateDateTime = moment.unix(dateTime).format('YYYY-MM-DD HH:mm:ss')
  return moment(rfcFromateDateTime).locale('zh-cn').format('lll')
}

/** Asset's public directory */
const baseUrl = process.env.NODE_ENV === 'production'
  ? `${process.env.PUBLIC_URL}assets`
  : 'assets';

  /**
 * is app running as PWA
 */
const isInStandaloneMode = () => (window.matchMedia('(display-mode: standalone)').matches) || document.referrer.includes('android-app://');

/** temporary method to parsed IDs (use the master data) */
const temporaryParseId = {
    adultEntertainment: 'Adult entertainment',
    alcohol: 'Alcohol',
    childLabour: 'Child labour',
    palmOil: 'Palm oil',
    gambling: 'Gambling',
    tobacco: 'Tobacco',
    thermalCoal: 'Thermal coal',
    controversialWeapons: 'Controversial weapons',
    climateChange: 'Climate Change',
    pollutionAndHaze: 'Pollution and haze',
    animalWelfare: 'Animal welfare',
    diversity: 'Diversity',
    humanRights: 'Human rights',
    communityEngagement: 'Community engagement',
    boardIndependence: 'Board independence',
    transparencyDisclosure: 'Transparency & disclosure',
    shareholderRights: 'Shareholder rights'
};

/** Get supports */
const getSupports = (environment, social, governance) => {
    let data = [];
    if (environment) {
      // data.push(temporaryParseId[environment]);
      data = [...data, ...environment.map((item: string) => temporaryParseId[item])];
    }
    if (social) {
      // data.push(temporaryParseId[social])
      data = [...data, ...social.map((item: string) => temporaryParseId[item])];
    }
    if (governance) {
      // data.push(temporaryParseId[governance])
      data = [...data, ...governance.map((item: string) => temporaryParseId[item])];
    }
    return data;
}

const getSupportsByID = (environment, social, governance) => {
  let data = [];
  if (environment) {
    // data.push(environment);
    data = [...data, ...environment];
  }
  if (social) {
    // data.push(social)
    data = [...data, ...social];
  }
  if (governance) {
    // data.push(governance)
    data = [...data, ...governance];
  }
  return data;
}

const getExclusions = (data) => {
    let newData = [];
    data.forEach((item) => {
      newData.push(temporaryParseId[item]);
    })
    return newData;
}

const exclusionOptions = [
  {id: 'palmOil', label: 'Palm oil', tmpCode: 'nw.esg.exclusion.palm_oil'},
  {id: 'thermalCoal', label: 'Thermal coal', tmpCode: 'nw.esg.exclusion.thermal_coal'},
  {id: 'alcohol', label: 'Alcohol', tmpCode: 'nw.esg.exclusion.alcohol'},
  {id: 'gambling', label: 'Gambling', tmpCode: 'nw.esg.exclusion.gambling'},
  {id: 'tobacco', label: 'Tobacco', tmpCode: 'nw.esg.exclusion.tobacco'},
  {id: 'controversialWeapons', label: 'Controversial weapons', tmpCode: 'nw.esg.exclusion.controversial_weapons'},
  {id: 'adultEntertainment', label: 'Adult entertainment', tmpCode: 'nw.esg.exclusion.adult_entertainment'},
  // {id: 'childLabour', label: 'Child labour'}
];

const causesToSupportOptions = [
  {id: 'climateChange', label: 'Climate Change' },
  {id: 'pollutionAndHaze', label: 'Pollution and haze' },
  {id: 'animalWelfare', label: 'Animal welfare' },
  {id: 'diversity', label: 'Diversity' },
  {id: 'humanRights', label: 'Human rights' },
  {id: 'communityEngagement', label: 'Community engagement' },
  {id: 'boardIndependence', label: 'Board independence' },
  {id: 'transparencyDisclosure', label: 'Transparency & disclosure' },
  {id: 'shareholderRights', label: 'Shareholder rights' }
]

/**
 * Get string after last char
 * @param string 
 * @param char 
 * e.g. getStringAfterSpecialChar('nw.esg.env.climate_change', '.')
 * @returns climate_change
 */
const getStringAfterSpecialChar = (string, char) => {
  const newStr = string.split(char).pop();
  return newStr
}

const utils = {
    formatDate,
    getDateFromEPOCH,
    convertEpochToDefaultDateFormat,
    epochToLongDateString,
    epochToShortDateString,
    cnDateTime,
    baseUrl,
    isInStandaloneMode,
    temporaryParseId,

    getSupports,
    getExclusions,
    getSupportsByID,

    exclusionOptions,
    causesToSupportOptions,

    getStringAfterSpecialChar
}

export default utils;