const FS_DOMAIN = process.env.REACT_APP_FUND_SINGAPORE_DOMAIN;

export const FS_PAGE_LINK = {
  HOME: FS_DOMAIN,
  FUND_LIBRARY: `${FS_DOMAIN}/fund-library`,
  PROFILERS: '#',
  REPORTS: 'https://imas.org.sg/education/fundsingapore',
  EDUCATION_CENTER: `${FS_DOMAIN}/education-center`,
  FUND_DISTRIBUTORS: '#',
  RISK_PROFILER: `${FS_DOMAIN}/profilers/risk-tolerance`,
  SUSTAINABILITY_PROFILER: '#'
}

export const PATH = {
  INDEX: '/',
  OTP: '/otp',
  INTRO: '/intro',
  TEST: '/test',
  STYLE_PICKER: '/stylepicker',
  ENVIRONMENT: '/environment',
  SOCIAL: '/social',
  GOVERNANCE: '/governance',
  SCORE: '/esg-score',
  EXCLUSIONS: '/exclusions',
  EXPERIENCE: '/experience',
  HOME: '/homepage',
  HIGH_RANKING: '/high-esg-ranking',
  MOMENTUM: '/momentum-ranking',
  PREFERENCES: '/preferences',
  STREAMING_NEWS: '/streaming-news',
  TRANSITION: '/transition'
};

export const FIELDS = {
  STYLE: 'style',
  EXCLUSIONS: 'exclusions',
  ENVIRONMENT: 'environment',
  SOCIAL: 'social',
  GOVERNANCE: 'governance',
  EXPRIENCE: 'experience',
  HIGH_RANKING: 'investHighRanking',
  MOMENTUM: 'investMediocreRanking',
  PRODUCT_RISK: 'productRisk',
  GROUPED_SUPPORTS: 'groupedSupports',
  CARBON_RISK_SCORE:'carbonRiskScore',
  THRESHOLD:'threshold',
  IS_CARBON_DISABLED: 'isCarbonDisabled'
};

export const STORAGE_KEY = {
  ESG_STATE: 'stateEsgFS',
  EXPIRATION_DATE: 'stateEsgFSExpiration'
}

/** 30mins expiration time */
export const EXPIRATION_TIME = 1800000; // 15000 (15 secs)

export const PAGE_SIZE = 10;

export const TRACKING_DATA = {
  appName: process.env.TRACKING_APP_NAME || 'imas-staging',
  actionType: {
    pageView: 'pageView',
    click: 'click,'
  }
}