import React from 'react';
import ReactDOM from 'react-dom';
import { GlobalProvider } from 'shared/context/store';
import ConnectedIntlProvider from './components/utils/ConnectedIntlProvider';
import './index.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { debugContextDevtool } from 'react-context-devtool';
import App from './App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');

ReactDOM.render(
  <GlobalProvider>
      <ConnectedIntlProvider>
        <Router>
          <App />
        </Router>
      </ConnectedIntlProvider>
  </GlobalProvider>,
  container
);

const devToolOption = ({
  debugReducer: true,
  debugContext: true,
  disable: false,
  disableAutoMode: process.env.NODE_ENV === 'development' ? true : false,
});

/** Context DevTool */
debugContextDevtool(container, devToolOption);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
