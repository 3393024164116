import React, { PureComponent } from 'react';
import connect from 'shared/context/connect';
import storeAction from 'shared/context/actions';
import { injectIntl } from 'react-intl';
import { authLayout } from 'layouts';
import moment from 'moment';
import { PATH } from 'shared/constants/constants';

import './Otp.scss';

let interval = null;
const timeout = null;
const CODE_LENGTH = new Array(4).fill(0);

interface State {
    timer: any,
    value: string,
    focused: boolean,
}

interface Props {
    history: { 
        location?: any, 
        push?: any 
    },
    intl: { formatMessage?: any },
    onHandleOTP?: any,
    // clientData?: any,
    onHandleResendOTP?: any,
    phoneNumber?: string,
    error?: string,
    token?: string,
}

class Otp extends PureComponent<Props, State>  {
    input: React.RefObject<HTMLInputElement>;

    constructor(props) {
        super(props);
        this.state = {
          timer: 60000,
          value: '',
          focused: false,
        };
        this.input = React.createRef();
    }

    componentDidMount() {
      // initial input typing
      this.input.current.style.caretColor = 'transparent';
      this.handleFocus();
  
      // start resend code duration
      this.resendCodeDuration();

      if (this.props.token) {
        this.props.history.push(PATH.INTRO);
      }
    }
    
    componentDidUpdate() {
      const { timer } = this.state;
      if (timer === 0) {
        clearInterval(interval);
      }
    }
  
    componentWillUnmount() {
      clearInterval(interval);
      clearTimeout(timeout);
    }
  
    handleResendCode = () => {
      const { onHandleResendOTP, history, phoneNumber } = this.props;
      this.setState({
        timer: 60000,
        value: '',
      }, () => {
          onHandleResendOTP(phoneNumber, history)
      //   onHandleResendOTP().catch(() => {
      //     errorMessage('Session has expired, please login again.');
      //     setTimeout(() => {
      //       clearAllToast();
      //       history.push('/');
      //     }, 3000);
      //   });
        this.resendCodeDuration();
      });
    }
  
    resendCodeDuration = () => {
      interval = setInterval(() => {
        this.setState((state) => ({
          timer: state.timer - 1000,
        }));
      }, 1000);
    }
  
    handleKeyUp = (e) => {
      if (e.key === 'Backspace') {
        this.setState((state) => ({
          value: state.value.slice(0, state.value.length - 1),
        }));
      }
    };
  
    handleChange = ({ target }) => {
      const { history, onHandleOTP } = this.props;
      this.setState(({ value }) => {
        if (value.length >= CODE_LENGTH.length) {
          return null;
        }
        return {
          value: (value + target.value).slice(0, CODE_LENGTH.length),
        }
      }, () => {
        const { value } = this.state;
        if (value.length === CODE_LENGTH.length) {
          onHandleOTP(value, history);
        }
      });
    };
  
    handleFocus = () => {
      this.input.current.focus();
      this.setState({ focused: true });
    };
  
    handleBlur = () => {
      this.setState({
        focused: false,
      });
    };

    render() {
        const { intl: { formatMessage }, error } = this.props;
        const {
            timer,
            value, focused,
          } = this.state;
        const values = value.split('');
        return (
            <div className="otp">
                <section className="otp__box">
                    <h5>
                        {formatMessage({ id: 'otp.enterOTP' })}
                        <br />
                        {' '}
                        {/* <b>{phone}</b> */}
                    </h5>
                    <div className="otp__box__input" onClick={this.handleFocus}>
                        {CODE_LENGTH.map((v, index) => {
                        // current index
                        const selected = values.length === index;
                        const filled = values.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1;
                        return (
                            <div
                            key={index}
                            className={`otp__box__input__box ${((selected && focused) || filled) ? 'otp__box__input__box--active' : ''}`}
                            >
                                {values[index]}
                            </div>
                        );
                        })}
                        <input
                            value=""
                            type="number"
                            ref={this.input}
                            onBlur={this.handleBlur}
                            onChange={this.handleChange}
                            onKeyUp={this.handleKeyUp}
                        />
                    </div>

                    {error && <span className="notification notification--error">{error}</span>}

                    <article className={`otp__box__resend-code ${timer !== 0 ? 'otp__box__resend-code--active' : ''}`}>
                        <p>{formatMessage({ id: 'otp.notReceivedOtp' })}</p>
                        {timer === 0 ? (
                        <b onClick={this.handleResendCode}>{formatMessage({ id: 'otp.resendCode' })}</b>
                        ) : (
                        <b>{formatMessage({ id: 'otp.requestNewCodeIn' }, { time: moment(timer).zone(-120).format('mm:ss') })}</b>
                        )}
                    </article>
                </section>
            </div>
        )
    }
}

const mapStateToProps = ({ rootState }) => ({
    phoneNumber: rootState.phoneNumber,
    error: rootState.error,
    token: rootState.token,
});

const mapDispatchToProps = ({
    onHandleOTP: storeAction.verifyOtp,
    onHandleResendOTP: storeAction.requestOtp,
})

export default connect(mapStateToProps, mapDispatchToProps)(authLayout(injectIntl(Otp)));