// eslint-disable-next-line no-unused-vars
import { State, Actions } from 'shared/types';
import ACTION_TYPE from 'shared/constants/actionTypes';
import { PAGE_SIZE } from 'shared/constants/constants';

const getNumOfRecords = total => (total > PAGE_SIZE ? PAGE_SIZE : total);

const reducer = (state: State, action: Actions) => {
  switch (action.type) {
    case ACTION_TYPE.CHANGE_LANGUAGE:
      return {
        ...state,
        rootState: {
          ...state.rootState,
          language: { locale: action.payload},
        }
      };
    case ACTION_TYPE.MASTER_DATA:
      return {
        ...state,
        rootState: {
          ...state.rootState,
          masterData: action.payload,
        }
      };
    case ACTION_TYPE.AUTH.UPDATE_USER_ID:
      return {
        ...state,
        rootState: {
          ...state.rootState,
          userId: action.payload,
        }
      };
    case ACTION_TYPE.UPDATE_INVESTMENT_DATA:
      return {
        ...state,
        investmentState: {
          ...state.investmentState,
          [action.payload.key]: action.payload.value,
        }
      };

    case ACTION_TYPE.UPDATE_GROUPED_SUPPORTS:
      return {
        ...state,
        investmentState: {
          ...state.investmentState,
          groupedSupports: action.payload,
        }
      };
    
    case ACTION_TYPE.CLEAR_SUPPORTS:
      return {
        ...state,
        investmentState: {
          ...state.investmentState,
          // environment: '',
          // social: '',
          // governance: '',
          groupedSupports: [], 
        }
      }
    
    case ACTION_TYPE.CLEAR_USER_INPUT_DATA:
      return {
        ...state,
        investmentState: {
          ...state.investmentState,
          style: '',
          environment: [],
          social: [],
          governance: [],
          groupedSupports: [],     
          exclusions: [],  
          experience: [],
          portfolio: {
            name: '',
            score: 0,
            description: ''
          },
          investHighRanking: '',
          investMediocreRanking: '',
          threshold: 2.0,
          carbonRiskScore: 10,
          isCarbonDisabled: false,  
        }
      }

    case ACTION_TYPE.REQUEST_FAILED:
      return {
        ...state,
        investmentState: {
          ...state.investmentState,
          error: action.payload,
        }
      };
    
    /** Store Score based on user Input  */
    case ACTION_TYPE.GET_USER_PORTFOLIO:
      return {
        ...state,
        investmentState: {
          ...state.investmentState,
          portfolio: {
            name: action.payload.name,
            score: action.payload.score,
            description: action.payload.description,
            esgRankings: action.payload.esgRankings
          },
          error: null,
        }
      }
    // case ACTION_TYPE.INVESTMENT_STYLE:
    //   return {
    //     ...state,
    //     investmentState: {
    //       ...state.investmentState,
    //       [FIELDS.STYLE]: action.payload,
    //     }
    //   };

    case ACTION_TYPE.AUTH.REQUEST_OTP_SUCCESS:
      return {
        ...state,
        rootState: {
          ...state.rootState,
          phoneNumber: action.payload,
          error: null,
        }
      };
    case ACTION_TYPE.AUTH.VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        rootState: {
          ...state.rootState,
          token: action.payload,
          error: null,
        }
      };
    case ACTION_TYPE.AUTH.ERROR:
      return {
        ...state,
        rootState: {
          ...state.rootState,
          token: null,
          error: action.payload,
        }
      };

    case ACTION_TYPE.PRODUCTS.INITIALIZE_PRODUCTS:
      return {
        ...state,
        productState: {
          ...state.productState,
          data: action.payload,
          paging: {
            total: action.payload.length,
            numOfRecords: getNumOfRecords(action.payload.length),
          },
          error: null
        }
      };
    
    case ACTION_TYPE.PRODUCTS.IS_PRODUCT_LOADING:
      return {
        ...state,
        productState: {
          ...state.productState,
          isLoading: action.payload
        }
      }

    case ACTION_TYPE.PRODUCTS.PRODUCTS_LOADED:
      return {
        ...state,
        productState: {
          ...state.productState,
          productsLoaded: action.payload,
          error: null,
        }
      }
    case ACTION_TYPE.PRODUCTS.PRODUCT_FILTER_CHANGED:
      return {
        ...state,
        productState: {
          ...state.productState,
          filters: {
            ...state.productState.filters,
            [action.payload.filterType]: [...action.payload.selectedFilterList],
          },
        },
      };

    case ACTION_TYPE.PRODUCTS.NUMBER_OF_RECORDS_CHANGED:
      return {
        ...state,
        productState: {
          ...state.productState,
          paging: {
            ...state.productState.paging,
            numOfRecords:
              action.payload > state.productState.paging.total
                ? state.productState.paging.total
                : action.payload,
          },
        },
      };
    case ACTION_TYPE.PRODUCTS.TOTAL_OF_RECORDS_CHANGED:
      return {
        ...state,
        productState: {
          ...state.productState,
          paging: {
            ...state.productState.paging,
            total: action.payload,
          },
        },
      };

    case ACTION_TYPE.PRODUCTS.ERROR:
      return {
        ...state,
        productState: {
          ...state.productState,
          error: action.payload,
        }
      };

    case ACTION_TYPE.STREAMING_NEWS:
      return {
        ...state,
        newsState: {
          ...state.newsState,
          data: action.payload,
          error: null,
      }
    };

    case ACTION_TYPE.POST_SEARCH_CRITERIA:
      return {
        ...state,
        newsState: {
          ...state.newsState,
          searchTerms: action.payload,
      }
    };

    case ACTION_TYPE.GET_SEARCH_CRITERIA:
      return {
        ...state,
        newsState: {
          ...state.newsState,
          searchTerms: action.payload,
      }
    };

    default:
      return state;
  }
};

export default reducer;