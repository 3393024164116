import React, { memo } from "react";
import Chips from "components/Chips";

import "./NewsItem.scss";
import arrowRightIcon from "assets/images/arrow-right.svg"

export interface newsItemProps {
    newsItemId: any,
    newsTitle: string,
    dateTime?: any,
    logo: string,
    primaryTags?: any,
    secondaryTags?: any,
    rightIcon: string,
    onClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined,
}

const NewsItem: React.FC<newsItemProps> = ({
    newsItemId,
    newsTitle = '',
    dateTime = '',
    logo,
    primaryTags,
    secondaryTags,
    rightIcon = arrowRightIcon,
    onClick = () => {},
}) => {

    return (
        <button className="news-item" id={newsItemId} onClick={onClick} >
            <div className="news-item__container">
                {newsTitle && <h4 className="news-item__container__title">{newsTitle}</h4>}
                <div className="news-item__container__footer">
                    <span className="news-item__container__footer__logoDateTime">
                        {logo && <img src={logo} alt="logo" />}
                        {dateTime && <>{dateTime}</>}
                    </span>
                    <div className="news-item__container__footer__tags">
                        {secondaryTags && <Chips isWithTitle={false} data={secondaryTags.map(item => [item])}/>}
                        {primaryTags && <Chips isPrimary isWithTitle={false} data={primaryTags.map(item => [item])}/>}
                    </div>
                </div>
            </div>
            <img src={rightIcon} alt="icon" />
        </button>
    );
}

export default memo(NewsItem);