import React, { memo } from "react";
import classNames from 'classnames';
import { ProgressBar } from 'components';

import './Navbar.scss';

export interface IProps {
    navTitle?: string,
    isTransparent?: boolean,
    isShowProgress?: boolean,
    leftButtonValue?: string | React.ReactNode,
    rightButtonValue?: string | React.ReactNode,
    onLeftButtonClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined,
    onRightButtonClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined,
    progress?: number,
}

const Navbar: React.FC<IProps> = ({
    navTitle = '',
    isTransparent = false,
    isShowProgress = false,
    leftButtonValue = null,
    rightButtonValue = null,
    onLeftButtonClick = () => {},
    onRightButtonClick = () => {},
    progress = 40,
}) => {
    const classes = classNames({
        'navbar': true,
        'navbar--transparent': isTransparent,
        // 'navbar--gauge': isShowProgress,
    });

    return (
        <>
            <header className={classes}>
            <>
                {leftButtonValue ? (
                    <button
                    type="button"
                    onClick={onLeftButtonClick}
                    >
                        {leftButtonValue}
                    </button>
                ): <span />}

                <h3>{navTitle}</h3>
                <span className="navbar__spacer" />
                {rightButtonValue ? (
                    <button
                    type="button"
                    onClick={onRightButtonClick}
                    >
                        {rightButtonValue}
                    </button>
                ): <span />}
            </>
            </header>

            {isShowProgress && <ProgressBar progress={progress} />}
        </>
    );
}

export default memo(Navbar);