import React, { memo } from 'react';
import classNames from 'classnames';
import './CheckMark.scss';

interface Props {
    type?: 'radio' | 'checkbox',
    id?: string,
    name?: string,
    label?: string,
    isChecked?: boolean,
    isDisabled?: boolean,
    hasContainer?: boolean,
    value?: string,
    ref?: any,
    onCheckMarkChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined,
}

const CheckMark: React.FC<Props> = React.forwardRef<HTMLInputElement, Props>(({type = 'checkbox', id, name = '', label = '', isChecked = false, isDisabled = false, onCheckMarkChange = () => {}, value = ''}, ref) => {
  const classes = classNames({
    'nw-checkmark': true,
    // 'nw-checkmark--has-label': label,
    'nw-checkmark--disabled': isDisabled,
    'nw-checkmark--checked': isChecked,

    // 'nw-checkmark--has-container': hasContainer,
  });

  const handleClick = (e:React.MouseEvent<HTMLInputElement>) =>{
      e.stopPropagation();
  }

  return (
    <div onClick={handleClick} className={classes}>
      <label
        className={'nw-checkmark__label-container'}
        htmlFor={id}
      >
        <input
          ref={ref}
          id={id}
          type={type}
          name={name}
          className="nw-checkmark__input"
          checked={isChecked}
          disabled={isDisabled}
          value={value}
          onChange={onCheckMarkChange}
        />
        <span  className={`nw-checkmark__container ${!label ? 'nw-checkmark__container--no-label' : ''}`}>
          <span className="nw-checkmark__switcher" />
          {label
              && <span className="nw-checkmark__label">{label}</span>}
        </span>
      </label>
    </div>
  );
});

export default memo(CheckMark);
