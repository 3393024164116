import React from 'react';
import classNames from 'classnames';
import './NewsDetails.scss';
import NavigaBtn from 'assets/images/naviga.png';


interface Props {
    id?: string,
    name?: string,
    title?: string,
    description?: string,
    dateString?: string,
    author?: string,
    ref?: any,
    onCheckMarkChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined,
}
const NewsDetails: React.FC<Props> = ({
        id= '',
        name = '',
        title = '',
        description = '',
        dateString = '',
        author = ''
    }): JSX.Element => {
    return (
      <div className='news-details'>
        <div className='news-details__header'>
            <small>{dateString}</small>

            {/* <img src={NavigaBtn} width="18" height="18"/> */}
            <small>{author}</small>
        </div>
        <h4>
            <strong>
                {title}
            </strong>
        </h4>
        <article style={{whiteSpace:'pre-line'}}>{description}</article>
    </div>
  );
};

export default NewsDetails;
