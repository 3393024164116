import React, { useState } from "react";
// import { Link } from 'react-router-dom';
import utils from "shared/helpers/utils";
import { FS_PAGE_LINK } from "shared/constants/constants";
import "./FsNavbar.scss";

export interface IFsNavbarProps {
  menu?: any;
}

const FsNavbar: React.FC<IFsNavbarProps> = ({
  menu = [
    {
      url: FS_PAGE_LINK.FUND_LIBRARY,
      label: "Fund Library",
      isDisabled: false,
      target: null,
    },
    {
      url: FS_PAGE_LINK.PROFILERS,
      label: "Profilers",
      isDisabled: false,
      target: null,
      children: [
        {
          url: FS_PAGE_LINK.RISK_PROFILER,
          label: "Risk Tolerance",
          target: null,
          isDisabled: false,
          isCurrent: false,
        },
        {
          url: FS_PAGE_LINK.SUSTAINABILITY_PROFILER,
          label: "Sustainability Profiler",
          target: null,
          isDisabled: false,
          isCurrent: true,
        },
      ],
    },
    {
      url: FS_PAGE_LINK.REPORTS,
      label: "Reports",
      isDisabled: false,
      target: "_blank",
    },
    {
      url: FS_PAGE_LINK.EDUCATION_CENTER,
      label: "Education center",
      isDisabled: false,
      target: null,
    },
    {
      url: FS_PAGE_LINK.FUND_DISTRIBUTORS,
      label: "Distributors",
      isDisabled: true,
      target: null,
    },
  ],
}) => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <header className="fsNavbar">
      <section className="fsNavbar__container">
        <div className="fsNavbar__container__branding">
          <div className="fsNavbar__logo">
            <a href={FS_PAGE_LINK.HOME} title="FundSingapore">
              <img
                src={`${utils.baseUrl}/icons/logo-fundSingapore.svg`}
                alt="FundSingapore"
                width={231}
                height={24}
              />
            </a>
          </div>

          <button
            className="fsNavbar__menuBtn"
            type="button"
            onClick={() => setShowMenu(!showMenu)}
          >
            <img
              src={`${utils.baseUrl}/icons/${
                showMenu ? "close.svg" : "icon-menu.svg"
              }`}
              alt="menu"
              width={showMenu ? 24 : 18}
              height={showMenu ? 24 : 12}
            />
          </button>
        </div>

        <nav
          className={`fsNavbar__navLinks ${
            showMenu ? "fsNavbar__navLinks--show" : ""
          }`}
        >
          <ul className="fsNavbar__navLinks__menu">
            {menu.map((item, idx) => (
              <li
                key={`nav-${idx}`}
                className={item.isDisabled ? "disabled" : ""}
              >
                <a href={item.url} target={item.target}>
                  {item.label}
                  {item.children && (
                    <img
                      src={`${utils.baseUrl}/icons/${
                        showMenu ? "navbar-dropdown.svg" : "icon-dropdown.svg"
                      }`}
                      alt="sub menu"
                      width={12}
                      height={8}
                    />
                  )}
                </a>
                {item.children && (
                  <ul>
                    {item.children.map((child, i) => (
                      <li
                        key={`subNav-${i}`}
                        className={item.isDisabled ? "disabled" : ""}
                      >
                        <a
                          href={child.url}
                          target={child.target}
                          className={child.isCurrent ? "current" : ""}
                        >
                          {child.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </section>
    </header>
  );
};

export default FsNavbar;
