import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player';
import track from 'react-tracking';
import { trackButtonClick } from 'shared/lib/tracking.utils';
import { simpleLayout } from 'layouts';
import { PATH } from 'shared/constants/constants';
import { isMobileOnly, isIOS, isMobile } from 'react-device-detect';
import classNames from 'classnames';
import utils from 'shared/helpers/utils';
import { Button } from 'components';
// import NewsIcon from 'assets/images/news-icon-white.svg';
import { changeLanguage } from 'shared/context/actions';
import connect from 'shared/context/connect';
import { EsgModal } from 'components';
import './Intro.scss';

interface IIntroProps {
    language: string,
    userId: string,
    onChangeLanguage?: any,
    tracking: Function,
}

const FUND_LIBRARY_URL = process.env.REACT_APP_FUND_LIBRARY;

const Intro: React.FC<IIntroProps> = ({language, onChangeLanguage = () => {}, userId, tracking}) => {
    const [showPopup, setShowPopup] = useState(false);
    const history = useHistory();
    const classes = classNames({
        'intro': true,
        'intro--ios': isMobileOnly && isIOS,
        'intro--noVideo': !isMobile,
        'intro--noBg': !isMobileOnly
    });
    const introClass = 'mainIndex';
    const introWelcomeImg = isMobileOnly ? 'ESG-investing.svg' : 'ESG-investing-desktop.svg';

    const handleStartBtn = () => {
        trackButtonClick(tracking, userId, [
            { key: 'getStarted', value: 'true' }
        ]);
        history.push(PATH.STYLE_PICKER);
    }

    /** Add this class on index or intro page to hide video bg  */
    useEffect(() => {
        document.body.classList.add(introClass);
        return () => {
            document.body.classList.remove(introClass);
        }
    }, [])

    return (
        <>
          {isMobileOnly ? (
            <section className="video-container">
                <video loop autoPlay controls={false} playsInline muted id="intro-video">
                    <source src={`${utils.baseUrl}/video/intro.mp4`} type="video/mp4" />
                </video> 
            </section>
          ) : (
            <section className="video-container-desktop">
                <video loop autoPlay controls={false} playsInline muted id="intro-video">
                    <source src={`${utils.baseUrl}/video/intro-desktop.mp4`} type="video/mp4" />
                </video> 
            </section>
          )}

          <section className={classes}>
            <span className="intro__spacer" />
            <header className="intro__header">
                <div className="intro__header__topActions">
                    {/* <img className="back-button" src={`${utils.baseUrl}/icons/arrow-left.svg`} alt="Back to IMAS" width={28} height={28} onClick={() => window.open(FUND_LIBRARY_URL, "_self")} /> */}
                    {/* <img className="newsIcon" src={NewsIcon} alt="Streaming News" width={28} height={28} onClick={() => history.push(PATH.STREAMING_NEWS)} /> */}
                    {/* <LanguageSwitcher selected={language} onSelect={(lang) => onChangeLanguage(lang)} /> */}
                </div>

                <section className="intro__header__body">
                    <img className="intro__header__body__title" src={`${utils.baseUrl}/icons/${introWelcomeImg}`} alt="IMAS ESG Profiler" />

                    <h5>Answer a few questions to discover your ESG element and align your investment preferences with your values.</h5>

                    <div className="intro__header__body__actions">
                        {!isMobileOnly ? <Button 
                            value="Get started"
                            isBig
                            onButtonClick={handleStartBtn}
                        /> : null}
                        <h5 className="knowMore" onClick={() => setShowPopup(true)}>
                            {/* <a href="https://d2csxpduxe849s.cloudfront.net/media/469BA343-4BDA-4DA8-82EFE0F61A0F858A/20875641-93E1-4068-AD7C08812FC6E601/18E08B06-E776-4674-9ABDC9FD2FCEBD99.mp4" target='_blank' rel="noopener noreferrer"> */}
                            <a>
                                <img src={`${utils.baseUrl}/icons/icon-play.svg`} alt="What is ESG?" width={20} height={20} />
                                <span>Learn more about Sustainable Investing</span>
                            </a>
                        </h5>
                    </div>

                    
                </section>
            </header>

            <h6 className="sponsor">
                <span>Special thanks to</span>
                <span className="sponsor__logo"><img src={`${utils.baseUrl}/icons/amundiAM_logo.png`} alt="What is ESG?" width={120} height="auto" /></span>
            </h6>

            <span className="intro__spacer" />
            <footer className="intro__actions">
                {/* <Button 
                    value={(
                        <>
                            <a href="https://video.morningstar.com/corporate/marketing/sustainability-rating.mp4" target='_blank' rel='noopener noreferrer'>What is ESG (90s)</a>
                            <img src={`${utils.baseUrl}/images/icon-play.svg`} alt="What is ESG?" />
                        </>
                    )}
                    isFullWidth
                    isBig
                    isOutline
                /> */}
                {isMobileOnly ? <Button 
                    value="Get started"
                    isFullWidth
                    isBig
                    onButtonClick={handleStartBtn}
                /> : null}
            </footer>
          </section>

            <EsgModal 
                showModal={showPopup} 
                title="Learn about Sustainable Investing" 
                onClose={() => setShowPopup(false)} 
                onConfirm={() => setShowPopup(false)}
                customClassName="modal--big"
            >
                <div className="video-wrapper">
                    {/* <ReactPlayer height="auto" volume={1} playing={true} controls={true} url="https://players.brightcove.net/3395660691001/default_default/index.html?videoId=6281084387001" /> */}
                    <iframe src="https://players.brightcove.net/3395660691001/default_default/index.html?videoId=6281084387001" allow="encrypted-media" width="760" height="360" style={{ maxHeight: 'auto', maxWidth: '100%', appearance: 'unset', border: 'unset' }}></iframe>
                </div>
            </EsgModal>
        </>
    );
}
const mapStateToProps = ({ rootState }) => ({
    // language: rootState.language.locale
    userId: rootState.userId
});
  
const mapDispatchToProps = ({
    onChangeLanguage: changeLanguage,
});

export default track()(connect(mapStateToProps, mapDispatchToProps)(simpleLayout(Intro)));