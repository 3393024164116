import React from "react";
import EsgDetails from './EsgDetails';
import { Tabs } from 'components';

export interface IEsgPerformanceProps {
    tabsMenu?: Array<object>,
    details?: Array<object>,
    id?: any,
}

const EsgPerformance: React.FC<IEsgPerformanceProps> = ({
    tabsMenu = [],
    details = [],
    id = '',
}) => {
    return (
        <section className="esg-performance">
            <Tabs
                isDefaultStyle={false}
                tabMenu={tabsMenu}
                tabActive={1}
            >
                {details && details.map((item: any, idx) => (
                    <EsgDetails 
                        key={`${id}-${idx}`}
                        {...item}
                    />
                ))}
            </Tabs>
            
        </section>
    );
}

export default EsgPerformance;