import React, { memo, useMemo } from 'react';
import Slider from 'rc-slider';
import './EsgRiskSlider.scss';
// import InfoIcon from 'assets/images/icon-info.svg';
import IconButton from 'components/Button/IconButton/IconButton';

export interface IEsgRiskSliderProps {
    // environmentScore?: string | number,
    name?: string,
    label?: string,
    defaultValue?: number,
    minValue?: number,
    maxValue?: number,
    step?: number,
    isDisabled?: boolean,
    onChange?: any,
    onAfterChange?: any,
    tooltips?: string,
}

const EsgRiskSlider: React.FC<IEsgRiskSliderProps> = ({
    name = 'environmentRisk',
    label = 'Environment risk',
    defaultValue = 5,
    tooltips = 'Lorem ipsum dolor',

    minValue = 1, 
    maxValue = 5, 
    step = 1,
    isDisabled = false,
    onChange = () => {},
    onAfterChange = () => {},
}) => {
    const parseRiskScoreLabel: any = useMemo(() => {
        // console.log(defaultValue);
        if (defaultValue === 1) {
            return 'Low'
        } else if (defaultValue === 2) {
            return 'Below average'
        } else if (defaultValue === 3) {
            return 'Average'
        } else if (defaultValue === 4) {
            return 'Above average'
        } else if (defaultValue === 5) {
            return 'High'
        }
    }, [defaultValue]);

    return (
        <div className="esg-slider">
            <header className="esg-slider__header">
                <h6>
                    <span>{`${label}: `}</span>
                    <strong>{parseRiskScoreLabel}</strong>
                </h6>
                <IconButton id={name} popupcontent={tooltips} />
                {/* <img src={InfoIcon} alt="info" width={20} height={20} /> */}
            </header>
            <div className="esg-slider__body">
                <Slider
                    min={minValue}
                    max={maxValue}
                    step={step}
                    defaultValue={defaultValue}
                    // marks={marks}
                    onChange={onChange}
                    onAfterChange={onAfterChange}
                    disabled={isDisabled}

                    handleStyle={{ 
                        width: 16,
                        height: 16,
                        marginTop: -6,
                        background: "#FFFFFF",
                        border: "4px solid #336792",
                        cursor: "auto",
                    }}
                    railStyle={{
                        // height: 8,
                        background: "linear-gradient(90deg, #C8FC9E 0%, #FF9980 100.08%)",
                        // borderRadius: 4,
                    }}
                    trackStyle={{
                        background: "unset",
                    }}
                />
            </div>
        </div>
    );
}

export default memo(EsgRiskSlider);