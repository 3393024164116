import { useContext } from 'react';
import { GlobalContext } from 'shared/context/store';
import { useQuery } from 'react-query';
import apiService from 'shared/services/apiService';

function selectNewsLanguage(lang) {
    if (lang === 'EN' || lang === 'TH') {
        return 'en_US'
    } else {
        return 'zh_CN'
    }
}

function useStreamingNews(saveNews) {  
    const { state: { rootState } } = useContext(GlobalContext);
    return useQuery("streamingNews", async () => {
        const { channel } = await apiService.getNews(selectNewsLanguage(rootState.language.locale));
        const {
                // description,
                items,
                // link,
                // locale,
                // publishDate
        } = channel;
        saveNews(items);
        return items;
    })
}

export default useStreamingNews;