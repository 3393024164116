import React, { memo, useRef } from 'react';
import classNames from 'classnames';
import CheckMark from 'components/CheckMark/CheckMark';
import './CheckMarkOption.scss';

interface Props {
    name?: string,
    id?:string,
    label?: string,
    title?: string,
    isChecked?: boolean,
    isDisabled?: boolean,
    value?: string,
    values?: any, //TODO: define type for it
    type?: 'radio' | 'checkbox',
    onCheckMarkChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined,
}

const CheckMarkOption: React.FC<Props> = ({
   name = '', label = '', isDisabled = false, onCheckMarkChange = () => {}, title = '', id, values, type,
}): JSX.Element => {

  const checked = values.indexOf(id) > -1;

  const classes = classNames({
    'nw-checkmark-option': true,
    'nw-checkmark-option--uncheck': !checked,

    // // 'nw-checkmark--has-label': label,
    'nw-checkmark-option--disabled': isDisabled,
    // 'nw-checkmark--checked': isChecked,

    // 'nw-checkmark--has-container': hasContainer,
  });


  const footerClasses = classNames({
     'nw-checkmark-option__content__footer': true,
     'nw-checkmark-option__content__footer--hidden': !checked
  });


  interface RefObject {
    click: () => void
  }
  const ref = useRef<RefObject>(null);

  const handleClick =() =>{
    if(ref.current){
      ref.current.click()
    }
  }

  return (
    <div className={classes} onClick={handleClick}>
            <div className={'nw-checkmark-option__content'}>
                <div className={'nw-checkmark-option__content__header'}>
                    {title}
                </div>
                <small className={footerClasses}>
                    {label}
                </small>
            </div>
            <div className={'nw-checkmark-option__wrapper'}>
               <CheckMark ref={ref} type={type} id={id} name={name} isChecked={checked} onCheckMarkChange={onCheckMarkChange}/>
            </div>
    </div>
  );
};

export default memo(CheckMarkOption);
