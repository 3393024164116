import React, { useState, memo, useMemo, useEffect } from "react";
import WithBottomButton from "components/Layout/WithBottomButton";
import { defaultLayout } from "layouts";
import ReactLoading from "react-loading";
import {
  RiskSlider,
  EsgRiskSlider,
  EsgModal,
  ButtonOption,
  Filter,
  FilterSlider,
} from "components";
import { isEqual } from 'lodash';
import track from 'react-tracking';
import { trackButtonClick, buildAnswerCodeFromArr } from 'shared/lib/tracking.utils';
import connect from "shared/context/connect";
import storeAction from "shared/context/actions";
// import { useHistory } from 'react-router-dom';
import utils from "shared/helpers/utils";
import { PATH, FIELDS } from "shared/constants/constants";
import "./Preferences.scss";
import PlusIcon from "assets/images/icon-plus.svg";

const FUND_LIBRARY_URL = process.env.REACT_APP_FUND_LIBRARY;
interface IPreferencesProps {
  // carbonRiskScore?: Array<number>,
  // environmentScore?: number,
  // socialScore?: number,
  // governanceScore?: number,
  exclusions?: Array<string>;
  // exclusionThreshold?: number,
  onSave?: any;
  onUpdateUserInputFilters?: any;
  esgRankings?: Array<any>;
  userId: string;
  tracking: Function;
}

const Preferences: React.FC<IPreferencesProps> = ({
  // carbonRiskScore = [1, 3],
  // environmentScore = 3,
  // socialScore = 2,
  // governanceScore = 1,
  exclusions = [],
  // exclusionThreshold = 1.0,
  onSave,
  onUpdateUserInputFilters,
  esgRankings = [],
  tracking = () => {},
  userId,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [is2ndBtnSubmitted, set2ndBtnSubmitted] = useState(false);
  const [showExclusionModal, setShowExclusionModal] = useState(false);
  const [carbonRiskScore, setCarbonRiskScore] = useState([1, 4]);
  const [environmentScore, setEnvironmentScore] = useState(
    esgRankings[0]?.level || 3
  );
  const [socialScore, setSocialScore] = useState(esgRankings[1]?.level || 2);
  const [governanceScore, setGovernanceScore] = useState(
    esgRankings[2]?.level || 2
  );
  const [exclusionThreshold, setExclusionThreshold] = useState(2.0);
  const [initFilter, setInitFilter] = useState(null);

  const onHandleExclusionConfirm = () => {
    setShowExclusionModal(false);
    // onGetProducts();
  };
  const handleExclusionCheckedItem = (value: string) => {
    // const selected = [...checkedItem];
    const selected = [...exclusions];
    const index = selected.indexOf(value);
    if (index !== -1) {
      selected.splice(index, 1);
    } else {
      selected.push(value);
    }
    // setCheckedItem(selected);
    onSave(FIELDS.EXCLUSIONS, selected);
  };

  const onHandleRemove = (key, val) => {
    onUpdateUserInputFilters(key, val);
  };

  const getFormData = () => {
    return {
      carbonRiskMin: carbonRiskScore[0],
      carbonRiskMax: carbonRiskScore[1],
      eRisk: environmentScore,
      sRisk: socialScore,
      gRisk: governanceScore,
      exclusionThresHold: exclusionThreshold,
    }
  }

  const trackExploreSustainableInvesting = () => {
    const formData = getFormData();
    const excludesValue = utils.exclusionOptions.filter(item => exclusions.includes(item.id)).map((item) => item.tmpCode);
    const excludesCode = buildAnswerCodeFromArr(excludesValue);
    let arrKeyValue = [
      { key: 'firstExploreSustainableInvesting', value: 'true' },
      { key: 'carbonRiskMin', value: carbonRiskScore[0] },
      { key: 'carbonRiskMax', value: carbonRiskScore[1] },
      { key: 'eRisk', value: environmentScore },
      { key: 'sRisk', value: socialScore },
      { key: 'gRisk', value: governanceScore },
      { key: 'excludes', value: excludesCode },
      { key: 'exclusionThresHold', value: exclusionThreshold }
    ]
    if (!isEqual(formData, initFilter)) {
      arrKeyValue.push({ key: 'modifyFilters', value: 'true' });
    }
    trackButtonClick(tracking, userId, arrKeyValue);
  }

  useEffect(() => {
    setInitFilter(getFormData());
  }, []);

  // const onHandleSliderChange = (name: string, val: any) => {
  //     onSave(name, val);
  //     console.log('saving slider values', name, val)
  // }

  /** Esg preference tooltips */
  const esgRiskTooltips = {
    environment:
      "The asset-weighted average of the Company Environmental Risk scores for the covered holdings in a portfolio. Company Environmental Risk Scores from Sustainalytics measure the degree to which a company's economic value may be at risk driven by environmental factors. The environmental risk represents the unmanaged environmental risk exposure after taking into account a company's management of such risks. The Environmental Risk Scores are displayed as a number between 0 and 100, though most scores range between 0 and 25.",
    social:
      "The asset-weighted average of the Company Social Risk Scores for the covered holdings in a portfolio. Company Social Risk Scores from Sustainalytics measure the degree to which a company's economic value may be at risk driven by social factors. The social risk represents the unmanaged social risk exposure after taking into account a company's management of such risks. The Social Risk Scores are displayed as a number between 0 and 100, though most scores range between 0 and 25.",
    governance:
      "The asset-weighted average of the company Governance Risk Scores for the covered holdings in a portfolio. Company Governance Risk Scores from Sustainalytics measure the degree to which a company's economic value may be at risk driven by governance factors. The governance risk represents the unmanaged governance risk exposure after taking into account a company's management of such risks. The Governance Risk Scores are displayed as a number between 0 and 100, though most scores range between 0 and 25.",
  };

  /** render primary button value */
  const renderPrimaryButton = useMemo(() => {
    return isSubmitted ? (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactLoading height="28px" width="28px" color="#3C90F4" type="spin" />
      </span>
    ) : (
      "Explore sustainable investing"
    );
  }, [isSubmitted]);

  /** render skip button value */
  const renderSecondaryButton = useMemo(() => {
    return is2ndBtnSubmitted ? (
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactLoading height="28px" width="28px" color="#3C90F4" type="spin" />
      </span>
    ) : (
      "Explore fund library"
    );
  }, [is2ndBtnSubmitted]);

  return (
    <WithBottomButton
      skipValue={renderSecondaryButton}
      onSkipClick={() => {
        set2ndBtnSubmitted(true);
        trackButtonClick(tracking, userId, [
          { key: 'exploreFundLibrary', value: 'true' }
        ]);
        window.open(FUND_LIBRARY_URL, "_self");
      }}
      buttonValue={renderPrimaryButton}
      onButtonClick={() => {
        setIsSubmitted(true);
        trackExploreSustainableInvesting();
        window.open(
          `${FUND_LIBRARY_URL}?new=true&carbonScore=${carbonRiskScore}&environment=${environmentScore}&social=${socialScore}&governance=${governanceScore}&exclusions=${exclusions}&threshold=${exclusionThreshold}&type=esgProfiler&featured=false`,
          "_self"
        );
      }}
      isButtonSwapOrder
      // disable={isSubmitted}
    >
      <section className="preferences">
        {/* Carbon risk slider */}
        <RiskSlider onChange={(e) => setCarbonRiskScore(e)} />

        <section className="esg-slider-wrapper">
          <EsgRiskSlider
            tooltips={esgRiskTooltips.environment}
            defaultValue={environmentScore}
            onChange={(e: any) => setEnvironmentScore(e)}
          />
          <EsgRiskSlider
            tooltips={esgRiskTooltips.social}
            defaultValue={socialScore}
            label="Social risk"
            onChange={(e: any) => setSocialScore(e)}
          />
          <EsgRiskSlider
            tooltips={esgRiskTooltips.governance}
            defaultValue={governanceScore}
            label="Governance risk"
            onChange={(e: any) => setGovernanceScore(e)}
          />
        </section>

        {/* EXCLUSION (Select to exclude) */}
        <Filter
          isEsgPreferences
          id="exclusions"
          title="Exclude"
          tags={exclusions}
          buttonValue={
            exclusions.length ? (
              <img src={PlusIcon} width={12} height={12} alt="add exclusion" />
            ) : (
              <>
                <span>Select to exclude</span>
                <img
                  src={PlusIcon}
                  width={12}
                  height={12}
                  alt="add exclusion"
                />
              </>
            )
          }
          onRemove={onHandleRemove}
          onAdd={() => setShowExclusionModal(true)}
        />

        <FilterSlider
          title="Exclusion threshold"
          percentage="%"
          preposition="below"
          minValue={0}
          maxValue={10}
          minLabel="0%"
          maxLabel="> 10%"
          step={0.1}
          defaultValue={exclusionThreshold}
          // onAfterChange={onAfterChange}
          // isSwitchDisabled
          isSwitcherHidden
          // iconBtnId='threshold'
          popupContent=""
          // onChange={e => onHandleSliderChange(FIELDS.THRESHOLD, e)}
          onChange={(val) => setExclusionThreshold(val)}
          isDisabled={!exclusions.length}
        />

        <span style={{ display: "block", minHeight: "20px" }} />
      </section>

      <EsgModal
        showModal={showExclusionModal}
        title="Investments to exclude"
        onClose={() => setShowExclusionModal(false)}
        onConfirm={onHandleExclusionConfirm}
      >
        <div className="exclusion-wrapper">
          {utils.exclusionOptions.map((p) => (
            <ButtonOption
              key={p.id}
              label={p.label}
              isChecked={exclusions.indexOf(p.id) > -1}
              onClick={() => handleExclusionCheckedItem(p.id)}
              isOnWhiteBg
              isSecondary
            />
          ))}
        </div>
      </EsgModal>
    </WithBottomButton>
  );
};

const mapStateToProps = ({ investmentState, rootState }) => ({
  exclusions: investmentState.exclusions,
  esgRankings: investmentState.portfolio.esgRankings,
  userId: rootState.userId,
  // exclusionThreshold: investmentState.threshold,
});

const mapDispatchToProps = {
  onSave: storeAction.updateInvestmentData,
  onUpdateUserInputFilters: storeAction.onUpdateUserInputFilters,
};

export default track()(connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(defaultLayout(Preferences))));
