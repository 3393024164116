import { IProps } from 'components/Navbar/Navbar';
import React from 'react';
import { Navbar } from 'components';

import './layoutWithBottomInfo.scss';

const layoutWithBottomInfo = (Wrapped: React.ComponentType, BottomInfo: React.ComponentType) => (props: (JSX.IntrinsicAttributes & IProps) | (JSX.IntrinsicAttributes & { children?: React.ReactNode; })) => (
    <main className="app-wrapper">
        <div className="app-wrapper__container layout">
            <Navbar {...props} />

            <section className="layout__body layout__body--withInfo">
                <div className="layout__body--withInfo__content"><Wrapped {...props} /></div>
                <div className="layout__body--withInfo__main"><BottomInfo {...props}/></div>
            </section>
            
        </div>
    </main>
)

export default layoutWithBottomInfo;