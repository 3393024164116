import React, { useState, useEffect } from "react";
import "./Charts.scss";

type Props = {
  value?: number; // value the chart should show
  label: String; // label for the chart
  size?: number; // diameter of chart
  strokewidth?: number; // width of chart line
};

const Charts: React.FC<Props> = ({
  value = 50,
  label = "",
  size = 300,
  strokewidth = 40,
}) => {
  const [val, setVal] = useState(0);
  // const createInterVal = () =>{
  //   return setInterval(()=>{
  //     if(val <= value){

  //     setVal(value);
  //    // console.log('aaaa 2',val)
  //   }
  //  },30)
  // }
  // useEffect(()=>{
  //   inter = createInterVal();
  // },[setVal])

  // useEffect(()=>{
  //   // console.log('aaaa',val)

  //   if(val >= value){
  //     // console.log('aaaa 1',val)
  //     clearInterval(inter);
  //   }
  //  },[val])

  // useEffect(()=>{
  //   setTimeout(() => {
  //     setVal(value);
  //   }, 100);
  // },[setVal])
  
  // https://stackoverflow.com/questions/53395147/use-react-hook-to-implement-a-self-increment-counter


  const createInterVal = () => {
    const inter = setInterval(() => {
      if (val <= value) {
        setVal((prev) => {
          if (prev < value) {
            return prev + 1;
          } else {
            clearInterval(inter);
            return value;
          }
        });
        // console.log("aaaa 2", val);
      }
    }, 10);
    return inter;
  };

  useEffect(() => {
    createInterVal();
  }, [setVal]);

  // useEffect(()=>{
  //   if(val >= value){
  //     clearInterval(inter);
  //   }
  // },[val])

  const halfsize = size * 0.5;
  const radius = halfsize - strokewidth * 0.5;
  const circumference = 2 * Math.PI * radius;
  const strokeval = (val * circumference) / 100;
  const dashval = strokeval + " " + circumference;

  const trackstyle = { strokeWidth: strokewidth };
  const indicatorstyle = { strokeWidth: strokewidth, strokeDasharray: dashval };

  const rotateval = "rotate(-90 " + halfsize + "," + halfsize + ")";

  return (
    <>
      <svg width={size} height={size} className="donutChart">
        <defs>
          <linearGradient id="gradient" x1="100%" y1="100%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#00FFFF" />
            <stop offset="100%" stopColor="blue" />
          </linearGradient>
        </defs>
        <circle
          r={radius}
          cx={halfsize}
          cy={halfsize}
          transform={rotateval}
          style={trackstyle}
          className="donutChart__track"
        />
        <circle
          r={radius}
          cx={halfsize}
          cy={halfsize}
          transform={rotateval}
          style={indicatorstyle}
          className="donutChart__indicator"
          stroke="url(#gradient)"
          fill="none"
        />
        <text
          className="donutChart__text"
          x={halfsize}
          y={halfsize + 8}
          style={{ textAnchor: 'middle' }}
        >
          <tspan className="donutChart__text__val">{value}%</tspan>
        </text>
       {/* <text
        className="donutChart__text"
        x={halfsize}
        y={halfsize + 30}
        style={{ textAnchor: 'middle' }}
      >
        <tspan className="donutChart__text__label">{label}</tspan>
       
      </text> */}
      </svg>
    </>
  );
};

export default Charts;
