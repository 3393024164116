import { IProps } from 'components/Navbar/Navbar';
import React from 'react';
import { Navbar } from 'components';
import { useWindowSize } from "shared/customHooks";

import utils from 'shared/helpers/utils';
import { FS_PAGE_LINK } from 'shared/constants/constants';

import './defaultLayout.scss';
// import ESGInfo from 'pages/Score/ESGInfo/ESGInfo';

const defaultLayout = (Wrapped: React.ComponentType) => (props: (JSX.IntrinsicAttributes & IProps) | (JSX.IntrinsicAttributes & { children?: React.ReactNode; })) => {
    const windowSize = useWindowSize();
    const showLogo = !!(windowSize.width >= 1190);
    return (
        <main className="app-wrapper">
            <div className="app-wrapper__container layout">
                <Navbar {...props} />

                <section className="layout__body">
                    <Wrapped {...props} />
                </section>
                {/* <ESGInfo/> */}

                {showLogo && <a href={FS_PAGE_LINK.HOME} title="FundSingapore" className="app-wrapper__logo">
                    <img src={`${utils.baseUrl}/icons/logo-fundSingapore.svg`} alt="FundSingapore" width={175} height={27} />
                </a>}
            </div>

            {/* {showLogo && <h6 className="sponsorv2">
                <span>Special thanks to</span>
                <span className="sponsorv2__logo"><img src={`${utils.baseUrl}/icons/Amundi_logo.svg`} alt="What is ESG?" width={100} height="auto" /></span>
            </h6>} */}
        </main>
    )
}

export default defaultLayout;