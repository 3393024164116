import React, { memo } from 'react';
import './SwipeButtons.scss';

interface ISwipeButtonsProps {
    left?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
    right?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
}

const SwipeButtons: React.FC<ISwipeButtonsProps> = ({ left, right }) => {
    return (
        <div className="swipe-buttons">
            <button type="button" className="swipe-buttons__no" onClick={left}>No</button>
            <button type="button" className="swipe-buttons__yes" onClick={right}>Yes</button>
        </div>
    );
}

export default memo(SwipeButtons);